import React, { useEffect } from "react";
import HomeLayout from "../../layouts/Home";
import Box from "@mui/material/Box";
import CardComp from "../../components/CardComp";
import { CardContent, Typography, CardMedia } from "@mui/material";
import {
  IHomeTwo,
  IBoxes,
  IHmoTwo,
  IClientTwo,
  IArrowFoward,
} from "../../utils/icons.utils";
import { Container } from "@mui/system";
import config from "../../utils/config";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Table from "../../components/table/Table";
import TablePreloader from "../../components/Loader/TablePreloader";
import { useDispatch, useSelector } from "react-redux";
import {
  getHospitalData,
  getHospitals,
} from "../../features/hospitals/hospitalSlice";
import { getHmoData, getHmos } from "../../features/hmo/hmoSlice";
import { capitalize } from "../../utils/helper";

const { routes } = config;
export default function Dashboard() {
  const { hospitals, pagination, loading } = useSelector(getHospitalData),
    {
      hmos,
      loading: hmoLoading,
      pagination: hmoPagination,
    } = useSelector(getHmoData),
    dispatch = useDispatch();

  useEffect(() => {
    (async () => await dispatch(getHospitals({ pageSize: 5 })))();
    (async () => await dispatch(getHmos({ pageSize: 5 })))();
  }, []);

  let hospitalData = hospitals?.map((hospital) => ({
    "Hospital Name": capitalize(hospital?.name),
    email: hospital?.email,
    "Phone Number": hospital?.phoneNumber,
    "RC Number": hospital?.rcNumber,
    // address: hospital?.streetName,
    // "Created Date": dayjs(hospital?.createdAt).format("MMM DD, YYYY"),
    _data: hospital,
  }));

  let hmoData = hmos?.map((hmo) => ({
    "HMO Name": capitalize(hmo?.name),
    address: capitalize(hmo?.streetName),
    "Contact Name": hmo?.user?.firstName + " " + hmo?.user?.lastName,
  }));

  const mapData = [
    {
      icon: IHomeTwo,
      title: "7,000",
      subtitle: "Registered Hospitals",
    },
    {
      icon: IBoxes,
      title: "7,000",
      subtitle: "Generated R. 0. I ",
    },
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Registered H. M. O",
    },
  ];

  const mapDataTwo = [
    {
      icon: IHomeTwo,
      title: "7,000",
      subtitle: "Indiviual Clients",
      iconTwo: IArrowFoward,
    },
    {
      icon: IClientTwo,
      title: "7,000",
      subtitle: "Company Clients  ",
      iconTwo: IArrowFoward,
    },
  ];

  return (
    <HomeLayout
      {...{
        title: "Dashboard",
        subtitle: "Overview of all information on the system.",
      }}
    >
      <div className="mb-10">
        <Typography
          variant="h6"
          noWrap
          component="div"
          color="#040316"
          sx={{ my: 3 }}
        >
          Business Overview
        </Typography>
        <Box className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
          {mapData.map((data, index) => {
            return (
              <Box elevation={3} key={index}>
                <CardComp
                  className={
                    "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                  }
                >
                  <CardContent className="flex gap-6 ">
                    <Box
                      className={`  rounded-lg`}
                      sx={{ height: 60, width: 60 }}
                    >
                      <CardMedia
                        component="img"
                        className={`${
                          index === 2 && "xl:p-4 lg:p-3 md:p-2 p-4"
                        } ${index === 2 && "bg-[#007AFF1A] rounded-lg"}`}
                        image={data.icon}
                        alt="Paella dish"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: 700 }}
                        variant="h1"
                        color="black"
                        gutterBottom
                        fontFamily={"Lota"}
                      >
                        {data.title}
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                        fontFamily={"Lota"}
                      >
                        {data.subtitle}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardComp>
              </Box>
            );
          })}
        </Box>

        <Typography
          variant="h6"
          noWrap
          component="div"
          color="#040316"
          sx={{ my: 3 }}
          fontFamily={"Lota"}
        >
          Registered Clients
        </Typography>
        <Box className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-4 lg:gap-10 p-4 mb-14">
          {mapDataTwo.map((data, index) => {
            return (
              <Box elevation={3} key={index}>
                <CardComp
                  className={
                    "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                  }
                >
                  <CardContent className="flex gap-6 justify-between">
                    <Box className="flex gap-6">
                      <Box
                        className={`  rounded-lg`}
                        sx={{ height: 60, width: 60 }}
                      >
                        <CardMedia
                          component="img"
                          className={`${
                            index === 2 && "xl:p-4 lg:p-3 md:p-2 p-4 rounded-lg"
                          } ${index === 2 && "bg-[#007AFF1A]"}`}
                          image={data.icon}
                          alt="Paella dish"
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontSize: 20, fontWeight: 700 }}
                          variant="h1"
                          color="black"
                          gutterBottom
                          fontFamily={"Lota"}
                        >
                          {data.title}
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          color="text.secondary"
                          fontFamily={"Lota"}
                        >
                          {data.subtitle}
                        </Typography>
                      </Box>
                    </Box>
                    <CardMedia
                      component="img"
                      className={`w-[18px] h-[15px] mt-12 cursor-pointer`}
                      image={data.iconTwo}
                      alt="Paella dish"
                    />
                  </CardContent>
                </CardComp>
              </Box>
            );
          })}
        </Box>

        <Box className="grid lg:grid-cols-2 gap-4">
          <div className="w-full">
            <Container
              className="py-5 bg-[var(--c-bg-color)] rounded-lg"
              sx={{ px: 0 }}
            >
              <Box className="flex justify-between mb-2">
                <Typography fontFamily={"Lota"}>Top 5 Hospitals</Typography>
                <Link
                  to={routes.hospitals.hospital}
                  className="text-[var(--c-primary-2)] font-[Lota]"
                >
                  See All{" "}
                  <ArrowForwardIcon
                    className="text-[var(--c-primary-2)]"
                    fontSize="small"
                  />
                </Link>
              </Box>

              <Box className="px-3">
			  {loading ? (
                <TablePreloader />
              ) : hospitalData.length ? (
                <Table
                  {...{
                    data: hospitalData,
                    pagination: { ...pagination, hidden: true },
                    isLoading: loading,
                  }}
                />
              ) : (
                <h2 className="text-center">
                  No Hospitals in the system at the moment
                </h2>
              )}
			  </Box>
            </Container>
          </div>

          <div className="w-full ">
            <Container
              className="py-5 bg-[var(--c-bg-color)] rounded-lg"
              sx={{ px: 0 }}
            >
              <Box className="flex justify-between mb-2">
                <Typography fontFamily={"Lota"}>Top 5 H.M.O</Typography>
                <Link
                  to={routes.hmos.hmo}
                  className="text-[var(--c-primary-2)]"
                  sx={{ fontFamily: "Lota !important" }}
                >
                  See All{" "}
                  <ArrowForwardIcon
                    className="text-[var(--c-primary-2)]"
                    fontSize="small"
                  />
                </Link>
              </Box>
			  <Box className="px-3">
              {hmoLoading ? (
                <TablePreloader />
              ) : hmoData.length ? (
                <Table
                  {...{
                    data: hmoData,
                    pagination: { ...hmoPagination, hidden: true },
                    isLoading: hmoLoading,
                  }}
                />
              ) : (
                <h2 className="text-center">
                  No Hospitals in the system at the moment
                </h2>
              )}
			  </Box>
            </Container>
          </div>
        </Box>
      </div>
    </HomeLayout>
  );
}
