import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import { LoadingButton } from "@mui/lab";
import MultipleSelect from "../Select";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddHospitalsToPlan({
	open,
 loading,
	handleSuccess,
 handleClose,
 options,
 value,
 name,
	setValue,
 btnTexts,
	title,
 subtitle
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				className="p-10 w-full"
				sx={{zIndex: 1200}}
				TransitionComponent={Transition}>
				<DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button color="error" onClick={handleClose} autoFocus>
						<Close />
					</Button>
				</DialogActions>
				<DialogTitle
					id="responsive-dialog-title"
					display="flex"
					justifyContent="center"
					mt="0em">
					<Typography textAlign="center" fontSize="24px">
						{title}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText fontSize="20px" padding=".5em 1em">
						{subtitle}
					</DialogContentText>
    
    <MultipleSelect
					{...{
						className: "w-full mx-aut lg:mt-8 mb-16",
						value,
						title,
						name,
						options,
						required: true,
						handleChange: ({target: {value}}) => setValue(value)
					}}
				/>

					<Stack spacing={7} direction="row" className="mt-5" style={{marginTop: '1.5em'}}>
     <LoadingButton
          fullWidth
						sx={{borderRadius: '10px', bgcolor: 'bg-[var(--text-bg)] !important'}}
          size="large"
          variant="outlined"
										onClick={handleClose}
        >
          <span>{btnTexts[0]}</span>
        </LoadingButton>

     <LoadingButton
          fullWidth
						sx={{".MuiLoadingButton-loadingIndicatorCenter": { color: 'white !important'}
					, borderRadius: '10px', bgcolor: 'var(--c-primary-1) !important', '&:hover': {bgcolor: 'var(--c-primary-1) !important'}}}
          size="large"
          loading={loading}
          onClick={handleSuccess}
          variant="contained"
        >
          <span>{btnTexts[1]}</span>
        </LoadingButton>
					</Stack>
				</DialogContent>
			</Dialog>
	);
}
