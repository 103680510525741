import {
  Box,
  Button,
  Chip,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import HomeLayout from "../../../layouts/Home";
import SelectInput from "../../../components/SelectInput";
import { LoadingButton } from "@mui/lab";
import MultipleSelect from "../../../components/Select";
import { useFormik } from "formik";
import { createHMOPlan } from "../../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import {
  createPlan,
  getHmoData,
  updatePlan,
} from "../../../features/hmo/hmoSlice";
import { getIds } from "../../../utils/helper";
import {
  getAilment,
  getAilmentData,
  setPagination,
} from "../../../features/users/ailmentSlice";
import { getGradeData, getGrades } from "../../../features/users/gradeSlice";
import RadioBoxInput from "../../../components/RadioInput";
import Slider from "../../../components/Slider";
import AddIcon from "@mui/icons-material/Add";
import AddBenefitToPlan from "../../../components/Modals/AddHmoBenefit";

const CreateHmoPlan = ({ editable, stateModel, cb }) => {
  const navigate = useNavigate(),
    [open, setOpen] = useState(false),
    { hmoPlansPayload, loading } = useSelector(getHmoData),
    { grades } = useSelector(getGradeData),
    {
      ailments,
      pagination,
      loading: ailmentLoading,
    } = useSelector(getAilmentData),
    dispatch = useDispatch(),
    formik = useFormik({
      validationSchema: createHMOPlan,
      initialValues: editable
        ? {
            ...stateModel,
            hospitalGrade: getGradeByNumber(stateModel?.hospitalGrade),
          }
        : hmoPlansPayload,
      onSubmit: async (value) => {
        const benefits = {
          categories: [
            {
              name: "benefits",
              limit: 0,
              subCategories: value.benefits,
            },
          ],
        };
        const payload = editable
          ? {
              ...value,
              hospitalGrade: getGradeByName(value.hospitalGrade),
              benefits,
            }
          : {
              ...value,
              hmoId: getIds().hmo,
              hospitalGrade: getGradeByName(value.hospitalGrade),
              benefits,
            };

        const action = editable ? updatePlan : createPlan;
        const res = await dispatch(action(payload)).unwrap();
        if (res?.success) navigate(-1);
      },
    }),
    {
      values,
      setValues,
      handleBlur,
      handleChange,
      handleSubmit,
      errors,
      touched,
      handleFocus,
    } = formik;

  useEffect(() => {
    dispatch(getAilment());
    dispatch(getGrades());
  }, []);

  useEffect(() => {
    editable &&
      setValues({
        ...values,
        hospitalGrade: getGradeByNumber(stateModel?.hospitalGrade),
      });
  }, [editable && grades?.length]);

  function getGradeByName(name) {
    const grade = grades?.find((grade) => {
      const value = `${grade?.name} - ${grade?.description}`;
      if (value === name) return grade;
      else return "";
    });

    return grade?.gradeNumber;
  }

  function getGradeByNumber(number) {
    const grade = grades?.find((grade) => {
      if (grade?.gradeNumber === number) return grade;
      else return "";
    });
    return `${grade?.name} - ${grade?.description}`;
  }

  function handleSearch(e) {
    e.stopPropagation();
    const {
      target: { value },
    } = e;
    let cb = null;
    if (value)
      cb = setTimeout(() => dispatch(setPagination({ search: value })), 10);
    else if (cb) {
      dispatch(getAilment());
      clearTimeout(cb);
    } else dispatch(getAilment());
  }

  useEffect(() => {
    pagination.search && dispatch(getAilment(pagination.search));
  }, [pagination.search]);
  // useEffect(() => {
  //   setValues({ ...values });
  // }, [values.benefits]);

  return (
    <HomeLayout {...{ title: "H. M. O Plans" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <Typography className="text-[400] text-xl mt-8 text-[#040316]">
          {editable ? "Edit" : "New"} H. M. O Plan
        </Typography>
      </Box>
      <Box
        className="py-8 mt-8 bg-[var(--c-bg-color)] w-full rounded-lg"
        noValidate
        onSubmit={handleSubmit}
        sx={{ p: 0 }}
        component={"form"}
      >
        <Box className="flex flex-row w-[90%] m-auto">
          <FormControl className="w-full border-none lg:mt-5 mt-5">
            <TextField
              className="w-[90%] bg-white "
              id="name"
              label="Plan  Name"
              name="name"
              value={values.name}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name && touched.name}
              helperText={errors.name && touched.name ? errors.name : ""}
            />
          </FormControl>
          <SelectInput
            {...{
              className: "lg:mt-5 w-[90%] bg-white mt-5",
              value: values.duration,
              name: "duration",
              label: "Duration",
              handleChange,
              handleBlur,
              handleFocus,
              error: errors.duration && touched.duration,
              helperText: errors.duration,
              options: ["1 Month", "3 Months", "6 Months", "12 Months"],
            }}
          />
        </Box>

        <Box className="flex flex-row w-[90%] m-auto">
          <FormControl className="w-full border-none lg:mt-8 mt-5">
            <TextField
              className=" bg-white w-[90%]"
              id="amount"
              label="Plan  Amount"
              name="amount"
              type="number"
              value={values.amount}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.amount && touched.amount}
              helperText={errors.amount && touched.amount ? errors.amount : ""}
            />
          </FormControl>

          <SelectInput
            {...{
              className: "lg:mt-8 mt-5 w-[90%] bg-white ",
              value: values.hospitalGrade,
              name: "hospitalGrade",
              label: "Hospital Grade",
              required: true,
              handleChange,
              handleBlur,
              handleFocus,
              error: errors.hospitalGrade && touched.hospitalGrade,
              helperText: errors.hospitalGrade,
              options: grades?.map?.(
                (grade) => `${grade?.name} - ${grade?.description}`
              ),
            }}
          />
        </Box>

        <Box className="flex flex-row lg:flex-nowrap flex-wrap w-[90%] m-auto">
          <RadioBoxInput
            {...{
              value: values.planType,
              name: "planType",
              className: "lg:mt-8 mt-5 w-full",
              onChange: handleChange,
              formik,
              required: true,
              label: "Plan Type",
              options: [
                {
                  label: "Individual",
                  value: "individual",
                },
                {
                  label: "Family",
                  value: "family",
                },
              ],
            }}
          />

          {values.planType === "family" && (
            <div className="w-[90%] mt-auto">
              <label
                htmlFor="'memberLimit'"
                className="mb-1 block text-[var(--c-primary-0)]"
              >
                Member Limit
              </label>
              <Slider
                {...{
                  onChange: ({ target: { value } }) =>
                    Number(value) >= 1 &&
                    setValues({ ...values, memberLimit: Number(value) }),
                  onFocus: handleFocus,
                  value: values.memberLimit,
                  name: "memberLimit",
                }}
              />
              <div className="flex justify-end">
                <p className="mt-5 mr-1">
                  {values.memberLimit}{" "}
                  {values.memberLimit > 1 ? "Members" : "Member"}
                </p>
              </div>
            </div>
          )}
        </Box>

        <Box className="flex flex-row lg:flex-nowrap flex-wrap w-[90%] m-auto lg:mt-8 mt-5">
          <MultipleSelect
            {...{
              className: "w-[90%] mx-auto ",
              options: ailments?.map(({ name }) => name),
              value: values.services || [],
              title: "Services",
              name: "services",
              required: true,
              handleBlur,
              handleFocus,
              handleChange,
              handleSearch,
              loading: !pagination.search && ailmentLoading,
              searchLoading: pagination.search && ailmentLoading,
              error: errors.services && touched.services,
              helperText: errors.services,
            }}
          />

          <Button
            className=" ml-4  w-[90%]  text-[12px] lg:text-[18px] bg-[var(--c-white-2)] text-[var(--c-primary-0)] shadow-none normal-case"
            startIcon={
              values?.benefits?.length ? (
                <Chip
                  className="cursor-pointer"
                  label={"+" + values?.benefits?.length}
                />
              ) : (
                <AddIcon />
              )
            }
            // variant="contained"
            fullWidth
            onClick={(_) => setOpen(true)}
          >
            <p className="mr-1">
              {values?.benefits?.length ? "Benefits" : "Add Benefit"}
            </p>{" "}
          </Button>
        </Box>

        <FormControl className="w-full border-none lg:mt-8">
          <TextField
            className="w-[90%] m-auto bg-white"
            id="description"
            label="Plan Description"
            name="description"
            multiline
            rows={5}
            value={values.description}
            required
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.description && touched.description}
            helperText={
              errors.description && touched.description
                ? errors.description
                : ""
            }
          />
        </FormControl>
        <Box className="flex justify-end  mx-auto w-[95%] flex-wrap">
          <Button
            className="lg:my-10 my-5 mr-4 lg:w-[20.1%] w-full rounded-xl text-[12px] lg:text-[18px] bg-[var(--c-white-2)] text-[var(--c-primary-0)] shadow-none normal-case"
            size="large"
            type="reset"
            variant="contained"
            fullWidth
            onClick={(_) => navigate(-1)}
          >
            Cancel
          </Button>
          <LoadingButton
            className="lg:my-10 lg:max-w-[28%] w-full rounded-xl text-[12px] lg:text-[18px] bg-[var(--c-primary-0)] shadow-none normal-case"
            size="large"
            type="submit"
            variant="contained"
            fullWidth
            loading={loading}
          >
            {editable ? "Save Changes" : "Create Plan"}
          </LoadingButton>
        </Box>
      </Box>
      {/* {open && ( */}
      <AddBenefitToPlan
        {...{
          open,
          handleClose: () => setOpen(false),
          values,
          setValues,
          loading,
          btnTexts: ["Close", "Add"],
          handleSuccess: (_) => {},
          touched: touched.benefits,
          error: errors.benefits,
          title: "Add benefits",
        }}
      />
      {/* )} */}
    </HomeLayout>
  );
};

export default CreateHmoPlan;
