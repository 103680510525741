import { Box, Typography } from "@mui/material";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IQuote, ITestimonials } from "../../utils/icons.utils";

const CarouselComp = () => {
  const testimonialData = [
    {
      title:
        "People now recognize that having a good performance conversation means that something happens as a result.",
      message:
        "People now recognize that having a good performance conversation means that something happens as a result.",
      img: ITestimonials,
      name: "Albert Flores",
      profession: "Product Manager at Hygiea",
    },
    {
      title:
        "People now recognize that having a good performance conversation means that something happens as a result.",
      message:
        "People now recognize that having a good performance conversation means that something happens as a result.",
      img: ITestimonials,
      name: "Albert Flores",
      profession: "Product Manager at Hygiea",
    },
    {
      title:
        "People now recognize that having a good performance conversation means that something happens as a result.",
      message:
        "People now recognize that having a good performance conversation means that something happens as a result.",
      img: ITestimonials,
      name: "Albert Flores",
      profession: "Product Manager at Hygiea",
    },
  ];
  return (
    <div className="mt-14 mb-14 mx-auto xl:w-[92%] lg:w-[85%] md:w-[85%] w-[90%] h-[80%]">
      <Carousel
        showThumbs={false}
        showArrows={false}
        interval={6000}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
      >
        {testimonialData.map((data, id) => {
          return (
            <div>
              <div className="md:flex md:justify-around items-center" key={id}>
                <div className="text-start ">
                  <img
                    src={IQuote}
                    alt="img"
                    className="w-[42px] h-[34px] mb-8"
                  />
                  <Typography className="text-[19px] md:text-[23px] font-medium mb-4 md:w-[70%] ">
                    “{data.title}”
                  </Typography>
                  <Typography className="text-[14px] md:text-[18px] font-normal text-gray-700 md:w-[70%] pb-8 md:pb-14">
                    “{data.message}”
                  </Typography>
                </div>
                <div className="relative">
                  <Box className="absolute lg:bottom-8 bottom-10 md:bottom-10 left-0 z-40 p-5 md:mb-2 lg:mb-0">
                    <Typography className="text-[20px] md:text-lg font-semibold text-white text-start">
                      {data.name}
                    </Typography>
                    <Typography className="text-base md:text-sm font-normal text-[#ccc] text-start">
                      {data.profession}
                    </Typography>
                  </Box>
                  <img
                    src={data.img}
                    alt="carousel"
                    className="w- rounded-[20px] mb-"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselComp;
