import { LoadingButton } from "@mui/lab";
import {
  CardContent,
  CardMedia,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import HomeLayout from "../../layouts/Home";
import config from "../../utils/config";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { IHomeTwo, IHmoTwo, IClientTwo } from "../../utils/icons.utils";
import CardComp from "../../components/CardComp";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyData,
  getDashboard,
} from "../../features/company/companySlice";
import { useEffect } from "react";
import { getAmount, getIds } from "../../utils/helper";

const { routes } = config;
export default function CompanyDash() {
  const { dashboard, loading } = useSelector(getCompanyData),
    dispatch = useDispatch();

  const mapData = [
    {
      icon: IHomeTwo,
      title: loading ? "..." : dashboard?.numberOfEmployees,
      subtitle: "Total Number Of Employees ",
    },
    {
      icon: IClientTwo,
      title: loading ? "..." : dashboard?.numberOfEmployeesWithActivePlans,
      subtitle: "Total Number Of  Employee With Active Plans",
    },
  ];

  useEffect(() => {
    (async () => await dispatch(getDashboard(getIds().company)))();
  }, []);

  return (
    <HomeLayout
      {...{
        title: "Dashboard",
        subtitle: "Overview of all information on the system.",
      }}
    >
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <Typography
          variant="h6"
          noWrap
          component="div"
          color="#040316"
          fontFamily={"sofiaPro"}
          sx={{ my: 3 }}
        >
          Business Overview
        </Typography>
        <LoadingButton
          LinkComponent={Link}
          className="w-[213px] h-[56px] bg-[var(--c-primary-0)] mr-8 text-[600] text-[18px] rounded-xl shadow-none normal-case"
          size="small"
          type="submit"
          variant="contained"
          fullWidth
          fontFamily={"sofiaPro"}
          startIcon={<AddIcon />}
          to={routes.companies.employees.new}
        >
          Add Employee
        </LoadingButton>
      </Box>

      <Box
        className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full"
        fontFamily={"sofiaPro"}
      >
        {mapData.map((data, index) => {
          return (
            <Box elevation={3} key={index}>
              <CardComp
                className={
                  "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                }
              >
                <CardContent className="flex gap-6 ">
                  <Box
                    className={`  rounded-lg`}
                    sx={{ height: 60, width: 60 }}
                  >
                    <CardMedia
                      component="img"
                      className={`${
                        index === 2 && "xl:p-4 lg:p-3 md:p-2 p-4"
                      } ${index === 2 && "bg-[#007AFF1A]"}`}
                      image={data.icon}
                      alt="Paella dish"
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{ fontSize: 20, fontWeight: 700 }}
                      variant="h1"
                      color="black"
                      fontFamily={"sofiaProBold"}
                      gutterBottom
                    >
                      {data.title}
                    </Typography>
                    <Typography
                      fontFamily={"sofiaPro"}
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                    >
                      {data.subtitle}
                    </Typography>
                  </Box>
                </CardContent>
              </CardComp>
            </Box>
          );
        })}
      </Box>

      <Typography
        fontFamily={"sofiaPro"}
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ mt: 3, pl: 3 }}
      >
        Total Number Of Employees Per H. M. O Plans
      </Typography>

      {loading ? (
        <Box className="grid lg:grid-cols-3 overflow-x-scroll md:grid-cols-3 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
          {Array(3)
            .fill("")
            .map((a) => (
              <Skeleton className="h-[180px] gap-6 pt-2 xl:pl-3 border-none shadow-md rounded-xl" />
            ))}
        </Box>
      ) : dashboard?.employeesPerPlan?.length > 0 ? (
        <Box
          sx={{ mt: 3 }}
          className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full"
          fontFamily={"sofiaPro"}
        >
          {dashboard?.employeesPerPlan?.map((data, index) => {
            return (
              <Box elevation={3} key={index}>
                <CardComp
                  className={
                    "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                  }
                >
                  <CardContent className="flex gap-6 ">
                    <Box
                      className={`rounded-lg`}
                      sx={{ height: 60, width: 60 }}
                    >
                      <CardMedia
                        component="img"
                        className={
                          "xl:p-4 lg:p-3 md:p-2 p-4 bg-[#007AFF1A] rounded-lg"
                        }
                        image={IHmoTwo}
                        alt="Paella dish"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: 700 }}
                        variant="h1"
                        color="black"
                        gutterBottom
                        fontFamily={"sofiaProBold"}
                      >
                        {loading ? "..." : getAmount(data?.count)}
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.black"
                        fontFamily={"sofiaPro"}
                        className="capitalize"
                      >
                        {data?.plan?.name}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardComp>
              </Box>
            );
          })}
        </Box>
      ) : (
        <p className="ml-6 mt-0 pt-0">No record found</p>
      )}

      <Typography
        fontFamily={"sofiaPro"}
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ mt: 5, pl: 3 }}
      >
        Total Outstanding Payment Per H. M. O Plans
      </Typography>

      {loading ? (
        <Box className="grid lg:grid-cols-3 overflow-x-scroll md:grid-cols-3 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
          {Array(3)
            .fill("")
            .map((a) => (
              <Skeleton className="h-[180px] gap-6 pt-2 xl:pl-3 border-none shadow-md rounded-xl" />
            ))}
        </Box>
      ) : dashboard?.outstandingsPerPlans?.length > 0 ? (
        <Box
          sx={{ mt: 3 }}
          className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full"
          fontFamily={"sofiaPro"}
        >
          {dashboard?.outstandingsPerPlans?.map((data, index) => {
            return (
              <Box elevation={3} key={index}>
                <CardComp
                  className={
                    "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                  }
                >
                  <CardContent className="flex gap-6 ">
                    <Box
                      className={`rounded-lg`}
                      sx={{ height: 60, width: 60 }}
                    >
                      <CardMedia
                        component="img"
                        className={
                          "xl:p-4 lg:p-3 md:p-2 p-4 bg-[#007AFF1A] rounded-lg"
                        }
                        image={IHmoTwo}
                        alt="Paella dish"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: 700 }}
                        variant="h1"
                        color="black"
                        gutterBottom
                        fontFamily={"sofiaProBold"}
                      >
                        {loading ? "..." : getAmount(data?.amount)}
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                        fontFamily={"sofiaPro"}
                      >
                        {data?.hmoName}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardComp>
              </Box>
            );
          })}
        </Box>
      ) : (
        <p className="ml-6 mt-0 pt-0">No Outstanding Payment found</p>
      )}

      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ mt: 5, pl: 3 }}
        fontFamily={"sofiaPro"}
      >
        Total Payment Made Per H. M. O Plan
      </Typography>

      {loading ? (
        <Box className="grid lg:grid-cols-3 overflow-x-scroll md:grid-cols-3 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
          {Array(3)
            .fill("")
            .map((a) => (
              <Skeleton className=" h-[180px] gap-6 pt-2 xl:pl-3 border-none shadow-md rounded-xl" />
            ))}
        </Box>
      ) : dashboard?.totalPaymentPerPlan?.length > 0 ? (
        <Box
          sx={{ mt: 3 }}
          className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full"
        >
          {dashboard?.totalPaymentPerPlan?.map((data, index) => {
            return (
              <Box elevation={3} key={index}>
                <CardComp
                  className={
                    "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                  }
                >
                  <CardContent className="flex gap-6 ">
                    <Box
                      className={`  rounded-lg`}
                      sx={{ height: 60, width: 60 }}
                    >
                      <CardMedia
                        component="img"
                        className={
                          "xl:p-4 lg:p-3 md:p-2 p-4 bg-[#007AFF1A] rounded-lg"
                        }
                        image={IHmoTwo}
                        alt="Paella dish"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: 700 }}
                        variant="h1"
                        color="black"
                        gutterBottom
                        fontFamily={"sofiaProBold"}
                      >
                        {loading ? "..." : getAmount(data?.count)}
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                        fontFamily={"sofiaPro"}
                      >
                        {data?.plan?.name}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardComp>
              </Box>
            );
          })}
        </Box>
      ) : (
        <p className="ml-6 mt-0 pt-0">No payment made yet</p>
      )}
    </HomeLayout>
  );
}
