import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../layouts/Home'
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from '@mui/lab';
import config from "../../../utils/config";
import { Link, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material';
import Table from '../../../components/table/Table';
import Menu from '../../../utils/data.dropdown';
import { closeModal, deleteAilment, getAilment, getAilmentData, openModal, setPagination } from '../../../features/users/ailmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import TablePreloader from '../../../components/Loader/TablePreloader';
import CancelModal from '../../../components/Modals/Cancel';
import { IError } from '../../../utils/icons.utils';


const { routes } = config;

export default function Configurations () {
  const {ailments, pagination, loading, modal } = useSelector(getAilmentData),
  [isLoading, setIsLoading] = useState(true),
  [cancelPlanPayload, setCancelPlanPayload] = useState({name: '', id: ''}),
  navigate = useNavigate(),
  dispatch = useDispatch();

   useEffect( () => {
    (async () => await dispatch(getAilment()))()
  }, [pagination.page])

  useEffect( ()=> {
    setIsLoading(false)
  }, [])

    let data = ailments?.map( ailment => ({
      "Name": ailment?.name,
      _data: ailment
    }) )

  const menu = new Menu({});
  const dropdownMenu = [
    menu.edit({
      navigate,
      route: routes.configurations.ailments,
      name: 'Edit'
    }),
    menu.delete({action: (value) => {
      setCancelPlanPayload({id: value?.id, name: value?.name})
      dispatch(openModal('delete'))
}
}),
  ]

  const handleDelete = async () => {
    const res = await dispatch(deleteAilment(cancelPlanPayload.name)).unwrap()
    res?.success === true && dispatch(getAilment())
  }

  return (
    <HomeLayout {...{ title: 'Configuration' }}>
      <Box className='flex justify-end mt-4 mb-10'>
      <LoadingButton
      LinkComponent={Link}
            className="w-[213px] h-[56px] bg-[var(--c-primary-0)] mr-8 text-[600] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            to={routes.createAilment}
          >
            Create Ailment
          </LoadingButton>
      </Box>
      <Box className="pt-8 px-8 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%" sx={{ p: 0 }}>
      {isLoading ? (
						<TablePreloader />
					) : data?.length ? (
						<Table
							{...{
								dropdownMenu,
								// checkData: rows,
								// checkbox: rows,
								data,
              pagination,
              setPagination: d => dispatch(setPagination(d())),
								isLoading: loading,
							}}
						/>
					) : (
            <Box
            className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
            sx={{ p: 0 }}
          >
            <img
            className="m-auto w-[350px] mt-8"
            src={IError}
            alt={"error"}
            loading="lazy"
          />
          <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
          No Ailment in the system at the moment
          </Typography>
          </Box>
					)}
        </Box>

        <CancelModal
         {...{
           open: modal.delete,
           loading: modal.loading,
            title: 'Delete Ailment',
            subtitle: `Are you sure you want to delete ${cancelPlanPayload.name}?`,
            btnTexts: ['Cancel', 'Delete'],
            handleSuccess: handleDelete,
            handleClose: _=> dispatch(closeModal('delete'))
         }}
         />
    </HomeLayout>
  )
}
