/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import SearchBar from "../../../components/SearchBar";
import Table from "../../../components/table/Table";
import HomeLayout from "../../../layouts/Home";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormat, getIds } from "../../../utils/helper";
import {
  getConsultations,
  getConsultationsData,
  setPagination,
} from "../../../features/hospitals/consultationSlice";
import TablePreloader from "../../../components/Loader/TablePreloader";
import { IError } from "../../../utils/icons.utils";

const Consultation = () => {
  const dispatch = useDispatch(),
    [isLoading, setIsLoading] = useState(true);

  const { pagination, loading, consultations } =
    useSelector(getConsultationsData);

  useEffect(() => {
    dispatch(getConsultations(getIds().hospital));
  }, [pagination.page]);

  useEffect(() => {
    let cb = () => {};
    if (pagination.search)
      cb = setTimeout(
        (_) =>
          (async () => await dispatch(getConsultations(getIds().hospital)))(),
        700
      );

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  let data = consultations?.map((consult) => ({
    Consultant:
      consult?.consultant?.firstName + " " + consult?.consultant?.lastName,
    Patient: consult?.patient?.firstName + " " + consult?.patient?.lastName,
    Ailment: consult?.ailment,
    Prescription: consult?.prescription,
    amount: currencyFormat(Number(consult?.amount || "---"), "₦"),
    Status: consult?.status,
    _data: consult,
  }));

  return (
    <HomeLayout {...{ title: "Staff Management" }}>
      <SearchBar
        {...{
          placeholder: "Search For Patient by Name...",
          onChange: (e) => {
            const { value } = e.target;
            if (e.code === "Enter") dispatch(getConsultations(value));
            else dispatch(setPagination({ search: value }));
          },
        }}
      />
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Typography className="text-[400] text-xl  mb-5  text-[#040316]">
          Consultation
        </Typography>
        {isLoading ? (
          <TablePreloader />
        ) : data?.length ? (
          <Table
            {...{
              // dropdownMenu
              data,
              pagination,
              setPagination: (data) => dispatch(setPagination(data())),
              isLoading: loading,
            }}
          />
        ) : (
          <Box
            className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
            sx={{ p: 0 }}
          >
            <img
              className="m-auto w-[350px] mt-8"
              src={IError}
              alt={"error"}
              loading="lazy"
            />
            <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
              No Consultations in the system at the moment
            </Typography>
          </Box>
        )}
      </Box>
    </HomeLayout>
  );
};

export default Consultation;
