/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Card,
  CardMedia,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import HomeLayout from "../../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SelectInput from "../../../../components/SelectInput";
import PhoneNumberInput from "../../../../components/Phone/PhoneInput";
import FileUpload from "../../../../components/FileUpload";
import {
  addEmployee,
  getEmployeeData,
  setPhoto,
  updateEmployee,
} from "../../../../features/hmo/employeeSlice";
import {
  getAuthData,
  getCities,
  getStates,
  uploadFile,
} from "../../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getIds } from "../../../../utils/helper";
import { useFormik } from "formik";
import { vaddHmoEmployee } from "../../../../utils/validators";

const AddHmoUser = () => {
  const navigate = useNavigate(),
    { models, loading } = useSelector(getEmployeeData),
    { states, cities, modal } = useSelector(getAuthData),
    dispatch = useDispatch(),
    { state } = useLocation(),
    editable = state?.editable,
    stateModel = editable
      ? {
          fullName: state?.User?.firstName + " " + state?.User?.lastName,
          employeePhoneNumber: state?.User?.phoneNumber,
          employeeEmail: state?.User?.email,
          employeeRole: state?.role?.[0],
          employeeId: state?.employeeId,
          state: state?.state,
          city: state?.city,
          street: state?.street,
          profileImage: state?.User?.profileImage,
        }
      : null,
    {
      values,
      handleBlur,
      handleChange,
      handleFocus,
      handleSubmit,
      setValues,
      errors,
      touched,
    } = useFormik({
      validationSchema: vaddHmoEmployee,
      initialValues: editable ? stateModel : models,
      onSubmit: async ({ fullName, profileImage, ...values }) => {
        const action = editable ? updateEmployee : addEmployee;
        const payload = {
          ...values,
          employeeFirstName: fullName.split(" ")?.[0],
          employeeLastName: fullName.split(" ")?.[1],
          hmoId: editable ? state?.hmoId : getIds().hmo,
          employeeUserId: state?.userId,
        };

        const res = await dispatch(action(payload)).unwrap();
        res?.success === true && navigate(-1);
      },
    });

  const handleUpload = async (payload) => {
    try {
      const res = await dispatch(uploadFile(payload)).unwrap();
      if (res?.success) dispatch(setPhoto(res?.data?.[0]?.imageUrl));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(setPhoto(""));
    dispatch(getStates());
  }, []);

  useEffect(() => {
    values.state &&
      dispatch(
        getCities(states?.find((state) => state?.state === values.state)?.tag)
      );
  }, [values.state]);

  return (
    <HomeLayout {...{ title: "Configuration" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Typography className="text-[400] text-xl mt-8 ml-1 text-[#040316]">
        Create User
      </Typography>

      <Box
        sx={{
          className: "w-[100%] lg:w-[80%]",
          px: "3em",
          py: "3px",
          bgcolor: "var(--c-bg-color)",
          mt: "3%",
        }}
      >
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          className="mt-[1.3em]"
        >
          <Box className="grid lg:grid-cols-2 gap-4 mb-8">
            <FormControl className="w-full border-none lg:mt-5 m-auto">
              <TextField
                className="w-[100%] bg-white"
                id="fullName"
                label="Full Name"
                name="fullName"
                value={values.fullName}
                required
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.fullName && touched.fullName}
                helperText={
                  errors.fullName && touched.fullName ? errors.fullName : ""
                }
              />
            </FormControl>
            <FormControl className="w-full border-none lg:mt-5 ">
              <TextField
                className="w-[100%] bg-white"
                id="employeeEmail"
                label="Email Address"
                name="employeeEmail"
                value={values.employeeEmail}
                required
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.employeeEmail && touched.employeeEmail}
                helperText={
                  errors.employeeEmail && touched.employeeEmail
                    ? errors.employeeEmail
                    : ""
                }
              />
            </FormControl>
            <FormControl className="w-full m-auto  border-none lg:mt-5">
              <PhoneNumberInput
                {...{
                  phoneNo: values.employeePhoneNumber,
                  setPhoneNo: (val) =>
                    setValues({ ...values, employeePhoneNumber: val }),
                  error:
                    errors.employeePhoneNumber && touched.employeePhoneNumber
                      ? errors.employeePhoneNumber
                      : "",
                  onFocus: handleFocus,
                  onBlur: handleBlur,
                }}
              />
            </FormControl>
            <FormControl className="w-full border-none lg:mt-5">
              <SelectInput
                {...{
                  className: " w-[100%] m-auto",
                  value: values.employeeRole,
                  name: "employeeRole",
                  label: "Select Role",
                  handleChange,
                  handleBlur,
                  handleFocus,
                  error: errors.employeeRole && touched.employeeRole,
                  helperText: errors.employeeRole,
                  options: ["H. M. O Admin", "Company Admin", "Hospital Admin"],
                }}
              />
            </FormControl>

            <FormControl className="w-full border-none lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="employeeId"
                label="Employee ID"
                name="employeeId"
                value={values.employeeId}
                required
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.employeeId && touched.employeeId}
                helperText={
                  errors.employeeId && touched.employeeId
                    ? errors.employeeId
                    : ""
                }
              />
            </FormControl>

            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.state,
                name: "state",
                label: "State",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.state && touched.state,
                helperText: errors.state,
                options: states?.map((state) => state?.state),
              }}
            />

            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.city,
                name: "city",
                label: "City",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.city && touched.city,
                helperText: errors.city,
                options: cities?.map((city) => city?.lga),
              }}
            />

            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="street"
                label="Street Name"
                name="street"
                value={values.street}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.street && touched.street}
                helperText={
                  errors.street && touched.street ? errors.street : ""
                }
                type="text"
              />
            </FormControl>
          </Box>
          <FileUpload
            {...{
              action: handleUpload,
              errors,
              touched,
              handleChange,
              loading: modal.photoLoading,
              label: "Upload Employee Picture",
              name: "profileImage",
            }}
          />
          {(models?.profileImage || stateModel?.profileImage) && (
            <Card className="w-60 h-50 m-auto my-8">
              <CardMedia
                component="img"
                alt="Selected Image"
                height="163px"
                width="266px"
                image={models?.profileImage || stateModel?.profileImage}
                title="Selected Image"
              />
            </Card>
          )}

          <Box className="flex justify-center">
            <LoadingButton
              className="my-10 rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] w-[50%] shadow-none normal-case"
              sx={{
                ".MuiLoadingButton-loadingIndicatorCenter": {
                  color: "var(--c-bg-color) !important",
                },
              }}
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              loading={loading}
            >
              {editable ? "Save Changes" : "Add"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </HomeLayout>
  );
};

export default AddHmoUser;
