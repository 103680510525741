import { Box, Typography } from '@mui/material';
import React, { useState } from 'react'
import SearchBar from '../../components/SearchBar';
import Table from '../../components/table/Table';
import HomeLayout from '../../layouts/Home';

const ConfirmPaynent = () => {

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10,
        total: 10,
        length: 5,
        hidden: false,
      });
    
      function createData(name, calories, fat, carbs, protein, user, age) {
        return { name, calories, fat, carbs, protein, user, age };
      }
      const rows = [
        createData("Frozen yoghurt", 159, 6.0, 24, 4.0, "Remi", 60),
        createData("Ice cream sandwich", 237, 9.0, 37, 4.3, "John", 30),
        createData("Eclair", 262, 16.0, 24, 6.0, "Moses", 20),
        createData("Cupcake", 305, 3.7, 67, 4.3, "Edu", 31),
        createData("Gingerbread", 356, 16.0, 49, 3.9, "Oba", 58),
      ];


  return (
    <HomeLayout {...{ title: "Transaction Management"  }}>
        <SearchBar placeholder="Search For Transactions..."/> 
        <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
         <Typography className="text-[400] text-xl  mb-5  text-[#040316]">
         Payment Confirmation
			</Typography>
        <Table
          {...{
            dropdownMenu: rows,
            data: rows,
            pagination,
            setPagination,
          }}
        />
        </Box>
    </HomeLayout>
  )
}

export default ConfirmPaynent