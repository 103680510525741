import React from 'react';
import HomeLayout from '../../../layouts/Home';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, CardContent, Chip, Typography } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardComp from '../../../components/CardComp';
import { getAmount } from '../../../utils/helper';


const PlanDetails = () => {
    const navigate = useNavigate(),
    { state } = useLocation(),
    servicesCount = state?.Plan?.services?.length || 0;


  return (
    <HomeLayout {...{ title: "Plans" }}>
         <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <Typography className="text-[400] text-xl mt-8 text-[#040316]">
        View Plan
        </Typography>
        </Box>
        <Box
        className="py-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
        sx={{ p: 0 }}
      >
        <CardComp
          className={
            "bg-[#FFFFFF] w-[95%] mb-8 md:p-2 lg:p-4 p-3 border-none rounded-lg shadow-none m-auto"
          }
        >
          <CardContent className=" ">
          <Box className="flex flex-row gap-6 md:gap-3 lg:gap-10">
            <Avatar alt="Remy Sharp" className="w-[75px] h-[75px]" src={state?.Plan?.Hmo?.hmoLogo} />
            <Box className="flex flex-col">
              <Box>
                <Typography
                className="text-[24px] text-[#040316] font-bold "
                variant="h5"
              >
                {state?.Plan?.Hmo?.name || "---"}
              </Typography>
              <Typography className="text-[18px] text-[#6B7588] font-[400] mb-6">
              {state?.Plan?.description || "---"}
              </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Amount
            </Typography>
            <Typography
              className="xl:text-[20px] xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {'₦' + getAmount(state?.Plan?.amount || "---")}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
               Services ({servicesCount})
            </Typography>
            <Box className="xl:grid-rows-2 grid lg:grid-rows-3 grid-rows-3 grid-flow-col md:gap-3 gap-2 ml-2 md:ml-0">
              {state?.Plan?.services?.length && state?.Plan?.services?.map((service, index) => <Chip className="bg-[#F1F1F4] rounded-lg" label={service} variant="outlined" key={index}/>)}
              </Box>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Duration
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.Plan?.duration || "---"}
            </Typography>
          </Box>
          </CardContent>
        </CardComp>
        </Box>

    </HomeLayout>
  )
}

export default PlanDetails