import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getDashboard = createAsyncThunk(
  "/hmo/getDashboard",
  (hmoId, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/hmos/dashboard/hmo/${hmoId}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);
export const getHmos = createAsyncThunk(
  "/hmo/getHmos",
  ({ search, pageSize } = {}, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().hmo;
      return new BACKEND().send({
        type: "get",
        to: `/hmos/?pageNumber=${pagination.page}&pageSize=${
          pageSize || pagination.pageSize
        }&hasplans=false&search=${search || pagination.search || ""}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const addPlanToHospitals = createAsyncThunk(
  "/hmo/addPlanToHospitals",
  (payload, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: "/plans/add-hospitals/",
        payload,
        useAlert: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const createPlan = createAsyncThunk(
  "/hmo/createPlan",
  (payload, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: "/plans/create-plan",
        payload,
        useAlert: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const updatePlan = createAsyncThunk(
  "/hmo/updatePlan",
  ({ planId, ...payload }, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "patch",
        to: `/plans/update-plan/${planId}`,
        payload,
        useAlert: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateHmo = createAsyncThunk(
  "/hmo/updateHmo",
  async (payload, thunkAPI) => {
    try {
      const {
          payload: { logo },
        } = thunkAPI.getState().auth,
        { hmoId, ...rest } = payload;
      if (logo) rest.hmoLogo = logo;

      return new BACKEND().send({
        type: "patch",
        to: "/hmos/update/" + hmoId,
        useAlert: true,
        payload: { ...rest },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const getPlansByHmoId = createAsyncThunk(
  "/hmo/getPlansByHmoId",
  (HmoId, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().hmo;
      return new BACKEND().send({
        type: "get",
        to: `/plans/get-plans/${HmoId}/?pageNumber=${pagination.page}&hashospitals=false&pageSize=${pagination.pageSize}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const getCompanyClients = createAsyncThunk(
  "/hmo/getCompanyClients",
  (hmoId, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().hmo;
      return new BACKEND().send({
        type: "get",
        to: `/hmos/clients/companies/${hmoId}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const getIndividualClients = createAsyncThunk(
  "/hmo/getIndividualClients",
  (hmoId, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().hmo;
      return new BACKEND().send({
        type: "get",
        to: `/hmos/clients/individuals/${hmoId}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const bulkUploadHmo = createAsyncThunk(
  "/auth/bulkUploadHmo",
  async (file, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: `/hmos/add-bulk`,
        useAlert: true,
        header: { headers: { "Content-Type": "multipart/form-data" } },
        payload: file,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const deletePlanById = createAsyncThunk(
  "/hmo/deletePlanById",
  async (planId, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "delete",
        to: `/plans/plan/${planId}`,
        useAlert: true,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const deactivateHmoById = createAsyncThunk(
  "/hmo/deactivateHmoById",
  async ({ hmoId, status }, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "patch",
        to: `/hmos/deactivate/${hmoId}/?status=${status}`,
        useAlert: true,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const createEmployee = createAsyncThunk(
  "/hmo/createEmployee",
  async ({ hmoId, status }, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "patch",
        to: `/hmos/get-employees/${hmoId}`,
        useAlert: true,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const AddBankAccount = createAsyncThunk(
  "/hmo/AddBankAccount",
  async (payload, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: `/hmo-bank`,
        payload,
        useAlert: true,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const updateBankAccount = createAsyncThunk(
  "/hmo/updateBankAccount",
  async ({ bankAccountId, ...payload }, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "patch",
        to: `/hmo-bank/${bankAccountId}`,
        payload,
        useAlert: true,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const fetchBankAccounts = createAsyncThunk(
  "/hmo/fetchBankAccounts",
  async (_, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/hmo-bank`,
        useAlert: false,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

const initialState = {
  loading: false,
  hmos: [],
  dashboard: {},
  plans: [],
  employees: [],
  companyClients: [],
  individualClients: [],
  bankAccounts: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    search: "",
  },
  hmoPlansPayload: {
    hmoId: "",
    name: "",
    duration: "",
    amount: "",
    description: "",
    services: "",
    memberLimit: "2",
    planType: "individual",
    hospitalGrade: "",
    benefits: [],
  },
  modal: {
    delete: false,
    add: false,
    loading: false,
  },
};

export const hmoSlice = createSlice({
  name: "hmo",
  initialState,
  reducers: {
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    closeModal: (state, { payload }) => {
      state.modal[payload] = false;
    },
    openModal: (state, { payload }) => {
      state.modal[payload] = true;
    },
    setPayload: (state, { payload }) => {
      state.hmoPlansPayload = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHmos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHmos.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.hmos = payload?.data?.hmos;
          state.pagination.total = payload?.data?.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getHmos.rejected, (state) => (state.loading = false));

    // getDashboard
    builder
      .addCase(getDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboard.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) state.dashboard = payload.data;
      })
      .addCase(getDashboard.rejected, (state) => {
        alert(false);
        state.loading = false;
      });

    builder
      .addCase(getCompanyClients.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyClients.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.companyClients = payload?.data?.companyClients;
          state.pagination.total = payload?.data?.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getCompanyClients.rejected, (state) => (state.loading = false));

    builder
      .addCase(getIndividualClients.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIndividualClients.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.individualClients = payload?.data?.individualClients;
          state.pagination.total = payload?.data?.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(
        getIndividualClients.rejected,
        (state) => (state.loading = false)
      )

      .addCase(updateHmo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHmo.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateHmo.rejected, (state) => {
        state.loading = false;
      })

      /** GET PLANS BY HMO ID **/
      .addCase(getPlansByHmoId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlansByHmoId.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.plans = payload?.data?.plans;
          state.pagination.total = payload?.data?.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getPlansByHmoId.rejected, (state) => {
        state.loading = false;
      })
      /** CREATE PLAN **/
      .addCase(createPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPlan.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createPlan.rejected, (state) => {
        state.loading = false;
      })
      /** UPDATE PLAN **/
      .addCase(updatePlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePlan.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updatePlan.rejected, (state) => {
        state.loading = false;
      })

      /** ADD HOSPITALS TO PLAN **/
      .addCase(addPlanToHospitals.pending, (state) => {
        state.modal.loading = true;
      })
      .addCase(addPlanToHospitals.fulfilled, (state, { payload }) => {
        state.modal.loading = false;
      })
      .addCase(addPlanToHospitals.rejected, (state) => {
        state.modal.loading = false;
      })

      .addCase(bulkUploadHmo.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkUploadHmo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(bulkUploadHmo.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deletePlanById.pending, (state) => {
        state.modal.loading = true;
      })
      .addCase(deletePlanById.fulfilled, (state, { payload }) => {
        state.modal.loading = false;
        if (payload.success) state.modal.delete = false;
      })
      .addCase(deletePlanById.rejected, (state) => {
        state.modal.loading = false;
      })

      .addCase(deactivateHmoById.pending, (state) => {
        state.modal.loading = true;
      })
      .addCase(deactivateHmoById.fulfilled, (state, { payload }) => {
        state.modal.loading = false;
        if (payload.success) state.modal.delete = false;
      })
      .addCase(deactivateHmoById.rejected, (state) => {
        state.modal.loading = false;
      })

      .addCase(AddBankAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddBankAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(AddBankAccount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateBankAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBankAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateBankAccount.rejected, (state) => {
        state.loading = false;
      })

      .addCase(fetchBankAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBankAccounts.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(fetchBankAccounts.rejected, (state) => {
        state.loading = false;
      });

    builder.addCase(createEmployee.pending, (state, { payload }) => {});
  },
});

export const { setPagination, closeModal, openModal, setPayload } =
  hmoSlice.actions;
export const getHmoData = (state) => state.hmo;
export default hmoSlice.reducer;
