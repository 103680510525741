import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getHospitals = createAsyncThunk('/hopsital/getHospitals', ({pageSize, search} = {}, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().hospital
  return new BACKEND().send({
   type: 'get',
   to: `/hospitals/?pageNumber=${pagination.page}&pageSize=${pageSize || pagination.pageSize}&search=${search || pagination.search || ""}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const updateHospital = createAsyncThunk('/hospital/updateHospital', async ({hospitalId, ...body}, thunkAPI) => {
  const { payload } = thunkAPI.getState().auth;
  try {
    if(payload.logo) body.hospitalLogo = payload.logo;
    
    return new BACKEND().send({ 
      type: 'patch',
      to: `/hospitals/update/${hospitalId}`,
      useAlert: true,
      payload: body
    })

  } catch (error) {
    thunkAPI.rejectWithValue("An error occurred somewhere")
  }
})

export const bulkUploadHospital = createAsyncThunk('/hospital/bulkUploadHospital', async (file, thunkAPI) => {
  try {   
    return new BACKEND().send({
      type: 'post',
      to: `/hospitals/add-bulk`,
      useAlert: true,
      header: {headers: { "Content-Type": "multipart/form-data" }},
      payload: file
    })

  } catch (error) {
    thunkAPI.rejectWithValue("An error occurred somewhere")
  }
})


export const getDashboard = createAsyncThunk(
  "/hospital/getDashboard",
  (hospitalId, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/hospitals/hospital-admin/dashboard/${hospitalId}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState = {
 loading: false,
 hospitals: [],
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 },
 dashboard: {}
};

export const hospitalSlice = createSlice({
 name: 'hospital',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  }
 },
 extraReducers: (builder => {
  builder.addCase(getHospitals.pending, (state) => {
   state.loading = true;
  })
  .addCase(getHospitals.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload?.success) {
    state.hospitals = payload?.data?.hospitals
    state.pagination.total = payload?.data.count;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getHospitals.rejected, state => state.loading = false)
  .addCase(updateHospital.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateHospital.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(updateHospital.rejected, (state) => {
      state.loading = false;
    })

    .addCase(bulkUploadHospital.pending, (state) => {
      state.loading = true;
    })
    .addCase(bulkUploadHospital.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(bulkUploadHospital.rejected, (state) => {
      state.loading = false;
    })


    .addCase(getDashboard.pending, (state) => {
      state.loading = true;
    })
    .addCase(getDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.success) state.dashboard = payload.data;
    })
    .addCase(getDashboard.rejected, (state) => {
      alert(false);
      state.loading = false;
    })
    
 })
})

export const { setPagination } = hospitalSlice.actions
export const getHospitalData = state => state.hospital
export default hospitalSlice.reducer;