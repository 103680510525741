import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getConsultations = createAsyncThunk('/hopsital/getConsultations', (hospitalId, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().consultation
  return new BACKEND().send({
   type: 'get',
   to: `/consultations/hospital/${hospitalId}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

const initialState = {
 loading: false,
 consultations: [],
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 }
};

export const consultationSlice = createSlice({
 name: 'consultation',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  }
 },
 extraReducers: (builder => {
  builder.addCase(getConsultations.pending, (state) => {
   state.loading = true;
  })
  .addCase(getConsultations.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload?.success) {
    state.consultations = payload?.data?.consultations
    state.pagination.total = payload?.data?.count;
    state.pagination.length = state?.pagination?.pageSize * state?.pagination?.page
   }
  })
  .addCase(getConsultations.rejected, state => state.loading = false)
 })
})

export const { setPagination } = consultationSlice.actions
export const getConsultationsData = state => state.consultation
export default consultationSlice.reducer;