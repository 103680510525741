import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getGrades = createAsyncThunk('/user/getGrade', (search, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().grade
  return new BACKEND().send({
   type: 'get',
   to: `/grades/?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${search || pagination.search}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const createGrade = createAsyncThunk('/user/createGrade', (payload, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'post',
   to: "/grades/create",
   payload,
   useAlert: true
 })
 } catch (error) {
   console.error(error);
 }
})

const initialState = {
 loading: false,
 grades: [],
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 },
 model: {
  name: "",
  gradeNumber: "",
  description: ""
 }
};

export const gradeSlice = createSlice({
 name: 'grade',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  },
 },
 extraReducers: (builder => {
  /** GET GRADE **/ 
  builder.addCase(getGrades.pending, (state) => {
   state.loading = true;
  })
  .addCase(getGrades.fulfilled, (state, {payload}) => {
   state.loading = false;
   if(payload?.success) {
    state.grades = payload?.data?.grades
    state.pagination.total = payload?.data?.count;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getGrades.rejected, state => {
    state.loading = false
  })

  /***CREATE GRADE ***/ 
  .addCase(createGrade.pending, (state) => {
   state.loading = true;
  })
  .addCase(createGrade.fulfilled, (state, {payload}) => {
   state.loading = false;
  })
  .addCase(createGrade.rejected, state => {
    state.loading = false
  })
 })
})

export const { setPagination } = gradeSlice.actions
export const getGradeData = state => state.grade
export default gradeSlice.reducer