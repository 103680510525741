import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../../../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyData,
  getHmoCompanyTransactionHistory,
  setPagination,
} from "../../../../features/company/companySlice";
import { capitalize, getAmount, getIds } from "../../../../utils/helper";
import dayjs from "dayjs";
import Menu from "../../../../utils/data.dropdown";

const Company = () => {
  const { loading, pagination, hmoCompanyTransactionHistory } =
      useSelector(getCompanyData),
    dispatch = useDispatch();

  useEffect(() => {
    (async () =>
      await dispatch(getHmoCompanyTransactionHistory(getIds().hmo)))();
  }, [pagination.page]);

  const data = hmoCompanyTransactionHistory?.map((data) => ({
    "Ref Number": "N/A",
    "Company Name": capitalize(data?.company?.name),
    "Payment method": capitalize(data?.plan?.Hmo?.phoneNumber || "N/A"),
    Amount: "₦" + getAmount(data?.outstandingAmount || 0),
    _data: data,
  }));
  console.log(data);

  const menu = new Menu({});
  const dropdownMenu = [
    // 	menu.edit({
    // 		navigate,
    // 		route: routes.hmos.plans.plan,
    // 	}),
    // 	menu.deleteHmoPlan({action: (value) => {
    // 		setPlanDetails(value)
    // 		dispatch(openModal('delete'))
    // },
    // route: routes.companies.employees.employee,})
  ];

  return (
    <Box
      className="pt-8 px-8 mt-8 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
      sx={{ p: 0 }}
    >
      <Table
        {...{
          data,
          pagination,
          setPagination: (data) => dispatch(setPagination(data())),
          isLoading: loading,
        }}
      />
    </Box>
  );
};

export default Company;
