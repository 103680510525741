import { Box, Divider, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import TabControl from "../tabControl/TabControl";
import SignupCompany from "./SignupCompany";
import SignupHospital from "./SignupHospital";
import { IHospitalI, IHmoI, IcompanyI } from "../../utils/icons.utils";
import SignupHMO from "./SignupHMO";
import { useAuthContext } from "../../context/AuthContext";
import { setCurrentScreen } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";

const Home = ({ currentScreen }) => {
	const [currentTabView, setCurrentTabView] = useState(),
		[current, setCurrent] = useState(null),
		{ setClassName } = useAuthContext(),
		dispatch = useDispatch();

	const pages = [
		{
			id: 1,
			img: IcompanyI,
			title: "Company",
			element: <SignupCompany />,
		},
		{
			id: 2,
			img: IHospitalI,
			title: "Hospital",
			element: <SignupHospital />,
		},
		{
			id: 3,
			img: IHmoI,
			title: "H.M.O",
			element: <SignupHMO />,
		},
	];

	const handleTabChange = (id) => {
		setCurrent(id);
		setCurrentTabView(
			pages.find((page) => {
				setClassName((_) => "md:w-[80%] lg:w-[85%] xl:w-[65%]");
				return page.id === id;
			}).element,
		);
	};

	return (
		<Box className="pt-24">
			<Typography component={"h4"} variant={"h4"} className="font-bold">
				Sign Up
			</Typography>
			<Typography className="text-lg" fontSize="24px">
				Please select your role
			</Typography>

			<TabControl
				tabs={pages}
				setCurrent={handleTabChange}
				tabWrapperActiveClass="bg-[#090F47] "
				current={current}
				wraperClass="grid lg:grid-cols-3 border-0 gap-14 my-8"
				tabClass="text-[500] text-[15px] text-[#6B7588] active:text-[var(--c-primary-0)]"
				activeTabClass="text-white fw-bold"
			/>
			{currentScreen === "home-page" && current && (
				<Typography className="text-lg" fontSize="24px">
					Enter your Correct details.
				</Typography>
			)}
			{currentTabView}

			{currentScreen === "home-page" &&  (
				<>
					<Divider> OR </Divider>

					<Typography
						component="p"
						textAlign="center"
						fontSize="20px"
						marginTop="2em">
						Already have an account?{" "}
						<Link
							className="no-underline cursor-pointer"
							onClick={(_) => dispatch(setCurrentScreen("login"))}>
							Login
						</Link>{" "}
					</Typography>
				</>
			)}
		</Box>
	);
};

export default Home;
