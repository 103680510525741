import { Box } from "@mui/system";
import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import Table from "../../components/table/Table";
import HomeLayout from "../../layouts/Home";

const Billing = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 0,
    total: 0,
    length: 0,
    hidden: false,
  });
  const data = [];

  return (
    <HomeLayout
      {...{
        title: "Billing",
        subtitle: "Overview  of all information on the system.",
      }}
    >
      <Box className="my-8">
        <SearchBar placeholder="Search For Transactions..." />
      </Box>
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Table
          {...{
            data,
            pagination,
            setPagination,
          }}
        />
      </Box>
    </HomeLayout>
  );
};

export default Billing;
