import { Avatar, Box, CardContent, Chip, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import HomeLayout from "../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CardComp from "../../components/CardComp";
import config from "../../utils/config";

const { routes } = config;

const HospitalDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <HomeLayout {...{ title: "Hospital" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <Typography className="text-[400] text-xl mt-8 text-[#040316]">
          View Hospital
        </Typography>
        <LoadingButton
          className="w-[213px] h-[56px] bg-[var(--c-primary-0)] mr-8 text-[600] text-[18px] rounded-xl normal-case"
          size="small"
          type="submit"
          variant="contained"
          fullWidth
        >
          <Link {...{state, to: routes.hospitals.hospital+'/'+state?.id+'/images' }} >
            View Images
            </Link>
        </LoadingButton>
      </Box>
      <Box
        className="py-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
        sx={{ p: 0 }}
      >
        <CardComp
          className={
            "bg-[#FFFFFF] w-[95%] mb-8 md:p-2 lg:p-4 p-3 border-none rounded-lg shadow-none m-auto"
          }
        >
          <CardContent className=" ">
          <Box className="flex flex-row gap-6 md:gap-3 lg:gap-10">
            <Avatar alt="Remy Sharp" className="w-[75px] h-[75px]" src={state?.hospitalLogo} />
            <Box className="flex flex-col">
              <Box>
                <Typography
                className="text-[18px] text-[#040316] font-semibold mb-6"
                variant="h5"
              >
                {state?.name}
              </Typography>
              <Box className="xl:flex xl:flex-row grid lg:grid-rows-2 grid-rows-3 grid-flow-col md:gap-3 gap-6">
              {state?.hospitalServices?.length && state?.hospitalServices?.map((service, index) => <Chip className="bg-[#F1F1F4] rounded-lg" label={service} variant="outlined" key={index}/>)}
              </Box>
              </Box>
            </Box>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Email Address
            </Typography>
            <Typography
              className="xl:text-[20px] xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.email || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
               Phone Number
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.phoneNumber || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Branch
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.branch || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Address
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.streetName || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              State
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.state || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Hospital Type
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.hospitalType || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              RC Number
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.rcNumber || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Hospital Grade
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.hospitalGrade || "---"}
            </Typography>
          </Box>
          </CardContent>
        </CardComp>

        <Typography component={"h6"} variant={"h6"} className="mt-14 mb-6 ml-10">
        Admin Information
				</Typography>

        <CardComp
          className={
            "bg-[#FFFFFF] w-[95%] mb-8 md:p-2 p-3 border-none rounded-lg shadow-none m-auto"
          }
        >
          <CardContent className=" ">
          <Box className=" flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Email Address
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.user?.email || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
               Phone Number
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.user?.phoneNumber || "---"}
            </Typography>
          </Box>
          <Box className="mt-8 flex flex-row justify-between">
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
            >
              Address
            </Typography>
            <Typography
              className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
            >
              {state?.user?.homeAddress || "---"}
            </Typography>
          </Box>
          </CardContent>
        </CardComp>
      </Box>
    </HomeLayout>
  );
};

export default HospitalDetails;
