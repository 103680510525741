import {
  Box,
  Card,
  CardMedia,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import HomeLayout from "../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import SelectInput from "../../../components/SelectInput";
import PhoneNumberInput from "../../../components/Phone/PhoneInput";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getIds } from "../../../utils/helper";
import {
  addStaff,
  getStaffData,
  setPhoto,
  updateStaff,
} from "../../../features/hospitals/staffSlice";
import { useFormik } from "formik";
import { vaddStaff } from "../../../utils/validators";
import FileUpload from "../../../components/FileUpload";
import {
  getAuthData,
  getCities,
  getStates,
  uploadFile,
} from "../../../features/auth/authSlice";
import {
  getDesignationData,
  getDesignations,
  setPagination,
} from "../../../features/hospitals/designation";

const AddStaff = ({ editable, cb, stateModel }) => {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    { states, cities, modal } = useSelector(getAuthData),
    { model, loading } = useSelector(getStaffData),
    {
      pagination,
      loading: staffLoading,
      designations,
    } = useSelector(getDesignationData),
    {
      values,
      handleBlur,
      handleChange,
      handleFocus,
      handleSubmit,
      setValues,
      errors,
      touched,
    } = useFormik({
      validationSchema: vaddStaff,
      initialValues: stateModel || model,
      onSubmit: async (value) => {
        const action = editable ? updateStaff : addStaff;
        const payload = {
          ...values,
          hospitalId: editable ? stateModel.hospitalId : getIds().hospital,
          employeeId: editable ? stateModel.userId : value.employeeId,
        };
        const res = await dispatch(action(payload)).unwrap();
        res?.success === true && navigate(-1);
      },
    });
  const handleUpload = async (payload) => {
    try {
      const res = await dispatch(uploadFile(payload)).unwrap();
      if (res?.success) {
        dispatch(setPhoto(res?.data?.[0]?.imageUrl));
        setValues({ ...values, profileImage: res?.data?.[0]?.imageUrl });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(setPhoto(""));
    dispatch(getStates());
  }, []);

  useEffect(() => {
    if (editable) {
      const tag = states?.find(
        (state) => state?.state === stateModel?.state
      )?.tag;
      stateModel?.city && tag && dispatch(getCities(tag));
    } else
      values.state &&
        dispatch(
          getCities(
            states?.find((state) => state?.state === values?.state)?.tag
          )
        );
  }, [values.state, states.length]);

  useEffect(() => {
    dispatch(getDesignations({ hospitalId: getIds().hospital }));
  }, [pagination.search]);

  const handleSearch = (e) => {
    e.stopPropagation();
    const {
      target: { value },
    } = e;
    let cb = null;
    if (value)
      cb = setTimeout(() => dispatch(setPagination({ search: value })), 10);
    else if (cb) {
      dispatch(getDesignations({ hospitalId: getIds().hospital }));
      clearTimeout(cb);
    } else dispatch(getDesignations({ hospitalId: getIds().hospital }));
  };
  return (
    <HomeLayout {...{ title: "Staff Management" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mx-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Typography className="text-[400] text-xl mt-8 ml-3 text-[#040316]">
        {editable ? "Edit Staff" : "New Staff"}
      </Typography>
      <Box
        className="rounded-lg"
        sx={{
          className: "w-[100%] lg:w-[80%]",
          px: "2em",
          py: "2em",
          mt: "2em",
          bgcolor: "var(--c-bg-color)",
        }}
        noValidate
        component="form"
        onSubmit={handleSubmit}
      >
        <Box className="grid lg:grid-cols-2 gap-4 pb-20  m-auto">
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="employeeFirstName"
              label="First Name"
              name="employeeFirstName"
              value={values.employeeFirstName}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.employeeFirstName && touched.employeeFirstName}
              helperText={
                errors.employeeFirstName && touched.employeeFirstName
                  ? errors.employeeFirstName
                  : ""
              }
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="employeeLastName"
              label="Last Name"
              name="employeeLastName"
              value={values.employeeLastName}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.employeeLastName && touched.employeeLastName}
              helperText={
                errors.employeeLastName && touched.employeeLastName
                  ? errors.employeeLastName
                  : ""
              }
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="employeeEmail"
              label="Email Address"
              name="employeeEmail"
              value={values.employeeEmail}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.employeeEmail && touched.employeeEmail}
              helperText={
                errors.employeeEmail && touched.employeeEmail
                  ? errors.employeeEmail
                  : ""
              }
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <PhoneNumberInput
              {...{
                phoneNo: values.employeePhoneNumber,
                setPhoneNo: (val) =>
                  setValues({ ...values, employeePhoneNumber: val }),
                error:
                  errors.employeePhoneNumber && touched.employeePhoneNumber
                    ? errors.employeePhoneNumber
                    : "",
                onFocus: handleFocus,
                onBlur: handleBlur,
              }}
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <SelectInput
              {...{
                className: " w-[100%] bg-white ",
                value: values.gender,
                name: "gender",
                label: "Gender",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.gender && touched.gender,
                helperText: errors.gender,
                options: ["male", "female"],
              }}
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <SelectInput
              {...{
                className: "w-full bg-white ",
                value: values.state,
                name: "state",
                label: "State",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.state && touched.state,
                helperText: errors.state,
                options: states?.map(({ state }) => state),
              }}
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <SelectInput
              {...{
                className: "w-full bg-white",
                value: values.city,
                name: "city",
                label: "City",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.city && touched.city,
                helperText: errors.city,
                options: cities?.map(({ lga }) => lga),
              }}
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="street"
              label="Street"
              name="street"
              value={values.street}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.street && touched.street}
              helperText={errors.street && touched.street ? errors.street : ""}
            />
          </FormControl>

          <FormControl className="w-full border-none lg:mt-5">
            <SelectInput
              {...{
                className: "w-full bg-white",
                value: values.specialization,
                name: "specialization",
                label: "Specialization",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.specialization && touched.specialization,
                helperText: errors.specialization,
                options: [
                  "Engineer",
                  "HR Admin",
                  "Customer Care",
                  "Chef",
                  "Health professional",
                ],
              }}
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <SelectInput
              {...{
                className: "w-full bg-white ",
                value: values.designation,
                name: "designation",
                label: "Designation",
                handleChange,
                handleBlur,
                handleFocus,
                loading: !pagination.search && staffLoading,
                searchLoading: pagination.search && staffLoading,
                handleSearch,
                error: errors.designation && touched.designation,
                helperText: errors.designation,
                options: designations?.map(({ name }) => name),
              }}
            />
          </FormControl>
        </Box>
        <FileUpload
          {...{
            action: handleUpload,
            loading: modal.photoLoading,
            name: "profileImage",
            errors,
            touched,
            handleChange,
            label: "Upload Staff Picture",
            required: true,
          }}
        />
        {(values?.profileImage || stateModel?.profileImage) && (
          <Card className="w-60 h-50 m-auto my-8">
            <CardMedia
              component="img"
              alt="Selected Image"
              height="163px"
              width="266px"
              image={values?.profileImage || stateModel?.profileImage}
              title="Selected Image"
            />
          </Card>
        )}

        <Box className="flex justify-center   m-auto">
          <LoadingButton
            className="my-10 rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] w-[50%] self-end shadow-none normal-case"
            sx={{
              ".MuiLoadingButton-loadingIndicatorCenter": {
                color: "var(--c-bg-color) !important",
              },
            }}
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            fullWidth
          >
            {editable ? "Save Changes" : "Add"}
          </LoadingButton>
        </Box>
      </Box>
    </HomeLayout>
  );
};

export default AddStaff;
