import Dashboard from "./dashboard/Index";
import config from "../utils/config";
import Hospitals from "./hospitals/Index";
import Configurations from "./configurations/aillment/Index";
import HMO from "./hMOs/Index";
import AddHospital from "./hospitals/AddHospital";
import UploadHospital from "./hospitals/UploadHospital";
import AddHmo from "./hMOs/AddHmo";
import UploadHmo from "./hMOs/UploadHmo";
import HospitalDetails from "./hospitals/HospitalDetails";
import Company from "./company/Index";
import AddCompany from "./company/AddCompany";
import CreateAilment from "./configurations/aillment/CreateAilment";
import Grade from "./configurations/grade/Index";
import CreateGrade from "./configurations/grade/CreatGrade";
import ViewImage from "../utils/ViewImage";
import HmoDetails from "./hMOs/HmoDetails";
import CompanyDetails from "./company/CompanyDetails";
import UserManagement from "./configurations/userManagement/Index";
import Settings from "./configurations/settings/Index";
import CreateRole from "./configurations/role/CreateRole";
import HmoPlans from "./hMOs/plan/HmoPlans";
import CreateHmoPlan from "./hMOs/plan/CreateHmoPlan";
import ClientHistory from "./hMOs/hmoTransactions/clientHistory/Index";
import ClientDebt from "./hMOs/hmoTransactions/clientDebt/Index";
import HospitalHistory from "./hMOs/hmoTransactions/hospitalHistory/Index";
import HospitalDebt from "./hMOs/hmoTransactions/hospitalDebt/Index";
import Employees from "./employees/Index";
import AddEmployee from "./employees/AddEmployee";
import UploadEmployee from "./employees/UploadEmployee";
import HmoHistory from "./company/transactionHistory/Index";
import HmoDebt from "./company/transactionHistory/Payment";
import EditEmployee from "./employees/EditEmployee";
import EmployeeDetails from "./employees/EmployeeDetails";
import CardReplacement from "./employees/CardReplacement";
import Profile from "./settings/Profile";
import EditPlan from "./hMOs/plan/EditPlan";
import Plans from "./company/plan/Index";
import AddPlan from "./company/plan/AddPlan";
import HmoAccounts from "./hMOs/hmoConfig/hmoAccounts/Index";
import AddHmoAccount from "./hMOs/hmoConfig/hmoAccounts/AddHmoAccount";
import Billing from "./hMOs/Billing";
import HmoClients from "./hMOs/hmoClients/Index";
import HmoHospitals from "./hMOs/HmoHospitals";
import HmoServices from "./hMOs/hmoConfig/hmoServices/Index";
import AddService from "./hMOs/hmoConfig/hmoServices/AddService";
import HmoRoles from "./hMOs/hmoConfig/hmoUsers/Index";
import AddHmoUser from "./hMOs/hmoConfig/hmoUsers/AddHmoUser";
import HospitalStaffs from "./hospitals/staff/Index";
import Consultation from "./hospitals/staff/Consultation";
import AddStaff from "./hospitals/staff/AddStaff";
import HospitalHmo from "./hospitals/HospitalHmo";
import HospitalHmoHistory from "./hospitals/HospitalHmoHistory";
import HospitalHmoDebt from "./hospitals/HospitalHmoDebt";
import ConfirmPaynent from "./hospitals/ConfirmPaynent";
import HospitalConfig from "./hospitals/HospitalConfig";
import HospitalAppointment from "./hospitals/HospitalAppointment";
import StaffDetails from "./hospitals/staff/StaffDetails";
import EditStaff from "./hospitals/staff/EditStaff";
import EditAilment from "./configurations/aillment/EditAilment";
import Patients from "./hospitals/patients/Index";
import Roles from "./configurations/role/Index";
import AddConsultation from "./hospitals/patients/consultation/AddNew";
import ConsultHistory from "./hospitals/patients/consultation/ConsultHistory";
import ConsultDetails from "./hospitals/patients/consultation/ConsultDetails";
import EmployeeConsultation from "./employees/EmployeeConsultation";
import Designation from "./hospitals/designation/Index";
import CreateDesignation from "./hospitals/designation/CreateDesignation";
import EditDesignation from "./hospitals/designation/EditDesignation";
import PlanDetails from "./company/plan/PlanDetails";

const { routes } = config;
const Pages = [
  {
    path: routes.dashboard,
    element: <Dashboard />,
  },
  {
    path: routes.hospitals.hospital,
    element: <Hospitals />,
  },
  {
    path: routes.hmos.hmo,
    element: <HMO />,
  },
  {
    path: routes.configurations.ailments,
    element: <Configurations />,
  },
  {
    path: routes.configurations.editAilment,
    element: <EditAilment />,
  },
  {
    path: routes.hospitals.new,
    element: <AddHospital />,
  },
  {
    path: routes.hospitals.edit,
    element: <AddHospital />,
  },
  {
    path: routes.hospitals.view,
    element: <HospitalDetails />,
  },
  {
    path: routes.hospitals.upload,
    element: <UploadHospital />,
  },
  {
    path: routes.hmos.new,
    element: <AddHmo />,
  },
  {
    path: routes.hmos.edit,
    element: <AddHmo />,
  },
  {
    path: routes.hmos.upload,
    element: <UploadHmo />,
  },
  {
    path: routes.hmos.view,
    element: <HmoDetails />,
  },
  {
    path: routes.companies.company,
    element: <Company />,
  },
  {
    path: routes.companies.new,
    element: <AddCompany />,
  },
  {
    path: routes.companies.edit,
    element: <AddCompany />,
  },
  {
    path: routes.companies.view,
    element: <CompanyDetails />,
  },
  {
    path: routes.createAilment,
    element: <CreateAilment />,
  },
  {
    path: routes.configurations.grade,
    element: <Grade />,
  },
  {
    path: routes.createGrade,
    element: <CreateGrade />,
  },
  {
    path: routes.hospitals.images,
    element: <ViewImage />,
  },
  {
    path: routes.configurations.userManagement,
    element: <UserManagement />,
  },
  {
    path: routes.configurations.roleManagement.role,
    element: <Roles />,
  },
  {
    path: routes.configurations.roleManagement.new,
    element: <CreateRole />,
  },
  {
    path: routes.configurations.settings,
    element: <Settings />,
  },
  {
    path: routes.hospitals.patients.consultations.new,
    element: <AddConsultation />,
  },
  {
    path: routes.hospitals.patients.consultations.history,
    element: <ConsultHistory />,
  },
  {
    path: routes.hospitals.patients.consultations.view,
    element: <ConsultDetails />,
  },
  {
    path: routes.hmos.plans.plan,
    element: <HmoPlans />,
  },
  {
    path: routes.companies.plans.plan,
    element: <Plans />,
  },
  {
    path: routes.companies.plans.new,
    element: <AddPlan />,
  },
  {
    path: routes.hmos.plans.new,
    element: <CreateHmoPlan />,
  },
  {
    path: routes.hmos.plans.edit,
    element: <EditPlan />,
  },
  {
    path: routes.hmos.transactions.clientHistory,
    element: <ClientHistory />,
  },
  {
    path: routes.hmos.transactions.clientDebt,
    element: <ClientDebt />,
  },
  {
    path: routes.hmos.transactions.hospitalHistory,
    element: <HospitalHistory />,
  },
  {
    path: routes.hmos.transactions.hospitalDebt,
    element: <HospitalDebt />,
  },
  {
    path: routes.companies.employees.transactions.hmoHistory,
    element: <HmoHistory />,
  },
  {
    path: routes.companies.employees.transactions.hmoDebt,
    element: <HmoDebt />,
  },
  {
    path: routes.companies.employees.employee,
    element: <Employees />,
  },
  {
    path: routes.companies.employees.new,
    element: <AddEmployee />,
  },
  {
    path: routes.companies.employees.view,
    element: <EmployeeDetails />,
  },
  {
    path: routes.companies.employees.edit,
    element: <EditEmployee />,
  },
  {
    path: routes.companies.employees.upload,
    element: <UploadEmployee />,
  },
  {
    path: routes.companies.employees.replaceCard,
    element: <CardReplacement />,
  },
  {
    path: routes.settings.profile,
    element: <Profile />,
  },
  {
    path: routes.hmos.configurations.accounts.account,
    element: <HmoAccounts />,
  },
  {
    path: routes.hmos.configurations.accounts.new,
    element: <AddHmoAccount />,
  },
  {
    path: routes.hmos.billing,
    element: <Billing />,
  },
  {
    path: routes.hmos.client,
    element: <HmoClients />,
  },
  {
    path: routes.hospitals.staffs.view,
    element: <StaffDetails />,
  },
  {
    path: routes.hmos.hospitals,
    element: <HmoHospitals />,
  },
  {
    path: routes.hmos.configurations.services.service,
    element: <HmoServices />,
  },
  {
    path: routes.hmos.configurations.services.new,
    element: <AddService />,
  },
  {
    path: routes.hmos.configurations.userManagement.user,
    element: <HmoRoles />,
  },
  {
    path: routes.hmos.configurations.userManagement.new,
    element: <AddHmoUser />,
  },
  {
    path: routes.hmos.configurations.userManagement.edit,
    element: <AddHmoUser />,
  },
  {
    path: routes.hospitals.staffs.staff,
    element: <HospitalStaffs />,
  },
  {
    path: routes.hospitals.staffs.edit,
    element: <EditStaff />,
  },
  {
    path: routes.hospitals.staffs.consultation,
    element: <Consultation />,
  },
  {
    path: routes.hospitals.staffs.new,
    element: <AddStaff />,
  },
  {
    path: routes.hospitals.hmo,
    element: <HospitalHmo />,
  },
  {
    path: routes.hospitals.hmoHistory,
    element: <HospitalHmoHistory />,
  },
  {
    path: routes.hospitals.hmoDebt,
    element: <HospitalHmoDebt />,
  },
  {
    path: routes.hospitals.confirmPayment,
    element: <ConfirmPaynent />,
  },
  {
    path: routes.hospitals.appointment,
    element: <HospitalAppointment />,
  },
  {
    path: routes.hospitals.designations.designation,
    element: <Designation />,
  },
  {
    path: routes.hospitals.designations.new,
    element: <CreateDesignation />,
  },
  {
    path: routes.hospitals.designations.edit,
    element: <EditDesignation />,
  },
  {
    path: routes.hospitals.configurations,
    element: <HospitalConfig />,
  },
  {
    path: routes.hospitals.staffs.edit,
    element: <EditStaff />,
  },
  {
    path: routes.hospitals.patients.patient,
    element: <Patients />,
  },
  {
    path: routes?.companies?.employees?.consultations?.view,
    element: <EmployeeConsultation />,
  },
  {
    path: routes?.companies?.plans?.view,
    element: <PlanDetails />,
  },
];
export default Pages;
