import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getEmployees = createAsyncThunk(
  "/company/employee/getEmplyees",
  (hmoId, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().hmoEmployee;
      return new BACKEND().send({
        type: "get",
        to: `/hmos/get-employees/${hmoId}/?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

// export const getEmployeesById = createAsyncThunk(
//   "/company/getEmployeesById",
//   (companyId, employeeId, thunkApi) => {
//     try {
//       return new BACKEND().send({
//         type: "get",
//         to: `/companies/get-employees/${companyId}/${employeeId}`,
//         useAlert: false,
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   }
// );

export const deleteEmployee = createAsyncThunk(
  "/company/employee/deleteEmployee",
  ({ hmoId, employeeId }, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "delete",
        to: `/hmos/employees/${hmoId}/${employeeId}`,
        useAlert: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const addEmployee = createAsyncThunk(
  "/hmo/employees/addEmployee",
  ({ employeeUserId, ...payload }, thunkAPI) => {
    payload = {
      ...payload,
      profileImage: thunkAPI.getState().hmoEmployee.models.profileImage,
    };

    return new BACKEND().send({
      type: "post",
      to: "/hmos/add-employee",
      useAlert: true,
      payload,
    });
  }
);

export const updateEmployee = createAsyncThunk(
  "/hmo/employees/updateEmployee",
  ({ hmoId, employeeUserId, ...payload }, thunkAPI) => {
    if (thunkAPI.getState().hmoEmployee.models.profileImage)
      payload.profileImage =
        thunkAPI.getState().hmoEmployee.models.profileImage;
    return new BACKEND().send({
      type: "patch",
      to: `/hmos/employees/${hmoId}/${employeeUserId}`,
      useAlert: true,
      payload,
    });
  }
);

// export const cancelEmployeeHmoPlan = createAsyncThunk(
//   "/company/employees/cancelEmployeeHmoPlan",
//   async (payload, thunkAPI) => {
//     const res = await new BACKEND().send({
//       type: "post",
//       to: `/companies/remove-employee-plan`,
//       useAlert: true,
//       payload,
//     });
//     if (res?.success) {
//       thunkAPI.dispatch(getEmployees(payload.companyId));
//       thunkAPI.dispatch(closeModal("cancelPlan"));
//     }
//     return res;
//   }
// );

// export const addEmployeePlan = createAsyncThunk(
//   "/company/employees/addEmployeePlan",
//   async (payload, thunkAPI) => {
//     const res = await new BACKEND().send({
//       type: "post",
//       to: `/companies/add-employee-plan`,
//       useAlert: true,
//       payload,
//     });

//     if (res?.success) {
//       thunkAPI.dispatch(getEmployees(payload.companyId));
//       thunkAPI.dispatch(closeModal("addPlan"));
//     }
//     return res;
//   }
// );

// export const bulkUploadEmployee = createAsyncThunk(
//   "/auth/bulkUploadEmployee",
//   async ({ companyId, file }, thunkAPI) => {
//     try {
//       return new BACKEND().send({
//         type: "post",
//         to: `/companies/add-employees-bulk/${companyId}`,
//         useAlert: true,
//         header: { headers: { "Content-Type": "multipart/form-data" } },
//         payload: file,
//       });
//     } catch (error) {
//       thunkAPI.rejectWithValue("An error occurred somewhere");
//     }
//   }
// );

// export const getEmployeeConsultations = createAsyncThunk(
//   "/company/employees/getEmployeeConsultations",
//   ({ companyId, employeeId }, thunkApi) => {
//     try {
//       const { pagination } = thunkApi.getState().employee;
//       return new BACKEND().send({
//         type: "get",
//         to: `/consultations/company/employees/${companyId}/${employeeId}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
//         useAlert: false,
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   }
// );

// export const getHmoIndividualTransactionHistory = createAsyncThunk(
//   "getHmoIndividualTransactionHistory",
//   (hmoId, thunkApi) => {
//     try {
//       return new BACKEND().send({
//         type: "get",
//         to: `/hmos/hmo-individual-transactions/${hmoId}?isPaid=false
//         `,
//         useAlert: false,
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   }
// );

const initialState = {
  loading: false,
  employees: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    search: "",
  },
  modal: {
    addPlan: false,
    loading: false,
    delete: false,
  },
  models: {
    // hmoId: "",
    employeeFirstName: "",
    employeeLastName: "",
    employeeEmail: "",
    employeePhoneNumber: "",
    profileImage: "",
    employeeId: "",
    state: "",
    city: "",
    street: "",
    fullName: "",
    employeeRole: "",
  },
};

export const employeeSlice = createSlice({
  name: "hmoEmployee",
  initialState,
  reducers: {
    setPayload: (state, { payload }) => {
      state.models = { ...state.models, ...payload };
    },
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    closeModal: (state, { payload }) => {
      state.modal[payload] = false;
    },
    openModal: (state, { payload }) => {
      state.modal[payload] = true;
    },
    setPhoto: (state, { payload }) => {
      state.models.profileImage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployees.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.employees = payload?.data?.employees;
          state.pagination.total = payload?.data?.count;
          state.pagination.length =
            state?.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getEmployees.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(addEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(addEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(addEmployee.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(updateEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateEmployee.rejected, (state) => {
        state.loading = false;
      })

      //   /** GET CONSULTATION BY EMPLOYEE ID **/
      //   .addCase(getEmployeeConsultations.pending, (state) => {
      //     state.loading = true;
      //   })
      //   .addCase(getEmployeeConsultations.fulfilled, (state, { payload }) => {
      //     state.loading = false;
      //     if (payload?.success) {
      //       state.consultations = payload?.data?.consultations;
      //       state.pagination.total = payload?.data?.count;
      //       state.pagination.length =
      //         state?.pagination?.pageSize * state?.pagination?.page;
      //     }
      //   })
      //   .addCase(
      //     getEmployeeConsultations.rejected,
      //     (state) => (state.loading = false)
      //   );

      // /*** CANCEL HMO PLAN**/
      // builder
      //   .addCase(cancelEmployeeHmoPlan.pending, (state) => {
      //     state.modal.loading = true;
      //   })
      //   .addCase(cancelEmployeeHmoPlan.fulfilled, (state, { payload }) => {
      //     state.modal.loading = false;
      //   })
      //   .addCase(cancelEmployeeHmoPlan.rejected, (state) => {
      //     state.modal.loading = false;
      //   })

      /** DELETE EMPLOYEE **/
      .addCase(deleteEmployee.pending, (state) => {
        state.modal.loading = true;
      })
      .addCase(deleteEmployee.fulfilled, (state) => {
        state.modal.loading = false;
        state.modal.delete = false;
      })
      .addCase(deleteEmployee.rejected, (state) => {
        state.modal.loading = false;
      });
    // /*** ADD HMO PLAN**/
    // builder
    //   .addCase(addEmployeePlan.pending, (state) => {
    //     state.modal.loading = true;
    //   })
    //   .addCase(addEmployeePlan.fulfilled, (state, { payload }) => {
    //     state.modal.loading = false;
    //   })
    //   .addCase(addEmployeePlan.rejected, (state) => {
    //     state.modal.loading = false;
    //   })
    //   .addCase(bulkUploadEmployee.pending, (state, { payload }) => {
    //     state.loading = true;
    //   })
    //   .addCase(bulkUploadEmployee.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(bulkUploadEmployee.rejected, (state) => {
    //     state.loading = false;
    //   });

    // // getHmoIndividualTransactionHistory
    // builder
    //   .addCase(getHmoIndividualTransactionHistory.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(
    //     getHmoIndividualTransactionHistory.fulfilled,
    //     (state, { payload }) => {
    //       state.loading = false;
    //       if (payload?.success === true) {
    //         state.hmoIndividualTransactionHistory = payload?.data?.transactions;
    //         state.pagination.total = payload?.data?.count;
    //         state.pagination.length =
    //           state.pagination.pageSize * state.pagination.page;
    //       }
    //     }
    //   )
    //   .addCase(getHmoIndividualTransactionHistory.rejected, (state) => {
    //     state.loading = false;
    //   });
    /*** getHmoIndividualTransactionHistory ***/
  },
});

export const { setPagination, setPayload, setPhoto, openModal, closeModal } =
  employeeSlice.actions;
export const getEmployeeData = (state) => state.hmoEmployee;
export default employeeSlice.reducer;
