import { Avatar, Box, CardContent, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeLayout from "../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardComp from "../../../components/CardComp";

const EmployeeDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  return (
    <HomeLayout {...{ title: "Staff Management" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <Typography className="text-[400] text-xl mt-8 text-[#040316]">
          View Staff
        </Typography>
      </Box>
      <Box
        className="py-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
        sx={{ p: 0 }}
      >
        <CardComp
          className={
            "bg-[#FFFFFF] w-[95%] mb-8 md:p-2 lg:p-4 p-3 border-none rounded-lg shadow-none m-auto"
          }
        >
          <CardContent className=" ">
            <Box className="flex flex-row gap-6 md:gap-3 lg:gap-10">
              <Avatar
                alt="Profile picture"
                className="w-[100px] h-[100px]"
                src={state?.User?.profileImage}
              />
              <Box className="flex flex-col">
                <Box className="flex flex-col">
                  <Typography
                    className="text-[24px] text-[#040316] font-semibold mt-8"
                    variant="h5"
                  >
                    {state?.User?.firstName + " " + state?.User?.lastName}
                  </Typography>
                  <Typography className="text-lg text-[#040316]">
                    {state?.designation}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Email Address
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.User?.email || "---"}
              </Typography>
            </Box>
            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Phone Number
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.User?.phoneNumber || "---"}
              </Typography>
            </Box>
            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Gender
              </Typography>
              <Typography className="xl:text-[20px] capitalize md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.User?.gender || "N/A"}
              </Typography>
            </Box>

            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Designation
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.designation || "---"}
              </Typography>
            </Box>

            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Specialization
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.specialization || "---"}
              </Typography>
            </Box>

            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Address
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.street || "---"}
              </Typography>
            </Box>
          </CardContent>
        </CardComp>
      </Box>
    </HomeLayout>
  );
};

export default EmployeeDetails;
