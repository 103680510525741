import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import ProtectedRoutes from "./components/others/ProtectedRoutes";
import Home from "./pages/home/Index";
import Pages from "./pages/Index";
import config from "./utils/config";
import Auth from "./pages/home/Auth";

function App() {
	const { routes } = config;

	return (
		<BrowserRouter>
			<div
				className={`App ${window.location.pathname === routes.auth && "m-2"}`}>
				<Routes>
					<Route
						{...{
							path: routes.auth,
							element: <Auth />,
						}}
					/>
					<Route
						{...{
							path: routes.home,
							element: <Home />,
						}}
					/>

					<Route element={<ProtectedRoutes />}>
						{Pages.map((p, i) => (
							<Route key={i} {...p} />
						))}
					</Route>
				</Routes>
			</div>
		</BrowserRouter>
	);
}

export default App;
