import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getUsers = createAsyncThunk('/user/getUsers', (search, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().user
  return new BACKEND().send({
   type: 'get',
   to: `/users/?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${search || pagination.search}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})


export const getUserById = createAsyncThunk('/user/getUserById', (id, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'get',
    to: `/users/${id}`,
    useAlert: false
  })
  } catch (error) {
    console.error(error);
  }
 })

const initialState = {
 loading: false,
 users: [],
 user:{},
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 }
};

export const userSlice = createSlice({
 name: 'user',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  },
 },
 extraReducers: (builder => {
  builder
  .addCase(getUsers.pending, (state) => {
   state.loading = true;
  })
  .addCase(getUsers.fulfilled, (state, {payload}) => {
   state.loading = false;
   if(payload?.success) {
    state.users = payload?.data?.users
    state.pagination.total = payload?.data?.count;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getUsers.rejected, state => {
    state.loading = false
  })
  .addCase(getUserById.pending, (state) => {
    state.loading = true;
   })
   .addCase(getUserById.fulfilled, (state, {payload}) => {
    state.loading = false;
    if(payload?.success) {
     state.user = payload?.data
    }
   })
   .addCase(getUserById.rejected, state => {
     state.loading = false
   })
 })
})

export const { setPagination } = userSlice.actions
export const getUserData = state => state.user
export default userSlice.reducer