import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Table from "../../../components/table/Table";
import HomeLayout from "../../../layouts/Home";
import {
  getCompanyData,
  getCompanyOutstandingPayment,
  setPagination,
} from "../../../features/company/companySlice";
import { capitalize, getAmount, getIds } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import TablePreloader from "../../../components/Loader/TablePreloader";
import { IError } from "../../../utils/icons.utils";

const HmoDebt = () => {
  const { loading, pagination, hmoOutstandingPayment } =
      useSelector(getCompanyData),
    dispatch = useDispatch();

  useEffect(() => {
    (async () =>
      await dispatch(getCompanyOutstandingPayment(getIds().company)))();
  }, [pagination.page]);

  const data = hmoOutstandingPayment?.map((data) => ({
    "HMO Name": capitalize(data?.plan?.Hmo.name),
    "Phone Number": capitalize(data?.plan?.Hmo?.phoneNumber),
    "Last Payment Date": data?.lastPaymentDate
      ? dayjs(data?.lastPaymentDate).format("DD MMM, YYYY")
      : "N/A",
    "Outstanding Amount": "₦" + getAmount(data?.outstandingAmount),
    _data: data,
  }));

  return (
    <HomeLayout
      {...{
        title: "Transaction Management",
        subtitle: "Overview  of all information on the system.",
      }}
    >
      <Box className="ml-3">
        <Typography className="text-[400] text-xl mt-8 mb-5  text-[#040316]">
          H. M. O Outstanding Payment
        </Typography>
      </Box>

      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        {/* {loading ? (
          <TablePreloader />
        ) : data?.length ? ( */}
        <Table
          {...{
            data,
            pagination,
            setPagination: (data) => dispatch(setPagination(data())),
            isLoading: loading,
          }}
        />
        {/* ) : (
          <Box
            className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
            sx={{ p: 0 }}
          >
            <img
              className="m-auto w-[350px] mt-8"
              src={IError}
              alt={"error"}
              loading="lazy"
            />
            <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
              No Outstanding Payment in the system at the moment
            </Typography>
          </Box>
        )} */}
      </Box>
    </HomeLayout>
  );
};

export default HmoDebt;
