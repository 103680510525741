import { Avatar, Box, CardContent, Typography } from '@mui/material'
import React from 'react'
import HomeLayout from '../../../../layouts/Home';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from 'react-router-dom';
import CardComp from '../../../../components/CardComp';
import { formatDate, getAmount } from '../../../../utils/helper';

const ConsultDetails = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
  return (
    <HomeLayout {...{ title: "Patients" }}>
    <Box className="flex  mt-2 text-[var(--c-dark-1)]">
      <ArrowBackIcon
        onClick={(_) => navigate(-1)}
        className=" mr-2 cursor-pointer"
        fontSize="small"
      />
      <Typography className="text-[400] text-[16px] ">Go back</Typography>
    </Box>
    <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
      <Typography className="text-[400] text-xl mt-8 text-[#040316]">
      View Consultation
      </Typography>
    </Box>
    <Box
      className="py-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
      sx={{ p: 0 }}
    >
      <CardComp
        className={
          "bg-[#FFFFFF] w-[95%] mb-8 md:p-2 lg:p-4 p-3 border-none rounded-lg shadow-none m-auto"
        }
      >
        <CardContent className=" ">
        <Box className="flex flex-row gap-6 md:gap-3 lg:gap-10">
          <Avatar alt="Remy Sharp" className="w-[100px] h-[100px]" src={state?.patient?.profileImage} />
          <Box className="flex flex-col">
            <Box>
              <Typography
              className="text-[24px] text-[#040316] font-semibold mt-8"
              variant="h5"
            >
              {state?.patient?.firstName + " " + state?.patient?.lastName}
            </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="mt-8 flex flex-row justify-between">
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
          >
            Consultation Date
          </Typography>
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
          >
            {formatDate(state?.createdAt) || "---"}
          </Typography>
        </Box>
        <Box className="mt-8 flex flex-row justify-between">
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
          >
            Ailment
          </Typography>
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
          >
            {state?.ailment || "---"}
          </Typography>
        </Box>
        <Box className="mt-8 flex flex-row justify-between">
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
          >
            Prescription
          </Typography>
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
          >
            {state?.prescription || "---"}
          </Typography>
        </Box>
        <Box className="mt-8 flex flex-row justify-between">
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
          >
            Doctor
          </Typography>
          <Typography
            className="xl:text-[20px] xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
          >
            {state?.consultant?.firstName + " " + state?.consultant?.lastName || "N/A"}
          </Typography>
        </Box>

        <Box className="mt-8 flex flex-row justify-between">
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
          >
            Consultation Amount
          </Typography>
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
          >
            {"₦" + getAmount(state?.amount) || "---"}
          </Typography>
        </Box>
        
        <Box className="mt-8 flex flex-row justify-between">
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]"
          >
            Ailment Descrption
          </Typography>
          <Typography
            className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]"
          >
            {state?.description || "---"}
          </Typography>
        </Box>
        </CardContent>
      </CardComp>
    </Box>
    </HomeLayout>
  )
}

export default ConsultDetails