import { Box, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import HomeLayout from '../../layouts/Home'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';


const CardReplacement = () => {
  const navigate = useNavigate();
  return (
    <HomeLayout {...{ title: "Employee" }}>
<Box className="flex  mt-2 text-[var(--c-dark-1)]">
      <ArrowBackIcon
        onClick={(_) => navigate(-1)}
        className=" mr-2 cursor-pointer"
        fontSize="small"
      />
      <Typography className="text-[400] text-[16px] ">Go back</Typography>
    </Box>

    <Box
				component="form"
				marginTop=""
				noValidate
				// onSubmit={handleSubmit}
        className='mt-[3em]'>
            <Box className=" px-8 pb-8 bg-[var(--c-bg-color)] rounded-lg w-100% flex flex-col " sx={{ p: 0 }}>
            <Typography className="text-[400] text-[22px] my-14 ml-8 text-[#040316]">
            Card Replacement Request
    </Typography>
                    <FormControl className="w-[50%] border-none mb-8  m-auto">
						<TextField
							className="w-full bg-white "
							id="name"
                            multiline
                            rows={4}
							label="Enter Reason"
							name="name"
							// value={values.name}
							// onFocus={handleFocus}
							// onChange={handleChange}
							// onBlur={handleBlur}
							// error={errors.name && touched.name}
							// helperText={
							// 	errors.name && touched.name
							// 		? errors.name
							// 		: ""
							// }
						/>
					</FormControl>
          <Box className="w-[50%] m-auto flex justify-end">
					<LoadingButton
						className="my-10 lg:w-[20.1%] w-full rounded-xl text-[12px] lg:text-[18px] bg-[var(--c-primary-0)] normal-case"
						size="large"
						type="submit"
						variant="contained"
						fullWidth
						// loading={loading}
                        >
						Create
					</LoadingButton>
				</Box>
        </Box>
        </Box>
      </HomeLayout>
  )
}

export default CardReplacement