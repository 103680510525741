import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Alert from "../../utils/alert";
import BACKEND from "../../utils/backend";
import config from "../../utils/config";
import Storage from "../../utils/storage";

const backendUrl = config.backendUrl.endsWith('/') ? config.backendUrl : config.backendUrl+'/';

export const getRoles = createAsyncThunk('/role/getRoles', (_, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().role
  return new BACKEND().send({
   type: 'get',
   to: `/role/?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const createRole = createAsyncThunk('/role/createRole', (payload, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'post',
   to: '/role',
   payload,
   useAlert: true
 })
 } catch (error) {
   console.error(error);
 }
})

export const deleteRole = createAsyncThunk('/role/deleteRole', async (id, thunkApi) => {
  try {
  //  return new BACKEND().send({
  //   type: 'delete',
  //   to: '/role',
  //   payload: {roleIds: [id]},
  //   useAlert: true
  // })
  let res = await axios.delete(`${backendUrl}role`, {data: {roleIds: [id]}, headers: {
    authorization: Storage.get(config.authProps[0])
  }})
  console.log(res.data);
  Alert({type: 'success', message: res.data.message || 'Role deleted successfully'})
return res.data

  } catch (error) {
    console.error(error.response.data);
    Alert({type: 'error', message: error.response.data.message})
  }
 })

export const getPermissions = createAsyncThunk('/role/getPermissions', () => {
 try {
  return new BACKEND().send({
   type: 'get',
   to: "/permission",
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

const initialState = {
 loading: false,
 roles: [],
 permissions: [],
 model: {
  name: '',
  permissions: []
 },
 modal: {
  delete: false,
  loading: false,
 },
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0
 }
};

export const roleSlice = createSlice({
 name: 'role',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  },
  closeModal: (state, {payload}) => {
    state.modal[payload] = false
  },
  openModal: (state, {payload}) => {
    state.modal[payload] = true
  },
 },
 extraReducers: (builder => {
  builder.addCase(getRoles.pending, (state) => {
   state.loading = true;
  })
  .addCase(getRoles.fulfilled, (state, {payload}) => {
   state.loading = false;
   if(payload?.success) {
    state.roles = payload?.data?.roles
    state.pagination.total = payload?.data?.count || payload?.data?.roles?.length;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getRoles.rejected, state => {
    state.loading = false
  })

  /*** Create Role ***/
  .addCase(createRole.pending, (state) => {
   state.loading = true;
  })
  .addCase(createRole.fulfilled, (state) => {
   state.loading = false;
  })
  .addCase(createRole.rejected, state => {
    state.loading = false
  })

  
  /*** Delete Role ***/
  .addCase(deleteRole.pending, (state) => {
    state.modal.loading = true;
   })
   .addCase(deleteRole.fulfilled, (state) => {
    state.modal.loading = false;
    state.modal.delete = false;
   })
   .addCase(deleteRole.rejected, state => {
     state.modal.loading = false
   })

  /*** PERMISSION ***/ 
  .addCase(getPermissions.pending, (state) => {
   state.loading = true;
  })
  .addCase(getPermissions.fulfilled, (state, {payload}) => {
   state.loading = false;
   if(payload?.success) {
    state.permissions = payload?.data
   }
  })
  .addCase(getPermissions.rejected, state => {
    state.loading = false
  })
 })
})

export const { setPagination, closeModal, openModal } = roleSlice.actions
export const getRoleData = state => state.role
export default roleSlice.reducer