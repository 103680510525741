import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../utils/config";
import Storage from "../../utils/storage";
import BACKEND from "../../utils/backend";

export const createCompany = createAsyncThunk(
  "/auth/createCompany",
  async (payload, thunkAPI) => {
    try {
      const {
        payload: { logo },
      } = thunkAPI.getState().auth;

      return new BACKEND().send({
        type: "post",
        to: "/auth/company/signup",
        useAlert: true,
        payload: { ...payload, companyLogo: logo },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const createHospital = createAsyncThunk(
  "/auth/createHospital",
  async ({ file, logo, ...body }, thunkAPI) => {
    const { payload } = thunkAPI.getState().auth;

    try {
      body.hospitalLogo = payload.logo;
      body.taxId = 73732;

      return new BACKEND().send({
        type: "post",
        to: "/hospitals/create",
        useAlert: true,
        payload: body,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const updateHmo = createAsyncThunk(
  "/auth/hmoUpdate",
  async ({ hmoId, file, logo, ...body }, thunkAPI) => {
    const { images, payload } = thunkAPI.getState().auth;

    try {
      if (images && images.length) {
        body.hospitalImages = images?.map(({ imageUrl }) => imageUrl);
      }
      body.hospitalLogo = payload.logo;
      return new BACKEND().send({
        type: "patch",
        to: `/hmos/update/${hmoId}`,
        useAlert: true,
        payload: body,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const createHmo = createAsyncThunk(
  "/auth/createHmo",
  async (payload, thunkAPI) => {
    try {
      const {
        payload: { logo },
      } = thunkAPI.getState().auth;
      return new BACKEND().send({
        type: "post",
        to: "/hmos/create",
        useAlert: true,
        payload: { ...payload, hmoLogo: logo },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const asyncLogin = createAsyncThunk(
  "/auth/login",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setEmail(payload.email));
      return new BACKEND().send({
        type: "post",
        to: "/auth/login/?deviceType=web",
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "/auth/forgotPassword",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setEmail(payload.email));
      return new BACKEND().send({
        type: "post",
        to: "/auth/forgot-password",
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const changePassword = createAsyncThunk(
  "/auth/change-password",
  async ({ newPassword }, thunkAPI) => {
    try {
      const {
        otp,
        payload: { email },
      } = thunkAPI.getState().auth;

      return new BACKEND().send({
        type: "post",
        to: "/auth/change-password",
        useAlert: true,
        payload: {
          token: otp || "123",
          email,
          newPassword,
        },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const resendOtp = createAsyncThunk(
  "/auth/resend-otp-code",
  async (_, thunkAPI) => {
    try {
      const {
        payload: { email },
      } = thunkAPI.getState().auth;
      return new BACKEND().send({
        type: "post",
        to: "/auth/resend-otp-code",
        useAlert: true,
        payload: {
          email,
        },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "/auth/verifyOtp",
  async (otp, thunkAPI) => {
    try {
      thunkAPI.dispatch(setOtp(otp));
      const {
        payload: { email },
      } = thunkAPI.getState().auth;

      return new BACKEND().send({
        type: "post",
        to: "/auth/reset-password",
        useAlert: true,
        payload: {
          token: otp,
          email,
        },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const uploadFile = createAsyncThunk(
  "auth/uploadFile",
  async ({ formData: payload, config }, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: "/upload-files",
        useAlert: true,
        header: config,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const getStates = createAsyncThunk(
  "/auth/states",
  async (_, thunkApi) => {
    return new BACKEND().send({
      type: "get",
      to: "/states",
      useAlert: false,
    });
  }
);

export const getCities = createAsyncThunk(
  "/auth/cities",
  async (stateTag, thunkApi) => {
    return new BACKEND().send({
      type: "get",
      to: `/cities/${stateTag}`,
      useAlert: false,
    });
  }
);

const initialState = {
  payload: {
    name: "",
    email: "",
    phoneNumber: "",
    state: "",
    city: "",
    streetName: "",
    adminFirstName: "",
    adminLastName: "",
    adminEmail: "",
    adminPhoneNumber: "",
    logo: "",
    websiteLink: "",
    rcNumber: "",
  },
  hospital: {
    hospitalType: "",
    hospitalGrade: "",
    parentId: "",
    branch: "",
    hospitalServices: "",
    hospitalImages: [],
  },
  changePassword: {
    password: "",
    newPassword: "",
  },
  otp: "",
  user: Storage.get(config.authProps[1])
    ? JSON.parse(Storage.get(config.authProps[1]))
    : {},
  loading: false,
  modal: {
    open: false,
    first: false,
    second: false,
    photoLoading: false,
  },
  currentScreen: "",
  states: [],
  cities: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setPayload: (state, { payload }) => {
      state.payload = { ...state.payload, ...payload };
    },
    logout: ({ user }) => {
      Storage.remove(config.authProps[0]);
      Storage.remove(config.authProps[1]);
    },
    setModal: (state, { payload }) => {
      state.modal = {
        ...state.modal,
        ...payload,
      };
    },
    setCurrentScreen: (state, { payload }) => {
      state.currentScreen = payload;
    },
    setOtp: (state, { payload }) => {
      state.otp = payload;
    },
    setEmail: (state, { payload }) => {
      state.payload.email = payload;
    },
    setLogo: (state, { payload }) => {
      state.payload.logo = payload;
    },
    setImages: (state, { payload }) => {
      state.images = payload;
    },
  },
  extraReducers: (builder) => {
    /** Company Builder **/
    builder
      .addCase(createCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCompany.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success === true) state.currentScreen = "login";
      })
      .addCase(createCompany.rejected, (state) => {
        state.loading = false;
      });
    /** Company Builder |END| **/

    /** HMO Builder **/
    builder
      .addCase(createHmo.pending, (state) => {
        state.loading = true;
      })
      .addCase(createHmo.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success === true) state.currentScreen = "login";
      })
      .addCase(createHmo.rejected, (state) => {
        state.loading = false;
      });
    /** HMO Builder |END| **/

    /** Hospital Builder **/
    builder
      .addCase(createHospital.pending, (state) => {
        state.loading = true;
      })
      .addCase(createHospital.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success === true) state.currentScreen = "login";
      })
      .addCase(createHospital.rejected, (state) => {
        state.loading = false;
      });
    /** Hospital Builder |END| **/

    /** Login Builder **/
    builder
      .addCase(asyncLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        asyncLogin.fulfilled,
        (state, { payload: { data, success } }) => {
          state.loading = false;
          if (success) {
            if (data.user.newUser) state.currentScreen = "change-password";
            else {
              state.user = data?.user;
              Storage.set(config.authProps[0], data?.token);
              Storage.set(config.authProps[1], data?.user);
            }
          }
        }
      )
      .addCase(asyncLogin.rejected, (state) => {
        state.loading = false;
      });
    /** Login Builder |END| **/

    /** Forgot Password Builder **/
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success === true) state.modal.open = true;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.loading = false;
      });
    /** Forgot Password Builder |END| **/

    /** Resend OTP Builder **/
    builder
      .addCase(resendOtp.pending, (state) => {
        state.modal.first = true;
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.modal.first = false;
      })
      .addCase(resendOtp.rejected, (state) => {
        state.modal.first = false;
      });
    /** Resend OTP Builder |END| **/

    /** Verify OTP Builder **/
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.modal.second = true;
      })
      .addCase(verifyOtp.fulfilled, (state, { payload }) => {
        state.modal.second = false;
        if (payload?.success) {
          state.modal.open = false;
          state.currentScreen = "reset-password";
        }
      })
      .addCase(verifyOtp.rejected, (state) => {
        state.modal.second = false;
      });
    /** Verify OTP Builder |END| **/

    /** Change Password Builder **/
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) state.currentScreen = "login";
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading = false;
      });
    /** Change Password Builder |END| **/

    builder
      .addCase(getStates.pending, (state) => {
        state.modal.loading = true;
      })
      .addCase(getStates.fulfilled, (state, { payload }) => {
        state.modal.loading = false;
        if (payload?.success) state.states = payload.data.states;
      })
      .addCase(getStates.rejected, (state) => {
        state.modal.loading = false;
      });

    builder
      .addCase(getCities.pending, (state) => {
        state.modal.loading = true;
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        state.modal.loading = false;
        if (payload?.success) state.cities = payload.data.cities;
      })
      .addCase(getCities.rejected, (state) => {
        state.modal.loading = false;
      });
    builder
      .addCase(uploadFile.pending, (state) => {
        state.modal.photoLoading = true;
      })
      .addCase(uploadFile.fulfilled, (state, { payload }) => {
        state.modal.photoLoading = false;
        if (payload?.success) state.cities = payload.data.cities;
      })
      .addCase(uploadFile.rejected, (state) => {
        state.modal.photoLoading = false;
      });
  },
});

export const getAuthData = (state) => state.auth;
export const {
  logout,
  setModal,
  setOtp,
  setEmail,
  setLogo,
  setImages,
  setCurrentScreen,
  setPayload,
} = authSlice.actions;
export default authSlice.reducer;
