import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';


const RadioBoxInput = ({
  formik,
  name,
  className,
  options,
  defaultValue,
  ...props
}) => {
  return (
    <FormControl component="fieldset" className={`mb-3 ${className}`}>
      <FormLabel component="legend" className='text-[var(--c-primary-0)]'>
        {props.required ? props.label + " *" : props.label}
        {props.optional && (
          <small className="text-muted">{`(optional)`}</small>
        )}
      </FormLabel>
      <RadioGroup className='flex flex-row gap-5' aria-label={props.label} name={name}>
        {options.map((option, idx) => (
          <FormControlLabel
            key={idx}
            value={option.value || defaultValue}
            control={<Radio className='text-[var(--c-primary-0)]'/>}
            label={option.label}
            onChange={(e) => props.onChange(e)}
            checked={props.value === option.value}
            tabIndex={props.tabIndex}
          />
        ))}
      </RadioGroup>
      {formik?.errors?.[name] && (
        <FormHelperText className="text-danger text-[red]">{formik?.errors?.[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default RadioBoxInput;
