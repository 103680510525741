import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import HomeLayout from "../../../layouts/Home";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyData,
  getCompanyHmoHistory,
  setPagination,
} from "../../../features/company/companySlice";
import { capitalize, getAmount, getIds } from "../../../utils/helper";
import { IError } from "../../../utils/icons.utils";
import TablePreloader from "../../../components/Loader/TablePreloader";

const HmoHistory = () => {
  const { loading, pagination, hmoTransactionHistory } =
      useSelector(getCompanyData),
    dispatch = useDispatch();
  useEffect(() => {
    (async () => await dispatch(getCompanyHmoHistory(getIds().company)))();
  }, [pagination.page]);

  const data = hmoTransactionHistory?.map((data) => ({
    "Reference No.": data.paymentReference,
    "Plan Name": capitalize(data?.Plan?.name),
    "HMO Name": capitalize(data?.Plan?.Hmo?.name),
    "Amount Per Plan": "₦" + getAmount(data?.Plan?.amount),
    "Plan Duration": data?.Plan?.duration,
    _data: data,
  }));

  console.log(data);

  return (
    <HomeLayout
      {...{
        title: "Transaction Management",
        subtitle: "Overview  of all information on the system.",
      }}
    >
      <Box className="ml-3">
        <Typography className="text-[400] text-xl mt-8 mb-5  text-[#040316]">
          H. M. O Transaction History
        </Typography>
      </Box>

      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        {/* {loading ? (
          <TablePreloader />
        ) : data?.length ? ( */}
        <Table
          {...{
            data,
            pagination,
            setPagination: (data) => dispatch(setPagination(data())),
            isLoading: loading,
          }}
        />
        {/* ) : (
          <Box
            className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
            sx={{ p: 0 }}
          >
            <img
              className="m-auto w-[350px] mt-8"
              src={IError}
              alt={"error"}
              loading="lazy"
            />
            <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
              No Transaction in the system at the moment
            </Typography>
          </Box>
        )} */}
      </Box>
    </HomeLayout>
  );
};

export default HmoHistory;
