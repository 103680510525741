import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getCompany = createAsyncThunk(
  "/company/getCompany",
  (search, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().company;
      return new BACKEND().send({
        type: "get",
        to: `/companies/?pageNumber=${pagination.page}&pageSize=${
          pagination.pageSize
        }&search=${search || pagination.search}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const getPlansByCompanyId = createAsyncThunk(
  "/company/getPlansByCompanyId",
  (companyId, thunkApi) => {
    try {
      const { pagination } = thunkApi.getState().company;
      return new BACKEND().send({
        type: "get",
        to: `/companies/get-plans/${companyId}/?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const getCompanyById = createAsyncThunk(
  "/company/getCompanyById",
  (companyId, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/companies/company/${companyId}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "/company/updateCompany",
  async (payload, thunkAPI) => {
    try {
      const { companyId, ...rest } = payload;
      const {
        payload: { logo },
      } = thunkAPI.getState().auth;
      if (logo) rest.companyLogo = logo;
      return new BACKEND().send({
        type: "patch",
        to: "/companies/update/" + companyId,
        useAlert: true,
        payload: { ...rest },
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const addPlan = createAsyncThunk(
  "/company/addPlan",
  (payload, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "post",
        to: `/companies/add-company-plan`,
        payload,
        useAlert: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const removeCompanyPlan = createAsyncThunk(
  "/company/removeCompanyPlan",
  async (payload, thunkAPI) => {
    try {
      return new BACKEND().send({
        type: "patch",
        to: `/companies/remove-company-plan`,
        useAlert: true,
        payload,
      });
    } catch (error) {
      thunkAPI.rejectWithValue("An error occurred somewhere");
    }
  }
);

export const getDashboard = createAsyncThunk(
  "/company/getDashboard",
  (companyId, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/companies/company-admin/dashboard/${companyId}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const getCompanyHmoHistory = createAsyncThunk(
  "/company/getCompanyHmoHistory",
  (companyId, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/payments/payments/${companyId}`,

        // to: `/companies/transaction/history/${companyId}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const getHmoCompanyTransactionHistory = createAsyncThunk(
  "getHmoCompanyTransactionHistory",
  (hmoId, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/hmos/hmo-company-transactions/${hmoId}?isPaid=false`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const getCompanyOutstandingPayment = createAsyncThunk(
  "/company/getCompanyOutstandingPayment",
  (companyId, thunkApi) => {
    try {
      return new BACKEND().send({
        type: "get",
        to: `/companies/transaction/history/${companyId}`,
        useAlert: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState = {
  loading: false,
  companies: [],
  dashboard: {},
  hmoTransactionHistory: [],
  hmoOutstandingPayment: [],
  hmoCompanyTransactionHistory: [],
  plans: [],
  plan: {},
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    search: "",
  },
  modal: {
    delete: false,
    add: false,
    loading: false,
  },
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    getPlanById: (state, { payload }) => {
      state.plan = state.plans.find((plan) => plan.id === payload);
    },
    closeModal: (state, { payload }) => {
      state.modal[payload] = false;
    },
    openModal: (state, { payload }) => {
      state.modal[payload] = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompany.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success) {
          state.companies = payload?.data?.companies;
          state.pagination.total = payload?.data?.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getCompany.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getPlansByCompanyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlansByCompanyId.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.success === true) {
          state.plans = payload?.data?.companyplans;
          state.pagination.total = payload?.data?.count;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
        }
      })
      .addCase(getPlansByCompanyId.rejected, (state) => {
        state.loading = false;
      });
    // getHmoCompanyTransactionHistory
    builder
      .addCase(getHmoCompanyTransactionHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getHmoCompanyTransactionHistory.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          if (payload?.success === true) {
            state.hmoCompanyTransactionHistory = payload?.data?.transactions;
            state.pagination.total = payload?.data?.count;
            state.pagination.length =
              state.pagination.pageSize * state.pagination.page;
          }
        }
      )
      .addCase(getHmoCompanyTransactionHistory.rejected, (state) => {
        state.loading = false;
      })
      /*** getHmoCompanyTransactionHistory ***/

      /*** ADD PLAN ***/
      .addCase(addPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPlan.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(addPlan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompany.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateCompany.rejected, (state) => {
        state.loading = false;
      })

      .addCase(removeCompanyPlan.pending, (state) => {
        state.modal.loading = true;
      })
      .addCase(removeCompanyPlan.fulfilled, (state, { payload }) => {
        state.modal.loading = false;
        if (payload.success) state.modal.delete = false;
      })
      .addCase(removeCompanyPlan.rejected, (state) => {
        state.modal.loading = false;
      })

      .addCase(getDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboard.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) state.dashboard = payload.data;
      })
      .addCase(getDashboard.rejected, (state) => {
        alert(false);
        state.loading = false;
      })
      .addCase(getCompanyHmoHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyHmoHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success)
          state.hmoTransactionHistory = payload.data.payments;
        state.pagination.total = payload?.data?.count;
        state.pagination.length =
          state.pagination.pageSize * state.pagination.page;
      })
      .addCase(getCompanyHmoHistory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCompanyOutstandingPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyOutstandingPayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success)
          state.hmoOutstandingPayment = payload.data.transactions;
        state.pagination.total = payload?.data?.count;
        state.pagination.length =
          state.pagination.pageSize * state.pagination.page;
      })
      .addCase(getCompanyOutstandingPayment.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setPagination, getPlanById, closeModal, openModal } =
  companySlice.actions;
export const getCompanyData = (state) => state.company;
export default companySlice.reducer;
