import * as React from "react";
import { Box, Radio, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AiOutlineRight } from "react-icons/ai";

const panels = [
  {
    id: "panel1",
    summary: "What is a Payment Gateway?",
    details:
      "No, you do not need to pay Instapay where there is no transaction happening. With one of the lowest transaction charges in the industry, pay only when you get paid!",
  },
  {
    id: "panel2",
    summary: "How does CareQuest work?",
    details:
      "No, you do not need to pay Instapay where there is no transaction happening. With one of the lowest transaction charges in the industry, pay only when you get paid!",
  },
  {
    id: "panel3",
    summary: "What platforms does ACME payment gateway support?",
    details:
      "No, you do not need to pay Instapay where there is no transaction happening. With one of the lowest transaction charges in the industry, pay only when you get paid!",
  },
  {
    id: "panel4",
    summary: "Does ACME provide international payments support?",
    details:
      "No, you do not need to pay Instapay where there is no transaction happening. With one of the lowest transaction charges in the industry, pay only when you get paid!",
  },
  {
    id: "panel5",
    summary: "what is the maintainance fee that I need to pay regularly?",
    details:
      "No, you do not need to pay Instapay where there is no transaction happening. With one of the lowest transaction charges in the industry, pay only when you get paid!",
  },
];

export default function AccordionComp() {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setSelectedValue(panel);
    } else {
      setSelectedValue("");
    }
  };

  return (
    <div className="relative md:w-[80%] w-full m-auto mb-20">
      {panels.map((panel) => (
        <Box key={panel.id} className="md:flex">
          <Box
            onClick={() => setExpanded(panel.id)}
            className="md:w-[54%] w-[100%] z-20"
          >
            <label htmlFor={panel.id}>
              <Box
                expandicon={<ExpandMoreIcon />}
                aria-controls={`${panel.id}bh-content`}
                id={`${panel.id}bh-header`}
                className="flex justify-between shadow-sm border-1 border py-4 "
                sx={{
                  backgroundColor:
                    expanded === panel.id ? "#eceef7" : "#FFFFFF",
                  transition: "background-color 0.2s ease",
                }}
              >
                <Radio
                  checked={selectedValue === panel.id}
                  onChange={handleChange(panel.id)}
                  value={panel.id}
                  name="radio-buttons"
                  inputProps={{ "aria-label": panel.summary }}
                  id={panel.id}
                  className="text-[#191489] md:ml-5 "
                />
                <Typography className="mt-2 flex-wrap text-[13px]">
                  {panel.summary}
                </Typography>
                <AiOutlineRight
                  color={expanded === panel.id ? "#191489" : "#F1F0FD"}
                  className="w-[35px] h-[35px] mt-1 md:mr-5 mr-2"
                />
              </Box>
            </label>
            {expanded === panel.id && (
              <Box className="md:hidden md:flex-1 md:w-[648px] md:h-[120%] md:absolute md:right-[8%] md:bottom-[-10%] pb-3  bg-[#ECEEF7] z-0  border ">
                <Typography className="pt-5 pl-3 pr-3 text-[15px] font-bold">
                  {panel.summary}
                </Typography>
                <Typography className="pt-5 pl-3 pr-3 text-[12px] text-[300]">
                  {panel.details}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ))}
      {panels.map(
        (panel) =>
          expanded === panel.id && (
            <Box
              key={panel.id}
              className="hidden md:block md:flex-1 md:w-[348px] lg:w-[448px] xl:w-[648px] md:h-[120%] md:absolute md:left-[45%] xl:left-[49%] md:bottom-[-10%]  bg-[#ECEEF7] z-0 rounded-[16px] border "
            >
              <Typography className="pt-24 pl-24 pr-5 text-[18px] font-bold">
                {panel.summary}
              </Typography>
              <Typography className="pt-10 pl-24 pr-5 text-[16px] text-[300]">
                {panel.details}
              </Typography>
            </Box>
          )
      )}
    </div>
  );
}
