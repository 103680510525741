import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TablePreloader from "../../../components/Loader/TablePreloader";
import SearchBar from "../../../components/SearchBar";
import Table from "../../../components/table/Table";
import {
  getPatients,
  getPatientsData,
  setPagination,
} from "../../../features/hospitals/patientSlice";
import HomeLayout from "../../../layouts/Home";
import config from "../../../utils/config";
import Menu from "../../../utils/data.dropdown";
import { loggedInUser } from "../../../utils/helper";

const Patients = () => {
  let user = {};
  if (loggedInUser) {
    user = JSON.parse(loggedInUser);
  }
  const hospitalId = user?.hospitaluser?.[0]?.id,
    navigate = useNavigate(),
    dispatch = useDispatch(),
    { pagination, loading, patients } = useSelector(getPatientsData),
    { routes } = config;

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) => (async () => await dispatch(getPatients(hospitalId)))(),
        700
      );
    } else cb = dispatch(getPatients(hospitalId));

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page]);

  const data = patients?.map((patient) => ({
    Name: patient?.User?.firstName + " " + patient?.User?.lastName,
    "H.M.O ID": patient?.Plan?.hmoId,
    "Phone number": patient?.User?.phoneNumber,
    "H.M.O": patient?.Plan?.Hmo?.name,
    Plan: patient?.Plan?.name,
    _data: patient,
  }));

  const menu = new Menu({});
  const dropdownMenu = [
    menu.createConsultation({
      navigate,
      routes,
    }),
    menu.viewConsultationHistory({
      navigate,
      routes,
    }),
  ];

  return (
    <HomeLayout
      {...{
        title: "Patients",
        subtitle: "Overview  of all information on the system.",
      }}
    >
      <Box className="">
        <SearchBar
          {...{
            onChange: ({ target: { value } }) =>
              dispatch(setPagination({ search: value })),
            placeholder: "Search For Patients",
            wraperClass: "mt-6 ml-3",
          }}
        />
      </Box>
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Table
          {...{
            dropdownMenu,
            data,
            pagination,
            setPagination: (data) => dispatch(setPagination(data())),
            isLoading: loading,
          }}
        />
      </Box>
    </HomeLayout>
  );
};

export default Patients;
