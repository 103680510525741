/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import HomeLayout from "../../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Table from "../../../../components/table/Table";
import config from "../../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBankAccounts,
  getHmoData,
  setPagination,
} from "../../../../features/hmo/hmoSlice";

const { routes } = config;
const HmoAccounts = () => {
  const navigate = useNavigate();
  const { pagination, loading } = useSelector(getHmoData);

  const data = [],
    dispatch = useDispatch();

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) => (async () => await dispatch(fetchBankAccounts()))(),
        700
      );
    } else cb = (async () => await dispatch(fetchBankAccounts()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page]);

  return (
    <HomeLayout {...{ title: "Configuration" }}>
      <Box className="flex text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-end">
        <LoadingButton
          LinkComponent={Link}
          className="w-[213px] h-[56px] bg-[var(--c-primary-0)] text-[var(--c-bg-color)] font-semibold text-[18px] rounded-xl shadow-none normal-case"
          size="small"
          type="submit"
          variant="contained"
          to={routes.hmos.configurations.accounts.new}
          fullWidth
        >
          Create Account
        </LoadingButton>
      </Box>
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Table
          {...{
            data,
            isLoading: loading,
            pagination,
            setPagination,
          }}
        />
      </Box>
    </HomeLayout>
  );
};

export default HmoAccounts;
