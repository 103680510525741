import React from "react";
import HomePage from "../../components/Auth/Home";
import Login from "../../components/Auth/Login";
import AuthLayout from "../../layouts/Auth";
import { useAuthContext } from "../../context/AuthContext";
import ForgotPassword from "../../components/Auth/ForgotPassword";
import ChangePassword from "../../components/Auth/ChangePassword";
import ResetPassword from "../../components/Auth/ResetPassword";
import LandingPage from "../../components/Auth/LandingPage";
import { getAuthData, setCurrentScreen } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function Auth() {
	const { setClassName } = useAuthContext(),
	dispatch = useDispatch(),
	{ state } = useLocation(),
	{ currentScreen } = useSelector(getAuthData);

	React.useEffect(() => {
		state && dispatch(setCurrentScreen(state))
	}, [])

	React.useEffect(()=> {
		if(currentScreen === 'home-page') setClassName( _=> 'md:w-[80%] lg:w-[85%] xl:w-[65%]')
	else setClassName(_=> 'md:w-[75%] lg:w-[65%] xl:w-[50%]');
	}, [currentScreen, setClassName])
	
	const getScreen = React.useCallback(() => {
		let Screen;
		switch (currentScreen) {
			case 'home-page': Screen = HomePage;
			break;
			case 'login': Screen = Login;
			break;
			case 'landing-page': Screen = LandingPage;
			break;
			case 'forgotPassword': Screen = ForgotPassword;
			break;
			case 'change-password': Screen = ChangePassword;
			break;
			case 'reset-password': Screen = ResetPassword;
			break;
			default: Screen = Login;
		}

		return <AuthLayout> <Screen {...{ currentScreen }}/> </AuthLayout>
	}, [currentScreen])

	return getScreen();
}
