import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Avatar, Typography } from "@mui/material";
import { IBulb } from "../../utils/icons.utils";
import OtpInput from "../OtpInput";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { setModal } from "../../features/auth/authSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({
	open,
	to = "email address",
	onSubmit,
	disabled,
 handleResend,
 resend
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
const dispatch = useDispatch()
	const handleClose = () => {
		dispatch(setModal({open: false}))
	};

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				className="p-10"
				sx={{zIndex: 20}}
				TransitionComponent={Transition}>
				<DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button color="error" onClick={handleClose} autoFocus>
						<Close />
					</Button>
				</DialogActions>
				<DialogTitle
					id="responsive-dialog-title"
					display="flex"
					justifyContent="center"
					mt="0em">
					<Avatar src={IBulb} />
				</DialogTitle>
				<DialogContent>
					<Typography textAlign="center" fontSize="24px">
						Validate Token
					</Typography>
					<DialogContentText fontSize="20px" padding=".5em 1em">
						A token was sent to your {to}, please check and enter below.
					</DialogContentText>
					<Box>
						<OtpInput {...{ onSubmit, disabled, length: 4 }} />
						<Typography textAlign="center" marginTop="1em">
							Didn't get the token?{" "}
							<LoadingButton loading={resend} onClick={handleResend} sx={{".MuiLoadingButton-loadingIndicatorCenter": {color: 'var(--c-primary-0) !important'}, color: 'var(--c-primary-0)'}}>
								{" "}
								<span>Resend</span>
							</LoadingButton>
						</Typography>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}
