/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, loggedInUser } from "../../../utils/helper";
import {
  getHmoData,
  getIndividualClients,
  setPagination,
} from "../../../features/hmo/hmoSlice";
import TablePreloader from "../../../components/Loader/TablePreloader";
import { IError } from "../../../utils/icons.utils";

const IndivualClients = () => {
  let user = {};
  if (loggedInUser) {
    user = JSON.parse(loggedInUser);
  }
  const hmoId = user?.hmos[0]?.id,
    dispatch = useDispatch(),
    [isLoading, setIsLoading] = useState(true);

  const { pagination, individualClients } = useSelector(getHmoData);

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) => (async () => await dispatch(getIndividualClients(hmoId)))(),
        700
      );
    } else cb = dispatch(getIndividualClients(hmoId));

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const data = individualClients?.map((individualClient) => ({
    Name: individualClient?.User?.firstName
      ? individualClient?.User?.firstName +
        " " +
        individualClient?.User?.lastName
      : "N/A",
    "Email Address": individualClient?.User?.email || "N/A",
    "Plan Type": individualClient?.Plan?.name || "N/A",
    "Registered Date": formatDate(individualClient?.startDate) || "N/A",
    "Registered Hospital": individualClient?.Hospital?.name || "N/A",
    "Subscription Status": individualClient?.active ? "Active" : "Inactive",
    _data: individualClient,
  }));

  return (
    <Box
      className="pt-8 px-8 mt-8 pb-2 bg-[var(--c-bg-color)] rounded-lg w-full"
      sx={{ p: 0 }}
    >
      <Box className="flex justify-between mb-2">
        <Typography>Individual Clients</Typography>
      </Box>
      {isLoading ? (
        <TablePreloader />
      ) : data?.length ? (
        <Table
          {...{
            data,
            pagination,
            setPagination,
          }}
        />
      ) : (
        <Box
          className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
          sx={{ p: 0 }}
        >
          <img
            className="m-auto w-[350px] mt-8"
            src={IError}
            alt={"error"}
            loading="lazy"
          />
          <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
            No Users in the system at the moment
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default IndivualClients;
