import React from 'react';
import Switch from '@mui/material/Switch';

function withControlledSwitch(Component) {
  return function ControlledSwitches({color, ...props}) {
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    return (
      <Component
        {...props}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        color={color}
      />
    );
  };
}

export default withControlledSwitch(Switch);
