import Brand from "../assets/images/HIMP.svg";
import Brand2 from "../assets/images/Logo.svg";
import Brand3 from "../assets/images/HIMP3.svg";
import ArrowBack from "../assets/images/arrow-back.svg";
import bulb from "../assets/images/bulb.svg";
import Ailment from "../assets/images/ailment.svg";
import Appointment from "../assets/images/appointment.svg";
import CaretRight from "../assets/images/caret-right.svg";
import Client from "../assets/images/client.svg";
import Configuration from "../assets/images/configuration.svg";
import Dashboard from "../assets/images/dashboard.svg";
import Hmo from "../assets/images/hmo.svg";
import Hospital from "../assets/images/hospital.svg";
import Logout from "../assets/images/logout.svg";
import Transaction from "../assets/images/transaction.svg";
import ClientTwo from "../assets/images/clientTwo.svg";
import HmoTwo from "../assets/images/hmoTwo.svg";
import Boxes from "../assets/images/direct.svg";
import HomeTwo from "../assets/images/Ihospital.svg";
import ArrowFoward from "../assets/images/arrow-foward.svg";
import SearchIcon from "../assets/images/search.svg";
import CloudDownload from "../assets/images/cloudDownload.svg";
import CloudUpload from "../assets/images/cloudUpload.svg";
import companyI from "../assets/images/companyI.svg";
import HmoI from "../assets/images/hmoI.svg";
import HospitalI from "../assets/images/hospitalI.svg";
import LandinImage from "../assets/images/landinImage.svg";
import Error from "../assets/images/error.svg";
import Employee from "../assets/images/client.svg";
import Plans from "../assets/images/plans.svg";
import Billing from "../assets/images/billing.svg";
import PlusRound from "../assets/images/plusRound.svg";
import ArrowUp from "../assets/images/arrowUp.svg";
import Twitter from "../assets/images/twitter.svg";
import Web from "../assets/images/web.svg";
import Youtube from "../assets/images/youtube.svg";
import Instagram from "../assets/images/instagram.svg";
import Airbnb from "../assets/images/airbnb.svg";
import FedEx from "../assets/images/fedEx.svg";
import Google from "../assets/images/google.svg";
import Hubspot from "../assets/images/hubspot.svg";
import Microsoft from "../assets/images/microsoft.svg";
import Walmart from "../assets/images/walmart.svg";
import Scalable from "../assets/images/scalable.svg";
import Blank from "../assets/images/blank.svg";
import Location from "../assets/images/location.svg";
import DownloadMobile from "../assets/images/downloadMobile.svg";
import AppleStore from "../assets/images/AppleStore.svg";
import GooglePlay from "../assets/images/GooglePlay.svg";
import Quote from "../assets/images/quote.svg";
import Testimonials from "../assets/images/testimonials.svg";
import CompanyIcon from "../assets/images/companyIcon.svg";
import SetTwo from "../assets/images/SetTwo.svg";
import DatePicker from "../assets/images/datePicker.svg";

export const IBrand = Brand;
export const IBrandBlue = Brand2;
export const IBrand2 = Brand3;
export const IBulb = bulb;
export const IArrowBack = ArrowBack;
export const IAilment = Ailment;
export const IAppointment = Appointment;
export const ICaretRight = CaretRight;
export const IClient = Client;
export const IConfiguration = Configuration;
export const IDashboard = Dashboard;
export const IHmo = Hmo;
export const IHospital = Hospital;
export const ILogout = Logout;
export const ITransaction = Transaction;
export const IHmoTwo = HmoTwo;
export const IClientTwo = ClientTwo;
export const IBoxes = Boxes;
export const IHomeTwo = HomeTwo;
export const IArrowFoward = ArrowFoward;
export const ISearchIcon = SearchIcon;
export const ICloudDownload = CloudDownload;
export const ICloudUpload = CloudUpload;
export const IcompanyI = companyI;
export const IHmoI = HmoI;
export const IHospitalI = HospitalI;
export const ILandinImage = LandinImage;
export const IError = Error;
export const IEmployee = Employee;
export const IPlans = Plans;
export const IBilling = Billing;
export const IPlusRound = PlusRound;
export const IArrowUp = ArrowUp;
export const ITwitter = Twitter;
export const IWeb = Web;
export const Iyoutube = Youtube;
export const IInstagram = Instagram;
export const IAirbnb = Airbnb;
export const IFedEx = FedEx;
export const IGoogle = Google;
export const IHubspot = Hubspot;
export const IMicrosoft = Microsoft;
export const IWalmart = Walmart;
export const IScalable = Scalable;
export const IBlank = Blank;
export const ILocation = Location;
export const IDownloadMobile = DownloadMobile;
export const IAppleStore = AppleStore;
export const IGooglePlay = GooglePlay;
export const IQuote = Quote;
export const ITestimonials = Testimonials;
export const ICompanyIcon = CompanyIcon;
export const ISetTwo = SetTwo;
export const IDatePicker = DatePicker;
