import {
  Box,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
} from "@mui/material";
import React from "react";
import CardComp from "../../components/CardComp";
import HomeLayout from "../../layouts/Home";
import {
  IHomeTwo,
  IBoxes,
  IHmoTwo,
  IClientTwo,
  IArrowFoward,
} from "../../utils/icons.utils";
// import { LoadingButton } from "@mui/lab";

// import config from "../../utils/config";
// import AddIcon from "@mui/icons-material/Add";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//   getCompanyData,
//   getDashboard,
// } from "../../features/company/companySlice";
import { useEffect } from "react";
import { getAmount, getIds } from "../../utils/helper";
import {
  getDashboard,
  getHospitalData,
} from "../../features/hospitals/hospitalSlice";

export default function HospitalDash() {
  // const { routes } = config;
  const { dashboard, loading } = useSelector(getHospitalData),
    dispatch = useDispatch();

  // const mapData = [
  //   {
  //     icon: IHomeTwo,
  //     title: loading ? "..." : dashboard?.numberOfEmployees,
  //     subtitle: "Total Number Of Employees ",
  //   },
  //   {
  //     icon: IClientTwo,
  //     title: loading ? "..." : dashboard?.numberOfEmployeesWithActivePlans,
  //     subtitle: "Total Number Of  Employee With Active Plans",
  //   },
  // ];

  useEffect(() => {
    (async () => await dispatch(getDashboard(getIds().hospital)))();
  }, []);
  console.log(dashboard);

  const mapData = [
    {
      icon: IHomeTwo,
      title: loading
        ? "..."
        : getAmount(dashboard?.registeredStaffs?.count) || 0,
      subtitle: loading ? "..." : dashboard?.registeredStaffs?.label,
    },
    {
      icon: IBoxes,
      title: loading ? "..." : getAmount(dashboard?.generatedROI?.count) || 0,
      subtitle: loading ? "..." : dashboard?.generatedROI?.label,
    },
    {
      icon: IHmoTwo,
      title: loading ? "..." : getAmount(dashboard?.registeredHmos?.count) || 0,
      subtitle: loading ? "..." : dashboard?.registeredHmos?.label,
    },
  ];

  const mapDataTwo = [
    {
      icon: IHomeTwo,
      title: loading
        ? "..."
        : getAmount(dashboard?.companyPatients?.count) || 0,
      subtitle: loading ? "..." : dashboard?.companyPatients?.label,
    },
    {
      icon: IClientTwo,
      title: loading
        ? "..."
        : getAmount(dashboard?.individualPatients?.count) || 0,
      subtitle: loading ? "..." : dashboard?.individualPatients?.label,
    },
  ];

  const mapDataThree = [
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Basic H. M. O",
    },
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Premimum H. M. O  ",
    },
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Classic H. M. O",
    },
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Executive H. M. O ",
    },
  ];

  return (
    <HomeLayout
      {...{
        title: "Dashboard",
        subtitle: "Overview of all information on the system.",
      }}
    >
      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ my: 3 }}
      >
        Business Overview
      </Typography>
      <Box className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
        {mapData.map((data, index) => {
          return (
            <Box elevation={3} key={index}>
              <CardComp
                className={
                  "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                }
              >
                <CardContent className="flex gap-6 ">
                  <Box
                    className={`  rounded-lg`}
                    sx={{ height: 60, width: 60 }}
                  >
                    <CardMedia
                      component="img"
                      // sx={{ height: 25, width: 25 }}
                      className={`${
                        index === 2 && "xl:p-4 lg:p-3 md:p-2 p-4"
                      } ${index === 2 && "bg-[#007AFF1A] rounded-lg"}`}
                      image={data.icon}
                      alt="Paella dish"
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{ fontSize: 20, fontWeight: 700 }}
                      variant="h1"
                      color="black"
                      gutterBottom
                    >
                      {data.title}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {data.subtitle}
                    </Typography>
                  </Box>
                </CardContent>
              </CardComp>
            </Box>
          );
        })}
      </Box>

      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ my: 3 }}
      >
        Registered Patients
      </Typography>
      <Box className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-4 lg:gap-10 p-4 mb-14">
        {mapDataTwo.map((data, index) => {
          return (
            <Box elevation={3} key={index}>
              <CardComp
                className={
                  "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                }
              >
                <CardContent className="flex gap-6 justify-between">
                  <Box className="flex gap-6">
                    <Box
                      className={`  rounded-lg`}
                      sx={{ height: 60, width: 60 }}
                    >
                      <CardMedia
                        component="img"
                        className={`${
                          index === 2 && "xl:p-4 lg:p-3 md:p-2 p-4 rounded-lg"
                        } ${index === 2 && "bg-[#007AFF1A]"}`}
                        image={data.icon}
                        alt="Paella dish"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: 700 }}
                        variant="h1"
                        color="black"
                        gutterBottom
                      >
                        {data.title}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {data.subtitle}
                      </Typography>
                    </Box>
                  </Box>
                  <CardMedia
                    component="img"
                    className={`w-[18px] h-[15px] mt-12 cursor-pointer`}
                    image={IArrowFoward}
                    alt="Paella dish"
                  />
                </CardContent>
              </CardComp>
            </Box>
          );
        })}
      </Box>

      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ mt: 5, pl: 3 }}
      >
        Total H. M. O Payment
      </Typography>

      {loading ? (
        <Box className="grid lg:grid-cols-3 overflow-x-scroll md:grid-cols-3 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
          {Array(3)
            .fill("")
            .map((a) => (
              <Skeleton className=" h-[180px] gap-6 pt-2 xl:pl-3 border-none shadow-md rounded-xl" />
            ))}
        </Box>
      ) : dashboard?.totalPaymentsPerPlan?.length > 0 ? (
        <Box
          sx={{ mt: 3 }}
          className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full"
        >
          {dashboard?.totalPaymentsPerPlan?.map((data, index) => {
            return (
              <Box elevation={3} key={index}>
                <CardComp
                  className={
                    "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                  }
                >
                  <CardContent className="flex gap-6 ">
                    <Box
                      className={`  rounded-lg`}
                      sx={{ height: 60, width: 60 }}
                    >
                      <CardMedia
                        component="img"
                        className={
                          "xl:p-4 lg:p-3 md:p-2 p-4 bg-[#007AFF1A] rounded-lg"
                        }
                        image={IHmoTwo}
                        alt="Paella dish"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: 700 }}
                        variant="h1"
                        color="black"
                        gutterBottom
                        fontFamily={"sofiaProBold"}
                      >
                        {loading ? "..." : getAmount(data?.count)}
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                        fontFamily={"sofiaPro"}
                      >
                        {data?.plan?.name}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardComp>
              </Box>
            );
          })}
        </Box>
      ) : (
        <p className="ml-6 mt-0 pt-0">No payment made yet</p>
      )}

      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ mt: 5, pl: 3 }}
      >
        Outstanding H. M. O Payments
      </Typography>

      {loading ? (
        <Box className="grid lg:grid-cols-3 overflow-x-scroll md:grid-cols-3 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
          {Array(3)
            .fill("")
            .map((a) => (
              <Skeleton className="h-[180px] gap-6 pt-2 xl:pl-3 border-none shadow-md rounded-xl" />
            ))}
        </Box>
      ) : dashboard?.outstandingTotalPaymentsPerPlan?.length > 0 ? (
        <Box
          sx={{ mt: 3 }}
          className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full"
          fontFamily={"sofiaPro"}
        >
          {dashboard?.outstandingTotalPaymentsPerPlan?.map((data, index) => {
            return (
              <Box elevation={3} key={index}>
                <CardComp
                  className={
                    "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                  }
                >
                  <CardContent className="flex gap-6 ">
                    <Box
                      className={`rounded-lg`}
                      sx={{ height: 60, width: 60 }}
                    >
                      <CardMedia
                        component="img"
                        className={
                          "xl:p-4 lg:p-3 md:p-2 p-4 bg-[#007AFF1A] rounded-lg"
                        }
                        image={IHmoTwo}
                        alt="Paella dish"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: 700 }}
                        variant="h1"
                        color="black"
                        gutterBottom
                        fontFamily={"sofiaProBold"}
                      >
                        {loading ? "..." : getAmount(data?.amount)}
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                        fontFamily={"sofiaPro"}
                      >
                        {data?.hmoName}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardComp>
              </Box>
            );
          })}
        </Box>
      ) : (
        <p className="ml-6 mt-0 pt-0">No Outstanding Payment found</p>
      )}
    </HomeLayout>
  );
}
