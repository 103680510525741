import { Avatar, FormControl, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import HomeLayout from "../../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import SelectInput from "../../../../components/SelectInput";
import { IPlusRound } from "../../../../utils/icons.utils";

const AddService = () => {
  const navigate = useNavigate();
  return (
    <HomeLayout {...{ title: "Configuration" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mx-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Typography className="text-[400] text-xl mt-8 mb-5 ml-3 text-[#040316]">
        Service Offerings
      </Typography>

      <Box
        className="w-[100%] rounded-lg  lg:p-20"
        sx={{
          px: "3em",
          py: "3em",
          bgcolor: "var(--c-bg-color)",
          mt: "3%",
        }}
      >
        <Box className="grid lg:grid-cols-2 gap-4 mb-20 border-b-4 pb-20">
          <FormControl className="w-full border-none lg:mt-5">
            <SelectInput
              {...{
                className: " w-[100%] bg-white ",
                // value: values.duration,
                name: "ailmentName",
                label: "Enter Ailment Name",
                // handleChange,
                // handleBlur,
                // handleFocus,
                // error: errors.duration && touched.duration,
                // helperText: errors.duration,
                options: ["Malaria", "Typhoid", "Diabetes", "Cancer"],
              }}
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="amount"
              label="Enter Amount"
              name="amount"
              // value={values.employeeLastName}
              // required
              // onFocus={handleFocus}
              // onChange={handleChange}
              // onBlur={handleBlur}
              // error={errors.employeeLastName && touched.employeeLastName}
              // helperText={
              // 	errors.employeeLastName && touched.employeeLastName
              // 		? errors.employeeLastName
              // 		: ""
              // }
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <SelectInput
              {...{
                className: " w-[100%] bg-white ",
                // value: values.duration,
                name: "hospital",
                label: "Choose Hospital",
                // handleChange,
                // handleBlur,
                // handleFocus,
                // error: errors.duration && touched.duration,
                // helperText: errors.duration,
                options: [
                  "Green Health",
                  "Teaching Hospital",
                  "Cancer Center",
                  "Bridge Wellness",
                ],
              }}
            />
          </FormControl>
          <Box className="w-full flex border-none lg:mt-5">
          <Avatar
            alt="image"
            src={IPlusRound}
            variant="square"
            className="w-[28px] h-[28px] mt-4 ml-1"
            />
            <Typography className="text-[400] text-[23px] mt-3 ml-3  text-[var(--c-primary-3)]">
            Add Hospital
			</Typography>
          </Box>
        </Box>

        <Box className="grid lg:grid-cols-2 gap-4 mb-20 border-b-4 pb-20">
          <FormControl className="w-full border-none ">
            <SelectInput
              {...{
                className: " w-[100%] bg-white ",
                // value: values.duration,
                name: "hospital",
                label: "Choose Hospital",
                // handleChange,
                // handleBlur,
                // handleFocus,
                // error: errors.duration && touched.duration,
                // helperText: errors.duration,
                options: [
                  "Green Health",
                  "Teaching Hospital",
                  "Cancer Center",
                  "Bridge Wellness",
                ],
              }}
            />
          </FormControl>
          <FormControl className="w-full border-none ">
            <TextField
              className="w-full bg-white"
              id="amount"
              label="Enter Amount"
              name="amount"
              // value={values.employeeLastName}
              // required
              // onFocus={handleFocus}
              // onChange={handleChange}
              // onBlur={handleBlur}
              // error={errors.employeeLastName && touched.employeeLastName}
              // helperText={
              // 	errors.employeeLastName && touched.employeeLastName
              // 		? errors.employeeLastName
              // 		: ""
              // }
            />
          </FormControl>
          <Box className="w-full flex border-none lg:mt-5">
          <Avatar
            alt="image"
            src={IPlusRound}
            variant="square"
            className="w-[28px] h-[28px] mt-4 ml-1"
            />
            <Typography className="text-[400] text-[23px] mt-3 ml-3  text-[var(--c-primary-3)]">
            Add Hospital
			</Typography>
          </Box>
        </Box>

        <Box className="grid lg:grid-cols-2 gap-4 mb-8">
          <FormControl className="w-full border-none ">
            <SelectInput
              {...{
                className: " w-[100%] bg-white ",
                // value: values.duration,
                name: "hospital",
                label: "Choose Hospital",
                // handleChange,
                // handleBlur,
                // handleFocus,
                // error: errors.duration && touched.duration,
                // helperText: errors.duration,
                options: [
                  "Green Health",
                  "Teaching Hospital",
                  "Cancer Center",
                  "Bridge Wellness",
                ],
              }}
            />
          </FormControl>
          <FormControl className="w-full border-none ">
            <TextField
              className="w-full bg-white"
              id="amount"
              label="Enter Amount"
              name="amount"
              // value={values.employeeLastName}
              // required
              // onFocus={handleFocus}
              // onChange={handleChange}
              // onBlur={handleBlur}
              // error={errors.employeeLastName && touched.employeeLastName}
              // helperText={
              // 	errors.employeeLastName && touched.employeeLastName
              // 		? errors.employeeLastName
              // 		: ""
              // }
            />
          </FormControl>
          <Box className="w-full flex border-none lg:mt-5">
          <Avatar
            alt="image"
            src={IPlusRound}
            variant="square"
            className="w-[28px] h-[28px] mt-4 ml-1"
            />
            <Typography className="text-[400] text-[23px] mt-3 ml-3  text-[var(--c-primary-3)]">
            Add Hospital
			</Typography>
          </Box>
        </Box>

        <Box className="flex justify-end gap-4 my-6">
          <LoadingButton
            className="w-[213px] h-[56px] bg-[var(--c-white-2)] text-[var(--c-primary-0)] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            className="w-[213px] h-[56px] bg-[var(--c-primary-0)] text-[var(--cc-grey-3)] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </HomeLayout>
  );
};

export default AddService;
