/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Box from "@mui/material/Box";
import {
	Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeLayout from "../../layouts/Home";
import { useLocation, useNavigate } from "react-router-dom";
import SignupHMO from "../../components/Auth/SignupHMO";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { getUserById } from "../../features/users/userSlice";
import { getIds, getRole } from "../../utils/helper";
import config from "../../utils/config";
import Storage from "../../utils/storage";


const AddHmo = () => {
	const navigate = useNavigate(),
	{ state } = useLocation(),
	dispatch = useDispatch(),
	cb = async res => {
		if(res.payload.success === true) {
			const user = await dispatch(getUserById(getIds().user)).unwrap()
			user.role = [getRole()]
			Storage.set(config.authProps[1], user)
			navigate(-1);
		}
	}
const stateModel = useMemo(() => {
	return {
		hmoId: state?.id,
name: state?.name,
		state: state?.state,
city: state?.city,
		streetName: state?.streetName,
email: state?.email,
phoneNumber: state?.phoneNumber,
		websiteLink: state?.websiteLink,
adminFirstName: state?.user?.firstName,
adminLastName: state?.user?.lastName,
adminEmail: state?.user?.email,
rcNumber: state?.rcNumber,
adminPhoneNumber: state?.user?.phoneNumber,
logo: state?.hmoLogo,
};
}, [])
	return (
		<HomeLayout {...{ title: "H.M.O" }}>
			<Box className="flex  mt-2 text-[var(--c-dark-1)]">
				<ArrowBackIcon
					onClick={(_) => navigate(-1)}
					className=" mr-2 cursor-pointer"
					fontSize="small"
				/>
				<Typography className="text-[400] text-[16px] mb-2">Go back</Typography>
			</Box>
			<Typography className="text-[400] text-xl mt-8 text-[#040316]">
			{ state?.editable ? 'Edit' : 'New' } H. M. O
			</Typography>

			<Box sx={{className: 'w-[100%] lg:w-[80%]', px: '2em', py: '2px', bgcolor: "var(--c-bg-color)"}}>
				<SignupHMO {...{ cb, editable: state?.editable, stateModel}} />
			</Box>
		</HomeLayout>
	);
};

export default AddHmo;
