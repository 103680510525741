import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddBenefitToPlan({
  open,
  handleClose,
  values,
  error,
  touched,
  setValues,
  title,
}) {
  const [benefit, setBenefit] = React.useState({
    value: "",
    idx: null,
    error: "",
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [benefits, setBenefits] = React.useState(values.benefits);

  const handleEditAnswer = ({ value, idx }) => {
    setBenefit({ error: "", value, idx });
  };

  const handleRemoveAnswer = (idx) => {
    const newBenefits = benefits.filter((a, i) => i !== idx);
    setBenefits(newBenefits);
    setValues({ ...values, benefits: newBenefits });
  };

  const addBenefit = () => {
    if (benefit.value !== "") {
      if (benefits.includes(benefit.value)) {
        setBenefit({
          ...benefit,
          error: "This benefit has already been added",
        });
      } else if (benefit.idx !== null) {
        const newOption = [...benefits];

        // updates edited option
        newOption[benefit.idx] = benefit.value;

        setBenefits(newOption);
        setBenefit({ error: "", value: "", idx: null });
        setValues({ ...values, benefits });
      } else {
        setBenefit({ ...benefit, error: "", value: "" });
        setBenefits([...benefits, benefit.value]);
        setValues({ ...values, benefits: [...values.benefits, benefit.value] });
      }
    } else {
      setBenefit({ value: "", idx: null, error: "Benefit is required" });
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className="p-10 w-full"
      sx={{ zIndex: 1200 }}
      TransitionComponent={Transition}
    >
      <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="error" onClick={handleClose} autoFocus>
          <Close />
        </Button>
      </DialogActions>

      <DialogTitle
        id="responsive-dialog-title"
        display="flex"
        justifyContent="center"
        mt="0em"
      >
        <Typography textAlign="center" fontSize="24px">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ width: "500px" }}>
        <div className="w-full">
          <div className="mt-5 flex w-full">
            <FormControl fullWidth className="border-none lg:mt- mt-">
              <TextField
                className="bg-white"
                id="benefit"
                placeholder="Enter benefit"
                name="benefit"
                value={benefit.value}
                required
                onChange={({ target: { value } }) =>
                  setBenefit({ ...benefit, error: "", value })
                }
                error={Boolean(benefit.error)}
                helperText={benefit.error}
              />
            </FormControl>
            <br />
            <br />
            <Button
              variant="contained"
              type="button"
              fullWidth
              onClick={addBenefit}
              style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
              }}
            >
              Add
            </Button>
          </div>
        </div>

        <div className="my-10">
          {(benefits?.length > 0 || error) && (
            <Box
              variant="outlined"
              sx={{
                boxShadow: "sm",
                borderRadius: "sm",
                p: 2,
                bgcolor: "#eee",
                color: "black",
              }}
            >
              <div>
                {benefits?.map((a, i) => {
                  return (
                    <div
                      key={i}
                      className="flex"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="flex" style={{ display: "flex" }}>
                        <p>{i + 1}.</p>
                        <Typography ml>{a}</Typography>
                      </div>
                      <div className="flex mr-5">
                        <EditIcon
                          color="info"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleEditAnswer({ value: a, idx: i })}
                        />
                        <DeleteIcon
                          color="error"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleRemoveAnswer(i)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <br />
              <br />
              <FormHelperText
                sx={{ color: "#C41C1C", mt: 1 }}
                className="text-[#C41C1C] px-[14px]"
              >
                {touched && error}
              </FormHelperText>
            </Box>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
