/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Typography, Box, Avatar } from "@mui/material";
import { IArrowBack } from "../../utils/icons.utils";
import { LoadingButton } from "@mui/lab";
import PasswordInput from "../PasswordInput";
import { useFormik } from "formik";
import { userResetPassword } from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, getAuthData, setCurrentScreen } from "../../features/auth/authSlice";

const ResetPassword = () => {
	const [isMatch, setMatch] = React.useState(false),
 { loading } = useSelector(getAuthData),
	dispatch = useDispatch(),
	[error, setError] = React.useState(''),
		model = {
			newPassword: "",
			confirmPassword: "",
		},
		{
		values,
		handleBlur,
		handleChange,
		handleSubmit,
		errors,
		touched
	} = useFormik({
			validationSchema: userResetPassword,
			initialValues: model,
			onSubmit: (value) => {
				if(isMatch)	dispatch(changePassword(value));
			}
		});

		React.useEffect(() => {
			const match = values.newPassword === values.confirmPassword;
			if(!match && !errors.confirmPassword && !errors.newPassword) {
				setError('Password mismatch')
			}
			 setMatch(match)
		}, [values.newPassword, values.confirmPassword])

	return (
		<Box className="pt-24">
			<Box display="flex" alignItems="center" marginBottom="2em">
				{" "}
				<Avatar
					src={IArrowBack}
					className="w-10 h-10 hover:bg-gray-400 p-2 mr-2 cursor-pointer"
					onClick={(_) => dispatch(setCurrentScreen("forgotPassword"))}
				/>{" "}
				<Typography fontSize="20px">Go back</Typography>{" "}
			</Box>
			<Typography component={"h4"} variant={"h4"} className="font-bold">
				Reset Password
			</Typography>
			<Typography className="text-lg" fontSize="24px">
				Enter your new password to reset password.
			</Typography>

			<Box
				component="form"
				marginTop="5em"
				noValidate
				onSubmit={handleSubmit}>
				<PasswordInput
					{...{
						name: "newPassword",
						label: "New Password",
						value: values.newPassword,
						error: (errors.newPassword && touched.newPassword),
						helperText:
							(errors.newPassword && touched.newPassword) ? errors.newPassword : "",
						onChange: handleChange,
						onBlur: handleBlur,
						required: true,
					}}
				/>

				<PasswordInput
					{...{
						name: "confirmPassword",
						label: "Confirm Password",
						value: values.confirmPassword,
						error: (errors.confirmPassword && touched.confirmPassword) || !isMatch,
						helperText:
							(errors.confirmPassword && touched.confirmPassword) || !isMatch ? errors.confirmPassword || error: "",
						onChange: handleChange,
						onBlur: handleBlur,
						required: true,
					}}
				/>

				<LoadingButton
						className="my-10 rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] "
						sx={{".MuiLoadingButton-loadingIndicatorCenter": {color: 'var(--c-bg-color) !important'}}}
					size="large"
					type={isMatch ? "submit" : "button"}
					variant="contained"
					fullWidth
					loading={loading && isMatch}>
					Reset Password
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default ResetPassword;
