import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getPatients = createAsyncThunk('/hospital/getPatients', (hospitalId, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().patient
  return new BACKEND().send({
   type: 'get',
   to: `/consultations/patients/${hospitalId}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})


export const getPatientConsultations  = createAsyncThunk('/hospital/getPatientConsultations', (patientId, thunkApi) => {
  try {
   const { pagination } = thunkApi.getState().patient
   return new BACKEND().send({
    type: 'get',
    to: `/consultations/patient/${patientId}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
    useAlert: false
  })
  } catch (error) {
    console.error(error);
  }
 })


export const createConsultation = createAsyncThunk('/hospital/createConsultation', (payload, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'post',
    to: "/consultations/consultation",
    useAlert: true,
    payload
  })
  } catch (error) {
    console.error(error);
  }
 })


const initialState = {
 loading: false,
 patients: [],
 consultations: [],
 model: {
  userId: "",
  hospitalId: "",
  patientId: "",
  hospitalStaff: "",
  ailment: "",
 prescription: "",
  description: "",
  amount: "",
},
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 }
};

export const patientSlice = createSlice({
 name: 'patient',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  }
 },
 extraReducers: (builder => {
  builder.addCase(getPatients.pending, (state) => {
   state.loading = true;
  })
  .addCase(getPatients.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload?.success) {
    state.patients = payload?.data?.patients
    state.pagination.total = payload?.data?.count;
    state.pagination.length = state?.pagination?.pageSize * state?.pagination?.page
   }
  })
  .addCase(getPatients.rejected, state => state.loading = false)

  /** GET CONSULTATION BY PATIENTS ID **/ 
  .addCase(getPatientConsultations.pending, (state) => {
    state.loading = true;
   })
   .addCase(getPatientConsultations.fulfilled, (state, { payload }) => {
    state.loading = false;
    if(payload?.success) {
     state.consultations = payload?.data?.consultations
     state.pagination.total = payload?.data?.count;
     state.pagination.length = state?.pagination?.pageSize * state?.pagination?.page
    }
   })
   .addCase(getPatientConsultations.rejected, state => state.loading = false)
 
/** CREATE CONSULTATION **/ 

 .addCase(createConsultation.pending, (state) => {
  state.loading = true;
 })
 .addCase(createConsultation.fulfilled, (state, { payload }) => {
  state.loading = false;
 })
 .addCase(createConsultation.rejected, state => state.loading = false)
})

})

export const { setPagination } = patientSlice.actions
export const getPatientsData = state => state.patient
export default patientSlice.reducer;