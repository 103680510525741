import { Box, FormControl, TextField, Typography } from "@mui/material";
import React from "react";
import HomeLayout from "../../../layouts/Home";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import MultipleSelect from "../../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import { createRole, getPermissions, getRoleData } from "../../../features/users/roleSlice";
import { useFormik } from "formik";
import { vCreateRole } from "../../../utils/validators";
import { useMemo } from "react";
import { useEffect } from "react";

const CreateRole = ({stateModel, editable}) => {
  const navigate = useNavigate(),
  { permissions, model } = useSelector(getRoleData),
		dispatch = useDispatch(),
		{
			values,
			handleBlur,
			handleChange,
			handleSubmit,
			errors,
			touched,
			handleFocus,
		} = useFormik({
			validationSchema: vCreateRole,
			initialValues: stateModel || model,
			onSubmit: async (value) => {
        let permission = []
        
        permissions?.forEach(option => {
          if(value?.permissions?.includes(option?.caption)) permission?.push(option?.id)
        })
        
        let payload = {...values, permissions: permission}


				const action = editable ? createRole : createRole;
				const res = await dispatch(action(payload)).unwrap();

				res?.success === true && navigate(-1)
			},
		});


    useEffect(() => {
      (async ()=> await dispatch(getPermissions()))()
    }, [])

    const permissionsOption = useMemo(()=>{
    return permissions?.map(permission => {
      return {
        name: permission?.caption,
        id: permission?.id
      }
    })
    }, [])

  return (
    <HomeLayout {...{ title: "Configuration" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <Typography className="text-[400] text-xl mt-8 ml-1 text-[#040316]">
          Role Management
        </Typography>
      </Box>
      <Box
        className="py-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
        sx={{ p: 0 }}
      >
        
        <Box className="flex justify-center">
          
          <Box className='flex justify-center flex-col w-3/4' noValidate onSubmit={handleSubmit}
				sx={{ p: 0 }} component={'form'}>

          <Typography className="text-[400] text-xl  text-[#040316]">
          Create Role
        </Typography>
          <FormControl className="w-full border-none lg:mt-5">

						<TextField
							className="w-full bg-white "
							id="name"
							label="Role Name"
							name="name"
							value={values.name}
							required
							onFocus={handleFocus}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.name && touched.name}
							helperText={errors.name && touched.name ? errors.name : ""}
						/>
					</FormControl>


          <Box className="m-auto w-full">
            <Typography className="text-[600] text-[#000000] text-xl  mt-9 mb-5">
              Permissions
            </Typography>
<MultipleSelect
					{...{
						className: "w-full mx-auto lg:mt-8",
						options: permissions?.map(({caption})=>caption) || [],
      readOnly: false,

						value: values.permissions || [],
						title: "Permissions",
						name: "permissions",
						required: true,
						handleBlur,
						handleFocus,
						handleChange,
						error: errors.permissions && touched.permissions,
						helperText: errors.permissions,
					}}
				/>
            <Box className="flex md:justify-end justify-center ">
            <LoadingButton
            className="w-[213px] text-[#FFFFFF] h-[56px] bg-[var(--c-primary-0)] shadow-none  mt-8 text-[600] text-[18px] rounded-xl normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
          >
            Create Role
            
          </LoadingButton>
            </Box>
          </Box>
          </Box>
        </Box>
      </Box>
    </HomeLayout>
  );
};

export default CreateRole;
