import React from 'react'
import { getRole, Roles } from '../../utils/helper';
import CompanyDash from './Company';
import HmoDash from './Hmo';
import HospitalDash from './Hospital';
import SuperAdminDash from './SuperAdmin'

const {
    superAdmin,
    hmoAdmin,
    companyAdmin,
    hospitalAdmin
  } = Roles;


const Dashboard = () => {
    const role = getRole();

    if (getRole()) {
        if (role === superAdmin) {
            return <SuperAdminDash />;
        } else if (role === companyAdmin) {
            return <CompanyDash />;
        } else if (role === hmoAdmin) {
            return <HmoDash />;
        } else if (role === hospitalAdmin) {
            return <HospitalDash />;
        }
    }

    return <SuperAdminDash />;
}

export default Dashboard