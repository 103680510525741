import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../../../../components/SearchBar";
import Table from "../../../../components/table/Table";
import HomeLayout from "../../../../layouts/Home";

const HospitalDebt = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    hidden: false,
  });
  const rows = [];

  return (
    <HomeLayout
      {...{
        title: "Transaction Management",
        subtitle: "Overview  of all information on the system.",
      }}
    >
      <Box className="ml-3">
        <Typography className="text-[400] text-xl mt-8 mb-5  text-[#040316]">
          Hospital Outstanding Payment
        </Typography>
        <SearchBar placeholder="Search For Transactions..." />
      </Box>

      <Box
        className="pt-8 px-8 mt-8 pb-4 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Table
          {...{
            dropdownMenu: rows,
            data: rows,
            pagination,
            setPagination,
          }}
        />
      </Box>
    </HomeLayout>
  );
};

export default HospitalDebt;
