import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Avatar, Stack, Typography } from "@mui/material";
import { IBulb } from "../../utils/icons.utils";
import { Close } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancelModal({
	open,
 loading,
	handleSuccess,
 handleClose,
 btnTexts,
	title,
 subtitle
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


	return (
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				className="p-10"
				sx={{zIndex: 5000}}
				TransitionComponent={Transition}>
				<DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button color="error" onClick={handleClose} autoFocus>
						<Close />
					</Button>
				</DialogActions>
				<DialogTitle
					id="responsive-dialog-title"
					display="flex"
					justifyContent="center"
					mt="0em">
					<Avatar src={IBulb} />
				</DialogTitle>
				<DialogContent>
					<Typography textAlign="center" fontSize="24px">
						{title}
					</Typography>
					<DialogContentText fontSize="20px" padding=".5em 1em" mb={'20px'}>
						{subtitle}
					</DialogContentText>
					<Stack spacing={7} direction="row">
     <LoadingButton
          fullWidth
						sx={{borderRadius: '10px', bgcolor: 'bg-[var(--text-bg)] !important'}}
          size="large"
          variant="outlined"
										onClick={handleClose}
        >
          <span>{btnTexts[0]}</span>
        </LoadingButton>

     <LoadingButton
          fullWidth
						sx={{".MuiLoadingButton-loadingIndicatorCenter": { color: 'white !important'}
					, borderRadius: '10px', bgcolor: 'var(--c-primary-1) !important', '&:hover': {bgcolor: 'var(--c-primary-1) !important'}}}
          size="large"
          loading={loading}
          onClick={handleSuccess}
          variant="contained"
        >
          <span>{btnTexts[1]}</span>
        </LoadingButton>
					</Stack>
				</DialogContent>
			</Dialog>
	);
}
