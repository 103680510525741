import { Avatar, Button, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardComp from "../../components/CardComp";
import HomeLayout from "../../layouts/Home";
import config from "../../utils/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Roles } from "../../utils/helper";
import Storage from "../../utils/storage";
let data, link, pageTitle;
const { routes } = config;
const Profile = () => {
  let roleName = "";
  const user = JSON.parse(Storage.get(config.authProps[1])),
    navigate = useNavigate(),
    getPayload = (role) => {
      switch (role) {
        case Roles.companyAdmin:
          roleName = "Company Admin";
          pageTitle = "Company";
          link = routes.companies.company;
          data = {
            name: user?.companies?.[0]?.name,
            email: user?.companies?.[0]?.email,
            phoneNumber: user?.companies?.[0]?.phoneNumber,
            state: user?.companies?.[0]?.state,
            city: user?.companies?.[0]?.city,
            streetName: user?.companies?.[0]?.streetName,
            user: {
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              phoneNumber: user?.phoneNumber,
            },
            companyLogo: user?.companies?.[0]?.companyLogo,
            websiteLink: user?.companies?.[0]?.websiteLink,
            rcNumber: user?.companies?.[0]?.rcNumber || "",
            id: user?.companies?.[0]?.id,
          };
          break;
        case Roles.hospitalAdmin:
          roleName = "Hospital Admin";
          pageTitle = "Hospital";
          link = routes.hospitals.hospital;
          data = {
            name: user?.hospitals?.[0]?.name,
            email: user?.hospitals?.[0]?.email,
            phoneNumber: user?.hospitals?.[0]?.phoneNumber,
            state: user?.hospitals?.[0]?.state,
            city: user?.hospitals?.[0]?.city,
            streetName: user?.hospitals?.[0]?.streetName,
            user: {
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              phoneNumber: user?.phoneNumber,
            },
            hospitalLogo: user?.hospitals?.[0]?.companyLogo,
            websiteLink: user?.hospitals?.[0]?.websiteLink,
            rcNumber: user?.hospitals?.[0]?.rcNumber || "",
            id: user?.hospitals?.[0]?.id,
          };
          break;
        case Roles.hmoAdmin:
          roleName = "H. M. O Admin";
          pageTitle = "H. M. O";
          link = routes.hmos.hmo;
          data = {
            name: user?.hmos?.[0]?.name,
            email: user?.hmos?.[0]?.email,
            phoneNumber: user?.hmos?.[0]?.phoneNumber,
            state: user?.hmos?.[0]?.state,
            city: user?.hmos?.[0]?.city,
            streetName: user?.hmos?.[0]?.streetName,
            user: {
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              phoneNumber: user?.phoneNumber,
            },
            hmoLogo: user?.hmos?.[0]?.hmoLogo,
            logo: user?.hmos?.[0]?.hmoLogo,
            websiteLink: user?.hmos?.[0]?.websiteLink,
            rcNumber: user?.hmos?.[0]?.rcNumber || "",
            id: user?.hmos?.[0]?.id,
          };
          break;
        case Roles.superAdmin:
          roleName = "Super Admin";
          pageTitle = "Profile";
          data = {
            email: user?.email,
            phoneNumber: user?.phoneNumber,
            state: user?.hmos?.[0]?.state,
            city: user?.hmos?.[0]?.city,
            streetName: user?.hmos?.[0]?.streetName,
            user: {
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              phoneNumber: user?.phoneNumber,
            },
            companyLogo: user?.hmos?.[0]?.hmoLogo,
            logo: user?.hmos?.[0]?.hmoLogo,
            websiteLink: user?.hmos?.[0]?.websiteLink,
            rcNumber: user?.hmos?.[0]?.rcNumber || "",
            id: user?.hmos?.[0]?.id,
          };
          break;
        case Roles.hospitalStaff:
          roleName = "Hospital Staff";
          pageTitle = "Profile";
          data = {
            email: user?.email,
            phoneNumber: user?.phoneNumber,
            state: user?.hospitaluser?.[0]?.state,
            city: user?.hospitaluser?.[0]?.city,
            streetName: user?.hospitaluser?.[0]?.streetName,
            user: {
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.hospitaluser?.[0]?.email,
              phoneNumber: user?.phoneNumber,
            },
            companyLogo: user?.hospitaluser?.[0]?.hospitalLogo,
            logo: user?.hospitaluser?.[0]?.hospitalLogo,
            websiteLink: user?.hmos?.[0]?.websiteLink,
            rcNumber: user?.hospitaluser?.[0]?.rcNumber || "",
            id: user?.hmos?.[0]?.id,
          };
          break;
        case Roles.companyStaff:
          roleName = "Company Staff";
          pageTitle = "Profile";
          data = {
            email: user?.email,
            phoneNumber: user?.phoneNumber,
            state: user?.hospitaluser?.[0]?.state,
            city: user?.hospitaluser?.[0]?.city,
            streetName: user?.hospitaluser?.[0]?.streetName,
            user: {
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.hospitaluser?.[0]?.email,
              phoneNumber: user?.phoneNumber,
            },
            companyLogo: user?.hospitaluser?.[0]?.hospitalLogo,
            websiteLink: user?.hmos?.[0]?.websiteLink,
            rcNumber: user?.hospitaluser?.[0]?.rcNumber || "",
            id: user?.hmos?.[0]?.id,
          };
          break;
        default:
          data = {};
          break;
      }
      return data;
    },
    nav = (_) => {
      navigate(link + "/" + data?.id + "/edit", {
        state: { ...getPayload(user?.role?.[0]), editable: true },
      });
    };

  useEffect(() => {
    document.title = pageTitle;
    // console.log(user[getTitle(user.role?.[0])]);
    // const res = (async _=>await dispatch(getUserById(user?.id)).unwrap())();
    // console.log(res);
  }, []);

  getPayload(user?.role?.[0]);
  return (
    // <HomeLayout title= {pageTitle}>
    <HomeLayout title={"Profile"}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className="mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <div>
        <Box
          className="py-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
          sx={{ p: 0 }}
        >
          <CardComp
            className={
              "bg-[#FFFFFF] w-[95%] mb-8 md:p-2 lg:p-4 p-3 border-none rounded-lg shadow-none m-auto"
            }
          >
            <CardContent className=" ">
              <Box className="flex flex-row gap-6 md:gap-3 lg:gap-10 justify-between">
                <Box className="flex gap-5">
                  <Avatar
                    // alt="profile pic"
                    className="w-[80px] h-[80px] border"
                    src={user?.profileImage || data?.logo}
                  />
                  <Box className="flex flex-col mt-2">
                    <Typography
                      className="text-xl text-[#040316] font-bold mt-2 mb-0"
                      variant="body1"
                      component="p"
                    >
                      {data?.user?.firstName + " " + data?.user?.lastName}
                    </Typography>
                    <Typography className="text-lg text-[#040316]">
                      {roleName}
                    </Typography>
                  </Box>
                </Box>
                {user?.role?.[0] !== Roles.superAdmin &&
                  user?.role?.[0] !== Roles.hospitalStaff && (
                    <Box className="ml-auto my-auto">
                      <Button
                        onClick={nav}
                        variant="contained"
                        size="large"
                        className="rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] shadow-none normal-case"
                        sx={{
                          ".MuiLoadingButton-loadingIndicatorCenter": {
                            color: "var(--c-bg-color) !important",
                          },
                        }}
                      >
                        Edit Profile
                      </Button>
                    </Box>
                  )}
              </Box>
              <Box className="mt-8 flex flex-row justify-between">
                <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                  Email Address
                </Typography>
                <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                  {data?.email || "---"}
                </Typography>
              </Box>
              <Box className="mt-8 flex flex-row justify-between">
                <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                  Phone Number
                </Typography>
                <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                  {data?.phoneNumber || "---"}
                </Typography>
              </Box>
              {user?.role?.[0] !== Roles.superAdmin && (
                <Box className="mt-8 flex flex-row justify-between">
                  <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                    Admin Email
                  </Typography>
                  <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                    {data?.user?.email || "---"}
                  </Typography>
                </Box>
              )}
              {user?.role?.[0] !== Roles.superAdmin && (
                <Box className="mt-8 flex flex-row justify-between">
                  <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                    RC Number
                  </Typography>
                  <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                    {data?.rcNumber || "N/A"}
                  </Typography>
                </Box>
              )}

              {user?.role?.[0] !== Roles.superAdmin &&
                user?.role?.[0] !== Roles.hospitalAdmin &&
                user?.role?.[0] !== Roles.hospitalStaff && (
                  <Box className="mt-8 flex flex-row justify-between">
                    <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                      Website
                    </Typography>
                    <Link
                      to={data?.websiteLink}
                      color="primary"
                      className="xl:text-[20px] md:text-[15px] text-[20px] hover:text-[var(--c-primary-1)] pl-2 pt-1 text-[var(--c-primary-0)]"
                    >
                      {data?.websiteLink || "---"}
                    </Link>
                  </Box>
                )}
            </CardContent>
          </CardComp>
        </Box>
      </div>
    </HomeLayout>
  );
};

export default Profile;
