import { Avatar, Box, CardContent, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeLayout from "../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import CardComp from "../../components/CardComp";
import config from "../../utils/config";
import dayjs from "dayjs";

const { routes } = config;
const EmployeeDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <HomeLayout {...{ title: "Employee" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <Typography className="text-[400] text-xl mt-8 text-[#040316]">
          View Employee
        </Typography>
        <Box className="flex justify-between gap-4 sm:flex-col lg:flex-row flex-col">
          <LoadingButton
            className="w-[243px] h-[56px] bg-[#EAE9FC] text-[var(--c-primary-0)] text-[13px] rounded-xl normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
          >
            {/* <Link
          to={routes.companies.employees.upload}
          > */}
            Request Physical Card
            {/* </Link> */}
          </LoadingButton>
          <LoadingButton
            className="w-[243px] h-[56px] bg-[var(--c-primary-0)] text-[600] text-[13px] rounded-xl normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            // startIcon={<AddIcon />}
          >
            <Link to={routes.companies.employees.replaceCard}>
              Card Replacement Request
            </Link>
          </LoadingButton>
        </Box>
      </Box>
      <Box
        className="py-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
        sx={{ p: 0 }}
      >
        <CardComp
          className={
            "bg-[#FFFFFF] w-[95%] mb-8 md:p-2 lg:p-4 p-3 border-none rounded-lg shadow-none m-auto"
          }
        >
          <CardContent className=" ">
            <Box className="flex flex-row gap-6 md:gap-3 lg:gap-10">
              <Avatar
                alt="Remy Sharp"
                className="w-[100px] h-[100px] border"
                src={state?.User?.profileImage}
              />
              <Box className="flex flex-col">
                <Box>
                  <Typography
                    className="text-[24px] text-[#040316] font-semibold mt-8"
                    variant="h5"
                  >
                    {state?.User?.firstName + " " + state?.User?.lastName}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Employee ID
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.employeeId || "---"}
              </Typography>
            </Box>
            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Phone Number
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.User?.phoneNumber || "---"}
              </Typography>
            </Box>
            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Address
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.street || "---"}
              </Typography>
            </Box>
            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                H. M. O Plan
              </Typography>
              <Typography className="xl:text-[20px] xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.companyuserplans?.name || "N/A"}
              </Typography>
            </Box>

            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Subscription Expiry Date
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {dayjs(state?.expiryDate).format("MMM DD, YYYY") || "---"}
              </Typography>
            </Box>

            <Box className="mt-8 flex flex-row justify-between">
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#6B7588]">
                Subscription Status
              </Typography>
              <Typography className="xl:text-[20px] md:text-[15px] text-[20px] text-[400] pl-2 pt-1 text-[#040316]">
                {state?.status || "---"}
              </Typography>
            </Box>
          </CardContent>
        </CardComp>
      </Box>
    </HomeLayout>
  );
};

export default EmployeeDetails;
