import React from "react";
import Box from "@mui/material/Box";
import {
	Typography,
	FormControl,
	TextField,
	Avatar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { IArrowBack } from "../../utils/icons.utils";
import Modal from "../Modals/ValidateToken";
import { useFormik } from "formik";
import { userForgotPassword } from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, getAuthData, resendOtp, verifyOtp, setCurrentScreen } from "../../features/auth/authSlice";

const ForgotPassword = () => {
	const dispatch = useDispatch(),
	{ loading, modal: { first: resend, second: verify, open  }, payload: { email }} = useSelector(getAuthData),
	handleVerify = async otp => {
		dispatch(verifyOtp(otp))
	},
	handleResend = () => dispatch(resendOtp()),
		{
			values,
			handleBlur,
			handleChange,
			handleSubmit,
			errors,
			touched,
			handleFocus,
		} = useFormik({
			validationSchema: userForgotPassword,
			initialValues: { email },
			onSubmit: async (value) => {
				try {
					dispatch(forgotPassword(value))
				} catch (error) {
					console.log(error);
				}
			},
		});

	return (
		<Box className="pt-24">
			<Box display="flex" alignItems="center" marginBottom="2em">
				{" "}
				<Avatar
					src={IArrowBack}
					className="w-10 h-10 hover:bg-gray-400 p-2 mr-2 cursor-pointer"
					onClick={(_) => dispatch(setCurrentScreen("login"))}
				/>{" "}
				<Typography fontSize="20px">Go back</Typography>{" "}
			</Box>
			<Typography component={"h4"} variant={"h4"} className="font-bold">
				Forgot Password
			</Typography>
			<Typography className="text-lg" fontSize="24px">
				Enter your email address.
			</Typography>

			<Box
				component="form"
				marginTop="5em"
				noValidate
				onSubmit={handleSubmit}>
				<FormControl required fullWidth>
				<TextField
						className="w-full bg-white"
						id="email"
						label="Email Address"
						name="email"
						value={values.email}
						required
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocus}
						error={errors.email && touched.email}
						helperText={
							errors.email && touched.email
								? errors.email
								: ""
						}
						type="email"
					/>
				</FormControl>

				<LoadingButton
					fullWidth
					className="my-10 rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] "
					sx={{".MuiLoadingButton-loadingIndicatorCenter": {color: 'var(--c-bg-color) !important'}}}
					size="large"
					type="submit"
					loading={loading}
					variant="contained">
					<span>Confirm</span>
				</LoadingButton>
			</Box>
			<Modal
				{...{
					open,
					onSubmit: handleVerify,
					disabled: verify,
					handleResend,
					resend,
				}}
			/>
		</Box>
	);
};

export default ForgotPassword;
