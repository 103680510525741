/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddStaff from "./AddStaff";

const EditStaff = () => {
	const { state } = useLocation();
	const navigate = useNavigate(),
		cb = async (res) => {
			res.payload.success === true && navigate(-1);
		};
	const stateModel = useMemo((_) => {
		return {
			employeeFirstName: state?.User?.firstName,
			employeeLastName: state?.User?.lastName,
			employeeEmail: state?.User?.email,
			profileImage: state?.User?.profileImage,
			gender: state?.User?.gender,
			employeePhoneNumber: state?.User?.phoneNumber,
			city: state?.city || "",
			state: state?.state || "",
			street: state?.street || "",
			specialization: state?.specialization,
			designation: state?.designation,
			hospitalId: state?.hospitalId,
   userId: state?.userId
		};
	}, []);

	return <AddStaff {...{ editable: state.editable, stateModel, cb }} />;
};

export default EditStaff;
