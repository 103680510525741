import React from "react";
import HomeLayout from "../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography, CardContent, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CardComp from "../../components/CardComp";
import { LoadingButton } from "@mui/lab";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { bulkUploadHmo, getHmoData } from "../../features/hmo/hmoSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getFileSize } from "../../utils/helper";
import { useRef } from "react";
import { uploadFile } from "../../features/auth/authSlice";

const UploadHmo = () => {
  const navigate = useNavigate(),
  { loading } = useSelector(getHmoData),
  dispatch = useDispatch(),
  [file, setFile] =  useState('');
  const fileUploadDiv = useRef(null);

  const dragEnter = (_) => {
    fileUploadDiv.current.classList.add("drag_active");
   },
   dragLeave = (_) => {
    fileUploadDiv.current.classList.remove("drag_active");
   },
   dragDrop = (e) => {
    e.preventDefault();
    fileUploadDiv?.current?.classList.remove("drag_active");
    const files = e?.dataTransfer?.files || e?.target?.files;
  
    if (files.length > 0) {
      setFile(files)
    }
   };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file[0])
    const res = await dispatch(bulkUploadHmo(formData)).unwrap();
    res?.success && navigate(-1)
  }
  return (
    <HomeLayout {...{ title: "H.M.O" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] mb-2">Go back</Typography>
      </Box>
      <Typography className="text-[400] text-[22px] mt-8 text-[#040316]">
        Upload H. M. O
      </Typography>
      <Box
        className="py-5 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
        sx={{ p: 0 }}
      >
        <CardComp
         className={"bg-[#FFFFFF] w-[70%] mb-3 pt-2 xl:pl-3 border-none rounded-lg shadow-none m-auto"}
        >
          <CardContent className="flex flex-col gap-5 ">
           
           <Button
           component='a'
           href="/HIMP_HMO_BULK_TEMPLATE.xlsx"
             className="xl:w-[250px] w-[176px] h-[50px] bg-[var(--c-primary-0)] text-[600] xl:text-[17px] text-[11px] m-auto rounded-xl shadow-none normal-case"
             size="small"
             variant="contained"
             fullWidth
             startIcon={<CloudDownloadIcon />}
           >
             Download Template
           </Button>

         <div className={`mx-auto max-w-[300px] w-full h-[300px] mt-5  ${file.length > 0?'pb-20' : 'pb-5'}`} ref={fileUploadDiv}
				Leave={dragLeave}>
         {file.length > 0 && <p className="font-md text-black">{file?.[0]?.name}</p> }
           <div className="border border-black relative w-full h-full flex justify-center items-center">
           <input
            onDragEnter={dragEnter}
					onDrop={dragDrop}
					onChange={dragDrop}
           type="file" 
           className="opacity-0 w-full h-full absolute z-20" id='upload' />
         {file.length > 0 ? <div>
         <img src="/excel.svg" alt="" width='120' height='120'/>
         <h3 className="text-center">{getFileSize({size: file?.[0]?.size})}</h3>
         </div> 
       :
       <Box className="text-center m-auto my-10">
       <Typography className="text-[20px] text-[#040316]">
         Drag excel file here
       </Typography>
       <Typography className="text-[20px] text-[#040316]">
         or
       </Typography>
       <Typography className="text-[20px] text-[var(--c-primary-1)]">
        select file
        </Typography>
     </Box>  
       }

           </div>
           { file.length > 0 && <LoadingButton
									onClick={handleUpload}
									variant="contained"
					fullWidth
									size="medium"
									className="w-full bg-[var(--c-primary-3)] block hover:bg-[var(--c-primary-0)] hover:text-white normal-case"
									sx={{".MuiLoadingButton-loadingIndicatorCenter": {color: 'var(--c-bg-color) !important'}}}
									loading={loading}>
									<span>Submit</span>
								</LoadingButton>}
         </div>
         

           { (!file || file.length === 0) && <Button
           component='label'
             className="xl:w-[250px] w-[176px] h-[50px] bg-[var(--c-primary-3)] hover:bg-[var(--c-primary-0)] hover:text-white text-[600] xl:text-[17px] text-[11px] m-auto rounded-xl shadow-none normal-case"
             size="small"
             htmlFor="upload"
             variant="contained"
             fullWidth
           >
             Select File
           </Button>}
         </CardContent>

        </CardComp>
      </Box>
    </HomeLayout>
  );
};

export default UploadHmo;
