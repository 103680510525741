/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SearchBar from "../../components/SearchBar";
import DatePicker from "../../components/DatePicker";
import HomeLayout from "../../layouts/Home";
import Box from "@mui/material/Box";
import Table from "../../components/table/Table";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import config from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
	getHospitalData,
	getHospitals,
	setPagination
} from "../../features/hospitals/hospitalSlice";
import dayjs from "dayjs";
import Menu from "../../utils/data.dropdown";
import TablePreloader from "../../components/Loader/TablePreloader";
import { IError } from "../../utils/icons.utils";
import { Typography } from "@mui/material";
import { capitalize } from "../../utils/helper";

const { routes } = config;
export default function Hospitals() {
	const { hospitals, pagination, loading } =
	useSelector(getHospitalData),
	dispatch = useDispatch(),
		navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		let cb = ()=>{}
		
		if(pagination.search) {
			dispatch(setPagination({page: 1}))
			cb = setTimeout(_=> (async ()=> await dispatch(getHospitals()))(), 700)
		}else cb = (async () => await dispatch(getHospitals()))();

		return ()=>{
				clearTimeout(cb)
		}
}, [ pagination.search, pagination.page])

useEffect(()=>{
	setIsLoading(false)
},[])

	let data = hospitals?.map((hospital) => ({
			"Hospital Name": capitalize(hospital?.name),
			email: hospital?.email,
			"Phone Number": hospital?.phoneNumber,
			"Date Created ": dayjs(hospital?.createdAt).format("MMM DD, YYYY"),
			Status: hospital?.status ? "Active" : "Inactive",
			_data: hospital,
		}));

	const menu = new Menu({});
	const dropdownMenu = [
		menu.viewHospitalProfile({ navigate, routes }),
		menu.edit({
			navigate,
			route: routes.hospitals.hospital,
		}),
	];
	return (
		<HomeLayout {...{ title: "Hospital" }}>
			<Box className="flex justify-between flex-col gap-6 sm:flex-col lg:flex-col xl:flex-row">
				<SearchBar  {... {
					 placeholder: "Search For Hospital",
					onChange: (e) => {
						const { value } = e.target;
						if(e.code === 'Enter') dispatch(getHospitals({search: value}))
					else dispatch(setPagination({search: value}))
					}
        }} />
				<Box className="flex justify-between gap-4 sm:flex-col lg:flex-row flex-col">
					<LoadingButton
					LinkComponent={Link}
						className="w-[213px] h-[56px] bg-[var(--c-gray-4)] text-[var(--c-primary-0)] text-[18px] rounded-xl shadow-none normal-case"
						size="small"
						type="submit"
						variant="contained"
						fullWidth
						to={routes.hospitals.upload}>
						Upload Hospital
					</LoadingButton>
					<LoadingButton
					LinkComponent={Link}
						className="w-[213px] h-[56px] bg-[var(--c-primary-0)] text-[600] text-[18px] rounded-xl shadow-none normal-case"
						size="small"
						type="submit"
						variant="contained"
						fullWidth
						startIcon={<AddIcon />}
						to={routes.hospitals.new}>
						Add Hospital
					</LoadingButton>
				</Box>
			</Box>
			<DatePicker  />
			<div className="w-full  mt-5">
				<Box
					className="pt-8 px-8 pb-2 rounded-lg bg-[var(--c-bg-color)] w-100%"
					sx={{ p: 0 }}>
					{isLoading  ? (
						<TablePreloader />
					) : data.length ? (
						<Table
							{...{
								dropdownMenu,
								data,
								pagination,
								setPagination: d => dispatch(setPagination(d())),
								isLoading: loading,
							}}
						/>
					) : (
						<Box
						className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
						sx={{ p: 0 }}
					  >
						<img
						className="m-auto w-[350px] mt-8"
						src={IError}
						alt={"error"}
						loading="lazy"
					  />
					  <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
					  No Hospitals in the system at the moment
					  </Typography>
					  </Box>
					)}
				</Box>
			</div>
		</HomeLayout>
	);
}
