import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeLayout from "../../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import config from "../../../../utils/config";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../../../components/SearchBar";
import Table from "../../../../components/table/Table";

const { routes } = config;
const HmoServices = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    hidden: false,
  });
  const data = [];

  return (
    <HomeLayout {...{ title: "Configuration" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mx-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-between flex-col gap-6 ml-3 sm:flex-col lg:flex-col xl:flex-row">
        <Typography className="text-[400] text-xl mt-8 mb-5  text-[#040316]">
          Service Offerings
        </Typography>
        <LoadingButton
          LinkComponent={Link}
          className="w-[213px] h-[56px] bg-[var(--c-primary-0)] text-[600] text-[16px] rounded-xl shadow-none normal-case"
          size="small"
          type="submit"
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          to={routes.hmos.configurations.services.new}
        >
          Create Service
        </LoadingButton>
      </Box>
      <SearchBar placeholder="Search For Service..." />
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Table
          {...{
            data,
            pagination,
            setPagination,
          }}
        />
      </Box>
    </HomeLayout>
  );
};

export default HmoServices;
