import React, { useEffect, useState } from "react";
import {
  IArrowUp,
  IBrand2,
  ITwitter,
  IWeb,
  Iyoutube,
  IInstagram,
} from "../../utils/icons.utils";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const Footer = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Box className="flex flex-col" style={{ backgroundColor: "transparent" }}>
      {/* FOOTER BEGINS */}
      <footer className="bg-[var(--c-primary-0)] text-white pt-8">
        <Box className="w-[90%] m-auto pt-[3%] md:flex md:justify-around flex flex-col md:flex-col text-center md:text-start">
          <Box className="">
            <Link to={"/"}>
              <img
                className="text-xl w-44 font-bold m-auto md:m-0"
                src={IBrand2}
                alt=""
              />
            </Link>
          </Box>
          <Box className="text-[var(--cc-grey-3)] mt-8 md:mt-0 md:flex md:justify-between">
            <ul className=" md:flex gap-8 my-7 font-[400] text-[16px]">
              <li>
                <Link to={""}>About</Link>
              </li>
              <li>
                <Link to={""}>Features</Link>
              </li>
              <li>
                <Link to={""}>Pricing</Link>
              </li>
              <li>
                <Link to={""}>Careers</Link>
              </li>
              <li>
                <Link to={""}>Help</Link>
              </li>
              <li>
                <Link to={""}>Privacy Policy</Link>
              </li>
            </ul>
            {scrollPosition > 100 && (
              <Box
                className=" py-3 bg-white rounded-lg w-[18%] md:w-[10%] lg:w-[8%] xl:w-[6%] h-[100%] flex justify-evenly hover:bg-gray-200 text-[var(--c-primary-0)] cursor-pointer m-auto mb-5 md:m-0"
                onClick={handleScrollToTop}
              >
                {" "}
                <span className="">Top</span>
                <img src={IArrowUp} alt="youtube" className="" />
              </Box>
            )}
          </Box>
        </Box>
        <Box className="flex gap-4 md:justify-between flex-col md:flex-row text-center md:text-start  mb-14  text-[var(--cc-grey-3)] w-[90%] m-auto">
          <span className="  mb-4  font-[500] text-[12px]">
            &copy; 2023 CareQuest. All rights reserved
          </span>
          <Box className="text-[var(--cc-grey-3)]">
            <Box className="flex justify-center gap-4  m-auto w-[50%]">
              <img
                src={IInstagram}
                alt="instagram"
                className="cursor-pointer"
              />
              <img src={IWeb} alt="web" className="cursor-pointer" />
              <img src={ITwitter} alt="twitter" className="cursor-pointer" />
              <img src={Iyoutube} alt="web" className="cursor-pointer" />
            </Box>
          </Box>
        </Box>
      </footer>
      {/* FOOTER ENDS */}
    </Box>
  );
};

export default Footer;
