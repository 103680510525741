import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { ILandinImage } from "../../utils/icons.utils";
import { setCurrentScreen } from "../../features/auth/authSlice"
import { useDispatch } from "react-redux";

const LandingPage = () => {
  const dispatch = useDispatch(),
  changeScreen = (screen) => dispatch(setCurrentScreen(screen));

  return (
    <Box>
      <Typography component={"h4"} variant={"h4"} className="font-bold">
        Welcome To CareQuest!
      </Typography>
      <Typography className="text-lg" fontSize="24px">
        Get started with us
      </Typography>
      <Avatar
        alt="image"
        src={ILandinImage}
        variant="square"
        className="w-[100%] h-[100%] mt-14"
      />
      <Box
        display="flex"
        justifyContent="center"
        className="mt-14 mb-3 sm:hidden "
      >
        
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        className="mt-8 mb-3 sm:hidden"
      >
        <Button
          onClick={(_) => changeScreen("login")}
          className="w-[80%] h-[52%] bg-[var(--c-primary-3)] rounded-lg shadow-lg"
          sx={{ color: "white" }}
          size="large"
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default LandingPage;
