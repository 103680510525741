import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getAppointments = createAsyncThunk('/hopsital/getAppointments', (hospitalId, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().appointment
  return new BACKEND().send({
   type: 'get',
   to: `/appointments/hospitals/${hospitalId}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const updateAppointmentStatus = createAsyncThunk('/hopsital/updateAppointmentStatus', ({appointmentId, status}, thunkApi) => {
  try {
   return new BACKEND().send({
    type: 'patch',
    to: `/appointments/appointment/${appointmentId}/?status=${status}`,
    useAlert: true
  })
  } catch (error) {
    console.error(error);
  }
 })

const initialState = {
 loading: false,
 appointments: [],
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 },
 modal: {
  delete: false,
  add: false,
  loading: false
 }
};

export const appointmentSlice = createSlice({
 name: 'appointment',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  },
  openModal: (state, {payload}) => {
    state.modal[payload] = true
  },
  closeModal: (state, {payload}) => {
    state.modal[payload] = false
  },
 },
 extraReducers: (builder => {
  builder.addCase(getAppointments.pending, (state) => {
   state.loading = true;
  })
  .addCase(getAppointments.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload?.success) {
    state.appointments = payload?.data?.appointments
    state.pagination.total = payload?.data?.count;
    state.pagination.length = state?.pagination?.pageSize * state?.pagination?.page
   }
  })
  .addCase(getAppointments.rejected, state => state.loading = false)
  .addCase(updateAppointmentStatus.pending, (state) => {
    state.modal.loading = true;
  })
  .addCase(updateAppointmentStatus.fulfilled, (state, {payload}) => {
    state.modal.loading = false;
    if(payload.success) state.modal.delete = false;
  })
  .addCase(updateAppointmentStatus.rejected, (state) => {
    state.modal.loading = false;
  });
})
});

export const { setPagination, closeModal, openModal } = appointmentSlice.actions
export const getAppointmentsData = state => state.appointment
export default appointmentSlice.reducer;