/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HomeLayout from '../../../layouts/Home'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import config from '../../../utils/config';
import { LoadingButton } from '@mui/lab';
import Table from '../../../components/table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getStaff, getStaffData, setPagination, openModal, closeModal, deleteStaff } from '../../../features/hospitals/staffSlice';
import { getIds } from '../../../utils/helper';
import Menu from '../../../utils/data.dropdown';
import TablePreloader from '../../../components/Loader/TablePreloader';
import CancelModal from '../../../components/Modals/Cancel';
import SearchBar from '../../../components/SearchBar';
import { IError } from '../../../utils/icons.utils';


const { routes } = config;
const HospitalStaffs = () => {
    const navigate = useNavigate(),
     dispatch = useDispatch(),
    [staffDetails, setStaffDetails] = useState({name: '', employeeId: null, hospitalId: null}),
    { pagination, loading, staffs, modal } = useSelector(getStaffData),
    [isLoading, setIsLoading] = useState(true)
    
    useEffect(()=>{
        let cb = ()=>{}
        if(pagination.search) {
          dispatch(setPagination({page: 1}))
          cb = setTimeout(_=> (async ()=> await dispatch(getStaff(getIds().hospital)))(), 700)
        }else cb = dispatch(getStaff(getIds().hospital))
        return ()=>{
          clearTimeout(cb)
        }
      }, [pagination.page, pagination.search])

      useEffect(()=>{
        setIsLoading(false)
      }, [])

      let data = staffs?.map( staff => ({
        "Name": staff?.User?.firstName+ ' '+ staff?.User?.lastName,
        "Email": staff?.User?.email,
        "Phone Number": staff?.User?.phoneNumber,
        "Gender": staff?.User?.gender || 'N/A',
        "Designation": staff?.designation,
        _data: staff
      }) )

    const menu = new Menu({});
    const dropdownMenu = [
      menu.view({
        navigate,
        route: routes.hospitals.staffs.staff,
      }),
      menu.delete({action: (staff) => {
        setStaffDetails(state=> ({name: staff?.User?.firstName+ ' '+ staff?.User?.lastName, employeeId: staff?.userId, hospitalId: staff?.hospitalId}))
        dispatch(openModal('delete'))
}}),
      menu.edit({
        navigate,
        route: routes.hospitals.staffs.staff,
      }),
    ],
    handleDeleteStaff = async () => {
      const res = await dispatch(deleteStaff(staffDetails)).unwrap()
      res?.success === true && dispatch(getStaff(getIds().hospital))
    }

  return (
    <HomeLayout {...{ title: "Staff Management"  }}>
    <Box className="flex  mt-2 text-[var(--c-dark-1)]">
              <ArrowBackIcon
                  onClick={(_) => navigate(-1)}
                  className=" mx-2 cursor-pointer"
                  fontSize="small"
              />
              <Typography className="text-[400] text-[16px] ">Go back</Typography>
          </Box>
          <Box className="flex justify-between mt-3">
          <SearchBar {... {
          placeholder: "Search by Employee Name...",
          onChange: (e) => {
            const { value } = e.target;
            if(e.code === 'Enter') dispatch(getStaff(value))
           else dispatch(setPagination({search: value}))
          }
        }}/>
          <LoadingButton
            LinkComponent={Link}
            className="w-[173px] h-[50px] bg-[var(--c-primary-0)] text-[600] text-[16px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            to={routes.hospitals.staffs.new}
          >
            Add Staffs
          </LoadingButton>
          </Box>
          <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
         <Typography className="text-[400] text-xl  mb-5  text-[#040316]">
         Staffs
			</Typography>
      {isLoading ? (
						<TablePreloader />
					) : data?.length ? (
						<Table
							{...{
								dropdownMenu,
								data,
              pagination,
              setPagination: d => dispatch(setPagination(d())),
								isLoading: loading,
							}}
						/>
					) : (
            <Box
            className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
            sx={{ p: 0 }}
          >
            <img
            className="m-auto w-[350px] mt-8"
            src={IError}
            alt={"error"}
            loading="lazy"
          />
          <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
          No Staff in the system at the moment
          </Typography>
          </Box>
					)}
        </Box>


        <CancelModal
         {...{
           open: modal.delete,
           loading: modal.loading,
            title: 'Delete Plan',
            subtitle: `Are you sure you want to delete ${staffDetails.name}?`,
            btnTexts: ['Cancel', 'Delete'],
            handleSuccess: handleDeleteStaff,
            handleClose: _=> dispatch(closeModal('delete'))
         }}
         />
    </HomeLayout>
  )
}

export default HospitalStaffs