import {
  Box,
  CardContent,
  CardMedia,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CardComp from "../../components/CardComp";
import HomeLayout from "../../layouts/Home";
import { IHomeTwo, IBoxes, IHmoTwo } from "../../utils/icons.utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Table from "../../components/table/Table";
import config from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard, getHmoData } from "../../features/hmo/hmoSlice";
import { Roles, getAmount, getIds } from "../../utils/helper";
import dayjs from "dayjs";

const { routes } = config;
export default function HmoDash() {
  const { dashboard, loading } = useSelector(getHmoData),
    dispatch = useDispatch();

  useEffect(() => {
    (async () => await dispatch(getDashboard(getIds().hmo)))();
  }, []);

  const mapData = [
    {
      icon: IHomeTwo,
      title: getAmount(dashboard?.numberOfRegisteredHospitals),
      subtitle: "Registered Hospitals",
    },
    {
      icon: IBoxes,
      title: getAmount(dashboard?.totalProfits),
      subtitle: "Generated R. 0. I ",
    },
  ];

  const mapDataTwo = [
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Basic H. M. O",
    },
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Premimum H. M. O  ",
    },
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Classic H. M. O",
    },
    {
      icon: IHmoTwo,
      title: "7,000",
      subtitle: "Executive H. M. O ",
    },
  ];

  const data = dashboard?.subscribers?.map((user) => ({
    Name: `${user?.firstName} ${user?.lastName}`,
    Email: user?.email || "N/A",
    "Phone Number": user?.phoneNumber,
    // "Amount Per Plan": "₦" + getAmount(plan?.amount),
    Role:
      user?.role?.[0] === Roles.companyStaff
        ? "Company Staff"
        : "Hospital Staff",
    // "Date Subscribed": dayjs(user?.createdAt).format("MMM DD, YYYY"),

    _data: user,
  }));

  return (
    <HomeLayout
      {...{
        title: "Dashboard",
        subtitle: "Overview of all information on the system.",
      }}
    >
      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ my: 3 }}
      >
        Business Overview
      </Typography>
      <Box className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
        {mapData.map((data, index) => {
          return (
            <Box elevation={3} key={index}>
              <CardComp
                className={
                  "bg-[var(--c-bg-color)] w-[100%] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                }
              >
                <CardContent className="flex gap-6 ">
                  <Box
                    className={`  rounded-lg`}
                    sx={{ height: 60, width: 60 }}
                  >
                    <CardMedia
                      component="img"
                      // sx={{ height: 25, width: 25 }}
                      className={`${
                        index === 2 && "xl:p-4 lg:p-3 md:p-2 p-4"
                      } ${index === 2 && "bg-[#007AFF1A]"}`}
                      image={data.icon}
                      alt="Paella dish"
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{ fontSize: 20, fontWeight: 700 }}
                      variant="h1"
                      color="black"
                      gutterBottom
                    >
                      {data.title}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {data.subtitle}
                    </Typography>
                  </Box>
                </CardContent>
              </CardComp>
            </Box>
          );
        })}
      </Box>

      <Typography
        variant="h6"
        noWrap
        component="div"
        color="#040316"
        sx={{ my: 3, pl: 3 }}
      >
        Subscribed Plans
      </Typography>

      {loading ? (
        // <CircularProgress className="ml-10" size={20} />
        <Box className="grid lg:grid-cols-3 overflow-x-scroll md:grid-cols-3 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full">
          {Array(3)
            .fill("")
            .map((a) => (
              <Skeleton className="min-w-[300px] h-[180px] gap-6 pt-2 xl:pl-3 border-none shadow-md rounded-xl" />
            ))}
        </Box>
      ) : dashboard?.plansData?.length > 0 ? (
        <Box
          sx={{
            mt: 3,
          }}
          className="---plan flex lg:grid-cols-4 overflow-x-scroll md:grid-cols-2 grid-cols-1 gap-4 lg:gap-10 p-4 mb-8 max-w-full"
          fontFamily={"sofiaPro"}
        >
          {dashboard?.plansData?.map((data, index) => {
            return (
              <Box elevation={3} key={index}>
                <CardComp
                  className={
                    "bg-[var(--c-bg-color)] min-w-[300px] pt-2 xl:pl-3 border-none shadow-md rounded-xl"
                  }
                >
                  <CardContent className="flex gap-6 ">
                    <Box
                      className={`rounded-lg`}
                      sx={{ height: 60, width: 60 }}
                    >
                      <CardMedia
                        component="img"
                        className={
                          "xl:p-4 lg:p-3 md:p-2 p-4 bg-[#007AFF1A] rounded-lg"
                        }
                        image={IHmoTwo}
                        alt="Paella dish"
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: 700 }}
                        variant="h1"
                        color="black"
                        gutterBottom
                        fontFamily={"sofiaProBold"}
                      >
                        {loading
                          ? "..."
                          : getAmount(data?.totalSubscriberCount)}
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.black"
                        fontFamily={"sofiaPro"}
                        className="capitalize"
                      >
                        {data?.plan?.name || "Plan Name"}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardComp>
              </Box>
            );
          })}
        </Box>
      ) : (
        <p className="ml-6 mt-0 pt-0">No record found</p>
      )}

      <Box className="mx-8">
        <Box className="flex justify-between mb-2 ">
          <Typography>Recent Subscribers</Typography>
          <Link to={routes.hmos.client} className="text-[var(--c-primary-2)]">
            See All
            <ArrowForwardIcon
              className="text-[var(--c-primary-2)]"
              fontSize="small"
            />
          </Link>
        </Box>

        <Table
          {...{
            data,
            pagination: {
              total: 10,
              length: 4,
              hidden: true,
            },
            isLoading: loading,
          }}
        />
      </Box>
    </HomeLayout>
  );
}
