/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/table/Table";
import {
  getAppointments,
  updateAppointmentStatus,
  getAppointmentsData,
  setPagination,
  openModal,
  closeModal,
} from "../../features/hospitals/appointmentSlice";
import HomeLayout from "../../layouts/Home";
import { Roles, formatDate, getIds, loggedInUser } from "../../utils/helper";
import Menu from "../../utils/data.dropdown";
import CancelModal from "../../components/Modals/Cancel";

const HospitalAppointment = () => {
  let user = {};
  if (loggedInUser) {
    user = JSON.parse(loggedInUser);
  }
  const dispatch = useDispatch(),
    [status, setStatus] = useState(null);
  const hospitalId =
    user?.role?.[0] === "hospitaladmin"
      ? getIds().hospital
      : user?.role?.[0] === "hospitalstaff"
      ? user?.hospitaluser?.[0]?.HospitalUser?.hospitalId
      : null;

  const { pagination, loading, appointments, modal } =
      useSelector(getAppointmentsData),
    handleSuccess = async (status, id) => {
      const res = await dispatch(
        updateAppointmentStatus({ appointmentId: id, status })
      )?.unwrap();

      if (res?.success === true) {
        dispatch(getAppointments(hospitalId));
      }
    };

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      cb = setTimeout(
        (_) => (async () => await dispatch(getAppointments(hospitalId)))(),
        700
      );
    } else {
      dispatch(getAppointments(hospitalId));
    }

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page]);

  let data = appointments?.map((appoint) => ({
    Name: appoint?.user?.firstName + " " + appoint?.user?.lastName,
    "Phone Number": appoint?.user?.phoneNumber,
    "Date Booked": formatDate(appoint?.createdAt),
    "Appointment Date": appoint?.time,
    Status: appoint?.status,
    _data: appoint,
  }));

  const menu = new Menu({});
  const dropdownMenu = [
    menu.completedAppointment({
      action: (value) => {
        dispatch(openModal("delete"));
        setStatus({ type: "completed", id: value.id });
      },
    }),
    menu.bookedAppointment({
      action: (value) => {
        dispatch(openModal("delete"));
        setStatus({ type: "booked", id: value.id });
      },
    }),
  ];

  return (
    <HomeLayout {...{ title: "Appointment" }}>
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Typography className="text-[400] text-xl  mb-5  text-[#040316]">
          Appointment
        </Typography>
        <Table
          {...{
            dropdownMenu:
              user?.role?.[0] === Roles.hospitalStaff ? dropdownMenu : null,
            data,
            pagination,
            setPagination: (data) => dispatch(setPagination(data())),
            isLoading: loading,
          }}
        />
      </Box>
      <CancelModal
        {...{
          open: modal.delete,
          loading: modal.loading,
          title: `Update Appointment Status`,
          subtitle:
            status?.type === "completed"
              ? `Has this patient been consulted?`
              : "Are you sure you want to re-open this appointment?",
          btnTexts: ["No", "Yes"],
          handleSuccess: (_) => handleSuccess(status?.type, status?.id),
          handleClose: (_) => dispatch(closeModal("delete")),
        }}
      />
    </HomeLayout>
  );
};

export default HospitalAppointment;
