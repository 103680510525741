import React, { useState } from 'react'
import TabControl from '../../../components/tabControl/TabControl'
import { useAuthContext } from '../../../context/AuthContext'
import HomeLayout from '../../../layouts/Home'
import ApiSettings from './ApiSettings'
import EmailConfiguration from './EmailConfiguration'

const Settings = () => {
  const [currentTabView, setCurrentTabView] = useState(<EmailConfiguration />),
  [current, setCurrent] = useState(null),
  { setClassName } = useAuthContext()

  const pages = [
		{
			id: 1,
			title: "Email Configuration",
			element: <EmailConfiguration />,
		},
		{
			id: 2,
			title: "API Settings",
			element: <ApiSettings />,
		},
	];


  const handleTabChange = (id) => {
		setCurrent(id);
		setCurrentTabView(
			pages.find((page) => {
				setClassName((_) => "md:w-[80%] lg:w-[85%] xl:w-[65%]");
				return page.id === id;
			}).element,
		);
	};
  return (
    <HomeLayout {...{ title: "Configuration" }}>
       <TabControl
				tabs={pages}
				setCurrent={handleTabChange}
				current={current}
				tabWrapperActiveClass=" border-b-4 shadow-none rounded-none mt-6 hover:shadow-none border-[#090F47]"
				tabWrapperClass="mt-6  shadow-none rounded-none hover:shadow-none " 
				wraperClass="grid lg:grid-cols-3  gap-14 my-8 border-b-2 border-[#B3B3B3]"
				tabClass="text-[400] text-[20px] text-[#040316]  "
			/>
      {currentTabView}
      </HomeLayout>
  )
}

export default Settings