import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HomeLayout from "../../../layouts/Home";
import AddIcon from "@mui/icons-material/Add";
import config from "../../../utils/config";
import Table from "../../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCompanyPlan,
  getCompanyData,
  getPlansByCompanyId,
  setPagination,
  openModal,
  closeModal,
} from "../../../features/company/companySlice";
import TablePreloader from "../../../components/Loader/TablePreloader";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../../../utils/data.dropdown";
import { getAmount, getIds } from "../../../utils/helper";
import CancelModal from "../../../components/Modals/Cancel";
import { IError } from "../../../utils/icons.utils";
import { capitalize } from "../../../utils/helper";

const { routes } = config;

const Plans = () => {
  const navigate = useNavigate(),
    { plans, loading, pagination, modal } = useSelector(getCompanyData),
    [cancelPlanPayload, setCancelPlanPayload] = useState({
      name: "",
      planId: "",
    }),
    dispatch = useDispatch();
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => await dispatch(getPlansByCompanyId(getIds().company)))();
  }, [pagination.page]);

  const data = plans?.map((plan) => ({
    "Plan Name": plan?.Plan?.name,
    "Unique Name": plan?.Plan?.uniqueName,
    "HMO Name": capitalize(plan?.Plan?.Hmo?.name),
    "Amount Per Plan": "₦" + getAmount(plan?.Plan?.amount),
    "Plan Duration": plan?.Plan?.duration,
    _data: plan,
  }));

  const menu = new Menu({});
  const dropdownMenu = [
      menu.remove({
        type: "Plan",
        action: (value) => {
          setCancelPlanPayload({
            planId: value.planId,
            name: value?.Plan?.name,
          });
          dispatch(openModal("delete"));
        },
      }),
      menu.viewCompanyPlanDetails({
        navigate,
        routes,
      }),
    ],
    handleSuccess = async () => {
      const res = await dispatch(
        removeCompanyPlan({
          planId: cancelPlanPayload.planId,
          companyId: getIds().company,
        })
      ).unwrap();
      res?.success &&
        (async () => await dispatch(getPlansByCompanyId(getIds().company)))();
    };

  return (
    <HomeLayout {...{ title: "Plans" }}>
      <Box className="flex justify-end gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <LoadingButton
          LinkComponent={Link}
          className="w-[167px] bg-[var(--c-primary-0)] mr-8 text-[600] text-[18px] rounded-xl shadow-none normal-case"
          size="medium"
          type="submit"
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          to={routes.companies.plans.new}
        >
          Add Plan
        </LoadingButton>
      </Box>
      <div className="w-full  mt-5">
        <Box
          className="pt-8 px-8 pb-2 rounded-lg bg-[var(--c-bg-color)] w-100%"
          sx={{ p: 0 }}
        >
          <Table
            {...{
              dropdownMenu,
              data,
              pagination,
              setPagination: (data) => dispatch(setPagination(data())),
              isLoading: loading,
            }}
          />
        </Box>
      </div>
      <CancelModal
        {...{
          open: modal.delete,
          loading: modal.loading,
          title: "Remove Plan",
          subtitle: `Are you sure you want to remove ${cancelPlanPayload?.name}?`,
          btnTexts: ["No", "Yes"],
          handleSuccess,
          handleClose: (_) => dispatch(closeModal("delete")),
        }}
      />
    </HomeLayout>
  );
};

export default Plans;
