import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import SearchBar from '../../components/SearchBar'
import HomeLayout from '../../layouts/Home'
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Table from '../../components/table/Table'
import { useEffect } from 'react'
import { getHospitalData, getHospitals, setPagination } from '../../features/hospitals/hospitalSlice'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import TablePreloader from '../../components/Loader/TablePreloader'
import { IError } from '../../utils/icons.utils'
import { capitalize } from '../../utils/helper'


const HmoHospitals = () => {


       
  const { hospitals, pagination, loading } =
	  useSelector(getHospitalData),
    dispatch = useDispatch(),
    [isLoading, setIsLoading] = useState(true);


  useEffect(()=>{
    setIsLoading(false)
  },[])

  useEffect(() => {
		let cb = ()=>{}
		
		if(pagination.search) {
			dispatch(setPagination({page: 1}))
			cb = setTimeout(_=> (async ()=> await dispatch(getHospitals()))(), 700)
		}else cb = (async () => await dispatch(getHospitals()))();

		return ()=>{
				clearTimeout(cb)
		}
}, [ pagination.search, pagination.page])

      

      let data = hospitals?.map((hospital) => ({
        "Hospital Name": capitalize(hospital?.name),
        email: hospital?.email,
        "Phone Number": hospital?.phoneNumber,
        "RC Number": hospital?.rcNumber,
        address: capitalize(hospital?.streetName),
        "Created Date": dayjs(hospital?.createdAt).format("MMM DD, YYYY"),
        _data: hospital,
      }));

  return (
    <HomeLayout
			{...{
				title: "Hospital",
				subtitle: "Overview of all information on the system.",
			}}>
                <Box className="ml-3 my-10 flex justify-between">
                <SearchBar  {... {
					 placeholder: "Search For Hospitals...",
					onChange: (e) => {
						const { value } = e.target;
						if(e.code === 'Enter') dispatch(getHospitals({search: value}))
					else dispatch(setPagination({search: value}))
					}
        }} />
            <LoadingButton
						className=" lg:w-[20.1%] w-full rounded-xl text-[12px] lg:text-[18px] bg-[#191489] normal-case"
						size="large"
						type="submit"
						variant="contained"
						fullWidth
                        startIcon={<CloudDownloadIcon />}
						// loading={loading}
                        >
						Export
					</LoadingButton>
        </Box>
        <Box className="pt-6 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%" sx={{ p: 0 }}>
        <Typography className='mb-3 text-[400] text-[20px]'>Hospitals</Typography>
        {isLoading  ? (
						<TablePreloader />
					) : data.length ? (
						<Table
							{...{
								// dropdownMenu,
								data,
								pagination,
								setPagination: d => dispatch(setPagination(d())),
								isLoading: loading,
							}}
						/>
					) : (
						<Box
						className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
						sx={{ p: 0 }}
					  >
						<img
						className="m-auto w-[350px] mt-8"
						src={IError}
						alt={"error"}
						loading="lazy"
					  />
					  <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
					  No Hospitals in the system at the moment
					  </Typography>
					  </Box>
					)}
          </Box>
      </HomeLayout>
  )
}

export default HmoHospitals