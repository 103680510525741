import React, { useEffect, useState } from "react";
import HomeLayout from "../../layouts/Home";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../components/table/Table";
import TablePreloader from "../../components/Loader/TablePreloader";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeData,
  setPagination,
  getEmployeeConsultations,
} from "../../features/company/employeeSlice";
import { formatDate, getAmount } from "../../utils/helper";
import { IError } from "../../utils/icons.utils";

const EmployeeConsultation = () => {
  const { consultations, pagination, loading, modal } =
      useSelector(getEmployeeData),
    { state } = useLocation(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () =>
      await dispatch(
        getEmployeeConsultations({
          companyId: state?.companyId,
          employeeId: state?.userId,
        })
      ))();
  }, [pagination.page]);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const data = consultations?.map((consultation) => ({
    Name:
      consultation?.patient?.firstName + " " + consultation?.patient?.lastName,
    "Employee ID": consultation?.patient?.companyuser?.[0]?.CompanyUser?.employeeId,
    "Hospital Name": consultation?.hospital?.name,
    "Visit Date": formatDate(consultation?.consultant?.createdAt),
    _data: consultation,
  }));

  return (
    <HomeLayout {...{ title: "Employee" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Typography className="text-[400] text-xl my-4 text-[#040316]">
          Employee History
        </Typography>
        {isLoading ? (
          <TablePreloader />
        ) : data?.length ? (
          <Table
            {...{
              // dropdownMenu,
              data,
              pagination,
              setPagination: (data) => dispatch(setPagination(data())),
              isLoading: loading,
            }}
          />
        ) : (
          <Box
          className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
          sx={{ p: 0 }}
        >
          <img
          className="m-auto w-[350px] mt-8"
          src={IError}
          alt={"error"}
          loading="lazy"
        />
        <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
        No Consultations in the system at the moment
        </Typography>
        </Box>
        )}
      </Box>
    </HomeLayout>
  );
};

export default EmployeeConsultation;
