import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getDesignations = createAsyncThunk('/hospital/getDesignation', ({search, hospitalId}, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().designation
  return new BACKEND().send({
   type: 'get',
   to: `/designations/${hospitalId}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${search || pagination.search || ''}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const createDesignation = createAsyncThunk('/hospital/createDesignation', (payload, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'post',
   to: `/designations`,
   useAlert: true,
   payload
 })
 } catch (error) {
   console.error(error);
 }
})


export const updateDesignation = createAsyncThunk('/hospital/updateDesignation', ({designationId, ...payload}, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'patch',
   to: `/designations/designation/${designationId}`,
   useAlert: true,
   payload
 })
 } catch (error) {
   console.error(error);
 }
})

export const deleteDesignation = createAsyncThunk('/hospital/deleteDesignation', (designationId, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'delete',
   to: `/designations/designation/${designationId}`,
   useAlert: true,
 })
 } catch (error) {
   console.error(error);
 }
})



const initialState = {
 loading: false,
 designations: [],
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 },
 model: {
  name: '',
  description: ''
 },
 modal: {
  delete: false,
  loading: false
 },
};

export const designationSlice = createSlice({
 name: 'designation',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  },
  
 closeModal: (state, {payload}) => {
  state.modal[payload] = false
},
openModal: (state, {payload}) => {
  state.modal[payload] = true
},
 },
 extraReducers: (builder => {
  
  builder.addCase(getDesignations.pending, (state) => {
   state.loading = true;
  })
  .addCase(getDesignations.fulfilled, (state, {payload}) => {
   state.loading = false;
   if(payload?.success) {
    state.designations = payload?.data?.designations
    state.pagination.total = payload?.data?.count || state.pagination.pageSize;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getDesignations.rejected, state => {
    state.loading = false
  })

    /** ADD AILMENT **/
    .addCase(createDesignation.pending, (state) => {
     state.loading = true;
   })
   .addCase(createDesignation.fulfilled, (state) => {
     state.loading = false;
   })
   .addCase(createDesignation.rejected, (state) => {
     state.loading = false;
   })

   /** DELETE AILMENT **/
   .addCase(deleteDesignation.pending, (state) => {
    state.modal.loading = true;
  })
  .addCase(deleteDesignation.fulfilled, (state) => {
    state.modal.loading = false;
    state.modal.delete = false
  })
  .addCase(deleteDesignation.rejected, (state) => {
    state.modal.loading = false;
  })

   
    /** UPDATE AILMENT **/
    .addCase(updateDesignation.pending, (state) => {
     state.loading = true;
   })
   .addCase(updateDesignation.fulfilled, (state) => {
     state.loading = false;
   })
   .addCase(updateDesignation.rejected, (state) => {
     state.loading = false;
   })
   
 })
})

export const { setPagination, closeModal, openModal } = designationSlice.actions
export const getDesignationData = state => state.designation
export default designationSlice.reducer