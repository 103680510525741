/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	FormControl,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import HomeLayout from "../../../../layouts/Home";
import SelectInput from "../../../../components/SelectInput";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { loggedInUser } from "../../../../utils/helper";
import { createConsultation, getPatientsData } from "../../../../features/hospitals/patientSlice";
import { vaddConsultation } from "../../../../utils/validators";
import { getAilment, getAilmentData } from "../../../../features/users/ailmentSlice";


const AddConsultation = ({ editable, stateModel, cb }) => {
 const user = loggedInUser ? JSON.parse(loggedInUser) : {}
 const { loading, model } = useSelector(getPatientsData)
	const navigate = useNavigate(),
	{ state } = useLocation(),
	{ ailments } = useSelector(getAilmentData),
		dispatch = useDispatch(),
  
		{
			values,
			handleBlur,
			handleChange,
			handleSubmit,
			errors,
			touched,
			handleFocus,
		} = useFormik({
			validationSchema: vaddConsultation,
			initialValues: stateModel || model,
			onSubmit: async (value) => {
				
    const payload = {...value, userId: user?.id, hospitalId: user?.hospitaluser?.[0]?.id, patientId: state?.User?.id, planId: state?.planId, hospitalStaff: user?.firstName +' '+ user?.lastName };
				const action = createConsultation;
				const res = await dispatch(action(payload)).unwrap();
				res?.success === true && navigate(-1)
			},
		});

useEffect(()=> {
 (async () => await dispatch(getAilment()))()
}, [])

	return (
		<HomeLayout {...{ title: "Consultation" }}>
			<Box className="flex  mt-2 text-[var(--c-dark-1)]">
				<ArrowBackIcon
					onClick={(_) => navigate(-1)}
					className=" mr-2 cursor-pointer"
					fontSize="small"
				/>
				<Typography className="text-[400] text-[16px] ">Go back</Typography>
			</Box>
			<Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
				<Typography className="text-[400] text-xl mt-8 text-[#040316]">
					{editable ? "Edit" : "New"} consultation
				</Typography>
			</Box>
			<Box
				className="py-8 mt-8 bg-[var(--c-bg-color)] w-full rounded-lg"  onSubmit={handleSubmit}
				sx={{ p: 0 }} component={'form'} noValidate>
     	{/* <MultipleSelect
					{...{
						className: "w-[90%] mx-auto",
						options: values.prescription || [],
						value: values.prescription || [],
						title: "Drugs",
						name: "prescription",
						required: true,
						handleBlur,
						handleFocus,
						handleChange,
						error: errors.prescription && touched.prescription,
						helperText: errors.prescription,
					}}
				/> */}

<FormControl className="flex flex-row justify-center w-full mx-auto border-none">
						<TextField
							className=" bg-white w-[90%]"
							id="prescription"
							label="Drug Prescription"
							name="prescription"
							value={values.prescription}
							required
							onFocus={handleFocus}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.prescription && touched.prescription}
							helperText={errors.prescription && touched.prescription ? errors.prescription : ""}
						/>
					</FormControl>

				<Box className="flex flex-row items-center justify-center w-[90%] mt-5 mx-auto">
    {/* <MultipleSelect
					{...{
						className: "w-[90%]",
						options: ailments?.map(({name}) => name) || [],
						value: values.ailment || [],
						title: "Ailments *",
						name: "ailment",
						required: true,
						handleBlur,
						handleFocus,
						handleChange,
						error: errors.ailment && touched.ailment,
						helperText: errors.ailment && touched.ailment ? errors.ailment : '',
					}}
				/> */}
    <FormControl className="w-full border-none lg:mt-5">
					<SelectInput
						{...{
							className: "w-[90%] bg-white ",
							value: values.ailment,
							name: "ailment",
							label: "Ailment",
							handleChange,
							handleBlur,
							handleFocus,
							error: errors.ailment && touched.ailment,
							helperText: errors.ailment,
							options: ailments?.map(({name}) => name) || [],
						}}
					/>
					</FormControl>

			<FormControl className="w-full border-none ml-5">
						<TextField
							className=" bg-white "
							id="amount"
							label="Amount"
							name="amount"
							value={values.amount}
       type="number"
							required
							onFocus={handleFocus}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.amount && touched.amount}
							helperText={errors.amount && touched.amount ? errors.amount : ""}
						/>
					</FormControl>
				</Box>


				<FormControl className="w-full border-none lg:mt-8">
					<TextField
						className="w-[90%] m-auto bg-white "
						id="description"
						label="Description"
						name="description"
						multiline
						rows={5}
						value={values.description}
						required
						onFocus={handleFocus}
						onChange={handleChange}
						onBlur={handleBlur}
						error={errors.description && touched.description}
						helperText={
							errors.description && touched.description
								? errors.description
								: ""
						}
					/>
				</FormControl>
				<Box className="w-[95%] flex justify-end">
				<Box className='flex'>
    <Button
						className="my-10 mr-4 normal-case w-full rounded-xl text-[12px] lg:text-[18px] bg-[#FFFFFF] text-[var(--c-primary-0)] shadow-none"
						size="large"
						type="reset"
						variant="contained"
						fullWidth
						onClick={_=> navigate(-1)}>
						Cancel
					</Button>
					<LoadingButton
						className={`my-10 w-full rounded-xl normal-case text-[12px] lg:text-[18px] bg-[var(--c-primary-0)] shadow-none  ${!loading && 'disabled:text-white disabled:bg-gray-700'} `}
						size="large"
						type="submit"
						variant="contained"
						fullWidth
      disabled={!values.ailment.length || loading}
						loading={loading}>
						{editable ? "Update" : "Save"}
					</LoadingButton>
    </Box>
				</Box>
			</Box>
		</HomeLayout>
	);
};

export default AddConsultation;
