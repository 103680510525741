/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import HomeLayout from "../../layouts/Home";
import config from "../../utils/config";
import AddIcon from "@mui/icons-material/Add";
import Table from "../../components/table/Table";
import {
  addEmployeePlan,
  cancelEmployeeHmoPlan,
  closeModal,
  deleteEmployee,
  getEmployeeData,
  getEmployees,
  openModal,
  setPagination,
  setPayload,
} from "../../features/company/employeeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyData,
  getPlansByCompanyId,
} from "../../features/company/companySlice";
import Menu from "../../utils/data.dropdown";
import CancelModal from "../../components/Modals/Cancel";
import AddPlanModal from "../../components/Modals/AddPlan";
import { capitalize } from "../../utils/helper";
import { getAmount, getIds } from "../../utils/helper";

const { routes } = config;
const Employees = () => {
  const { employees, pagination, loading, modal, model } =
      useSelector(getEmployeeData),
    { plans } = useSelector(getCompanyData),
    [name, setName] = useState("this user"),
    [cancelPlanPayload, setCancelPlanPayload] = useState(""),
    navigate = useNavigate(),
    dispatch = useDispatch();

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) => (async () => await dispatch(getEmployees(getIds().company)))(),
        700
      );
    } else cb = (async () => await dispatch(getEmployees(getIds().company)))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page]);

  let data = employees?.map((employee) => ({
    Name: employee?.User?.firstName + " " + employee?.User?.lastName,
    "Employee ID": employee?.employeeId || "N/A",
    Email: employee?.User?.email || "N/A",
    "Phone Number": employee?.User?.phoneNumber || "N/A",
    "H.M.O Plan": capitalize(employee?.companyuserplans?.name) || "N/A",
    status: employee?.active ? "Active" : "Inactive",
    _data: employee,
  }));

  const menu = new Menu({});
  const dropdownMenu = [
    menu.view({
      navigate,
      route: routes.companies.employees.employee,
    }),
    menu.viewEmployeeConsultations({
      navigate,
      routes,
    }),
    menu.edit({
      navigate,
      route: routes.companies.employees.employee,
    }),
    menu.cancelPlan({
      action: (value) => {
        setName(`${value?.User?.firstName}  ${value?.User?.lastName}`);

        setCancelPlanPayload({
          employeeUserId: value.userId,
          planId: value.planId,
          companyId: value?.companyId,
        });
        dispatch(openModal("cancelPlan"));
      },
      route: routes.companies.employees.employee,
    }),
    menu.addEmployeePlan({
      action: (value) => {
        setName(`${value?.User?.firstName}  ${value?.User?.lastName}`);

        setCancelPlanPayload({
          employeeUserId: value.userId,
          companyId: value?.companyId,
        });
        dispatch(openModal("addPlan"));
      },
    }),
    menu.delete({
      action: (value) => {
        setCancelPlanPayload({
          employeeId: value.userId,
          companyId: value?.companyId,
        });

        setName(`${value?.User?.firstName}  ${value?.User?.lastName}`);
        dispatch(openModal("delete"));
      },
    }),
  ];
  const cancelHmoPlan = () => {
    dispatch(cancelEmployeeHmoPlan(cancelPlanPayload));
  };

  const handleDelete = async () => {
    const res = await dispatch(deleteEmployee(cancelPlanPayload)).unwrap();
    res?.success === true && dispatch(getEmployees(getIds().company));
  };

  const addHmoPlan = async () => {
    const plan = plans?.find(({ Plan, planId }) => {
      return model.hmoPlan.includes(Plan.name) ? planId : "";
    });

    const res = await dispatch(
      addEmployeePlan({ ...cancelPlanPayload, planId: plan.planId })
    ).unwrap();
    res?.success && dispatch(getEmployees(cancelPlanPayload.companyId));
  };

  useEffect(() => {
    dispatch(getPlansByCompanyId(getIds().company));
  }, []);

  return (
    <HomeLayout {...{ title: "Employee" }}>
      <Box className="flex justify-between flex-col gap-6 sm:flex-col lg:flex-col xl:flex-row">
        <SearchBar
          {...{
            placeholder: "Search by Employee Name...",
            onChange: (e) => {
              const { value } = e.target;
              if (e.code === "Enter") dispatch(getEmployees(value));
              else dispatch(setPagination({ search: value }));
            },
          }}
        />
        <Box className="flex justify-between gap-4 sm:flex-col lg:flex-row flex-col">
          <LoadingButton
            LinkComponent={Link}
            className="w-[220px] h-[56px] bg-[var(--c-gray-4)] text-[var(--c-primary-0)] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            to={routes.companies.employees.upload}
          >
            Upload Employees
          </LoadingButton>
          <LoadingButton
            LinkComponent={Link}
            className="w-[213px] h-[56px] bg-[var(--c-primary-0)] text-[600] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            to={routes.companies.employees.new}
          >
            Add Employee
          </LoadingButton>
        </Box>
      </Box>
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Table
          {...{
            dropdownMenu,
            data,
            pagination,
            setPagination: (d) => dispatch(setPagination(d())),
            isLoading: loading,
          }}
        />

        <CancelModal
          {...{
            open: modal.cancelPlan,
            loading: modal.loading,
            title: "Cancel Plan",
            subtitle: `Are you sure you want to cancel ${name}'s plan?`,
            btnTexts: ["No", "Yes"],
            handleSuccess: cancelHmoPlan,
            handleClose: (_) => dispatch(closeModal("cancelPlan")),
          }}
        />
        <CancelModal
          {...{
            open: modal.delete,
            loading: modal.loading,
            title: "Delete Employee",
            subtitle: `Are you sure you want to delete ${name}?`,
            btnTexts: ["Cancel", "Delete"],
            handleSuccess: handleDelete,
            handleClose: (_) => dispatch(closeModal("delete")),
          }}
        />
        <AddPlanModal
          {...{
            open: modal.addPlan,
            loading: modal.loading,
            title: "Add Plan",
            value: model.hmoPlan,
            setValue: (val) => dispatch(setPayload({ hmoPlan: val })),
            options: plans?.map(({ Plan, planId }) => {
              return Plan.name + " - ₦" + getAmount(Plan.amount);
            }),
            subtitle: `Please select the H.M.O plan to be added `,
            btnTexts: ["Cancel", "Add Plan"],
            handleSuccess: addHmoPlan,
            handleClose: (_) => dispatch(closeModal("addPlan")),
          }}
        />
      </Box>
    </HomeLayout>
  );
};

export default Employees;
