import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HomeSidebar from "../components/Sidebars/Home";
import { useLocation } from "react-router-dom";

const HomeLayout = ({ children, title, subtitle }) => {
	const { pathname } = useLocation();
	if (pathname !== "/") document.body.style.background = "#fff";

	return <HomeSidebar {...{ children, title, subtitle }}/>
};

export default HomeLayout;
