import React from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  Typography,
  FormControl,
  Link,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { userLogin } from "../../utils/validators";
import { useFormik } from "formik";
import PasswordInput from "../PasswordInput";
import { useDispatch } from "react-redux";
import { asyncLogin, setCurrentScreen } from "../../features/auth/authSlice";
import config from "../../utils/config";
import { Roles } from "../../utils/helper";
import Alert from "../../utils/alert";

const Login = () => {
  console.log(Object.values(Roles));
  const dispatch = useDispatch(),
    changeScreen = (screen) => dispatch(setCurrentScreen(screen)),
    model = {
      email: "",
      password: "",
    },
    {
      values,
      isSubmitting,
      handleBlur,
      handleChange,
      handleSubmit,
      errors,
      touched,
      handleFocus,
    } = useFormik({
      validationSchema: userLogin,
      initialValues: model,
      onSubmit: async (value) => {
        try {
          const { companyStaff, ...roles } = Roles;
          const res = await dispatch(asyncLogin(value)).unwrap(),
            user = res?.data?.user;
          // Restrict role access
          if (res?.success && !user?.newUser) {
            if (Object.values(roles).includes(user?.role?.[0])) {
              let redirectUrl;
              if (user?.role?.[0] === Roles.hospitalStaff)
                redirectUrl = config.routes.hospitals.patients.patient;
              else redirectUrl = config.routes.dashboard;

              // takes us to the corresponding page
              window.location.href = redirectUrl;
            } else
              Alert({
                type: "error",
                message:
                  "You do not have access to this portal. Please download and use our mobile app. Thank you.",
              });
          }
        } catch (error) {
          console.log(error);
        }
      },
    });

  return (
    <Box className="pt-24">
      <Typography component={"h4"} variant={"h4"} className="font-bold ">
        Sign In
      </Typography>
      <Typography className="text-lg" fontSize="24px">
        Enter your login details.
      </Typography>

      <Box component="form" marginTop="2em" noValidate onSubmit={handleSubmit}>
        <FormControl required fullWidth>
          <TextField
            className="w-full bg-white"
            id="email"
            label="Email Address"
            name="email"
            value={values.email}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email ? errors.email : ""}
            type="email"
          />
        </FormControl>

        <PasswordInput
          {...{
            value: values.password,
            label: "Password",
            error: errors.password && touched.password,
            helperText:
              errors.password && touched.password ? errors.password : "",
            name: "password",
            onChange: handleChange,
            onBlur: handleBlur,
            required: true,
          }}
        />

        <Box display="flex" justifyContent="flex-end">
          <Link
            className="no-underline cursor-pointer mt-2"
            onClick={(_) => changeScreen("forgotPassword")}
          >
            Forgot Password?
          </Link>
        </Box>

        <LoadingButton
          className="my-6 rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] "
          sx={{
            ".MuiLoadingButton-loadingIndicatorCenter": {
              color: "var(--c-bg-color) !important",
            },
          }}
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          loading={isSubmitting}
        >
          Sign In
        </LoadingButton>

        <Divider> OR </Divider>

        <Typography
          component="p"
          textAlign="center"
          fontSize="20px"
          marginTop="1em"
        >
          Don't have an account?{" "}
          <Link
            className="no-underline cursor-pointer"
            onClick={(_) => changeScreen("home-page")}
          >
            Sign Up
          </Link>{" "}
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
