import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const addStaff = createAsyncThunk('/staff/addStaff', (payload, thunk) => {
 try {
  if(thunk.getState().staff.profileImage) payload.profileImage = thunk.getState().staff.profileImage
  return new BACKEND().send({
   type: 'post',
   to: '/hospitals/add-employee',
   useAlert: true,
   payload
 })
 } catch (error) {
  console.log(error);
 }
})

export const updateStaff = createAsyncThunk('/staff/updateStaff', ({hospitalId, employeeId, ...payload}, thunk) => {
  try {
    if(thunk.getState().staff.profileImage) payload.profileImage = thunk.getState().staff.profileImage
   return new BACKEND().send({
    type: 'patch',
    to: `/hospitals/employees/${hospitalId}/${employeeId}`,
    useAlert: true,
    payload
  })
  } catch (error) {
   console.log(error);
  }
 })

export const getStaff = createAsyncThunk('/staff/getStaff', (hospitalId, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().staff
  return new BACKEND().send({
   type: 'get',
   to: `/hospitals/get-employees/${hospitalId}/?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${pagination.search}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const deleteStaff = createAsyncThunk('/staff/deleteStaff', ({hospitalId, employeeId}) => {
  try {
   
   return new BACKEND().send({
    type: 'delete',
    to: `/hospitals/employees/${hospitalId}/${employeeId}`,
    useAlert: true
  })
  } catch (error) {
   console.log(error);
  }
 })

const initialState = {
 staffs: [],
 model: {
  hospitalId: "",
  employeeFirstName: "",
  employeeLastName: "",
  employeeEmail: "",
  employeePhoneNumber: "",
  employeeId: "098",
  state: "",
  city: "",
  street: "",
  specialization: "",
  designation: "",
  profileImage: '',
  gender: ''
 },
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ""
 },
 loading: false,
 modal: {
  delete: false,
  loading: false
 },
}

export const staffSlice = createSlice({
 name: 'staff',
 initialState,
 reducers: {
  setPhoto: (state, {payload}) => {
    console.log(payload);
   state.model.profileImage = payload
 },
 setPagination: (state, { payload }) => {
  state.pagination = {...state.pagination, ...payload}
 },
 closeModal: (state, {payload}) => {
  state.modal[payload] = false
},
openModal: (state, {payload}) => {
  state.modal[payload] = true
},
 },
 extraReducers: (builder => {
  builder.addCase(getStaff.pending, (state) => {
   state.loading = true;
  })
  .addCase(getStaff.fulfilled, (state, { payload }) => {
   state.loading = false;
   if(payload?.success) {
    state.staffs = payload?.data?.employees
    state.pagination.total = payload?.data.count;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getStaff.rejected, state => state.loading = false)

  /** UPDATE STAFF **/ 
  .addCase(updateStaff.pending, (state) => {
    state.loading = true;
  })
  .addCase(updateStaff.fulfilled, (state) => {
    state.loading = false;
  })
  .addCase(updateStaff.rejected, (state) => {
    state.loading = false;
  })
  /** ADD STAFF **/
  .addCase(addStaff.pending, (state) => {
      state.loading = true;
    })
    .addCase(addStaff.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(addStaff.rejected, (state) => {
      state.loading = false;
    })
    /** DELETE STAFF **/ 
  .addCase(deleteStaff.pending, (state) => {
    state.modal.loading = true;
  })
  .addCase(deleteStaff.fulfilled, (state) => {
    state.modal.loading = false;
    state.modal.delete = false;
  })
  .addCase(deleteStaff.rejected, (state) => {
    state.modal.loading = false;
  })
 })

})

export const {setPagination, setPhoto, openModal, closeModal} = staffSlice.actions;

export const getStaffData = state => state.staff;

export default staffSlice.reducer