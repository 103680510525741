import { Box, CardContent, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import CardComp from '../../../components/CardComp'
import ControlledSwitch from '../../../components/ControlledSwitch'

const EmailConfiguration = () => {
  return (
    <div>
         <Box
        className="p-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
        sx={{ p: 0 }}
      >
        <CardComp
          className={
            "bg-[#FFFFFF] my-4 w-[95%] h-[550px]  md:p-2 border-none rounded-lg shadow-none m-auto"
          }
        >
          <CardContent className="flex flex-col mt-10">
					
                    <FormControl className="md:w-[75%] w-[100%] border-none mb-8  m-auto">
						<TextField
							className="w-full bg-white "
							id="hostName"
							label="Host Name"
							name="hostName"
							// value={values.name}
							// onFocus={handleFocus}
							// onChange={handleChange}
							// onBlur={handleBlur}
							// error={errors.name && touched.name}
							// helperText={
							// 	errors.name && touched.name
							// 		? errors.name
							// 		: ""
							// }
						/>
                        </FormControl>
                        <FormControl className="md:w-[75%] w-[100%] border-none mb-8  m-auto">
                        <TextField
							className="w-full bg-white "
							id="port"
							label="Port"
							name="port"
							// value={values.name}
							// onFocus={handleFocus}
							// onChange={handleChange}
							// onBlur={handleBlur}
							// error={errors.name && touched.name}
							// helperText={
							// 	errors.name && touched.name
							// 		? errors.name
							// 		: ""
							// }
						/>
                        </FormControl>
                        <FormControl className="md:w-[75%] w-[100%]border-none mb-8  m-auto">
                        <TextField
							className="w-full bg-white "
							id="defaultSenderID"
							label="Default sender’s ID"
							name="defaultSenderID"
							// value={values.name}
							// onFocus={handleFocus}
							// onChange={handleChange}
							// onBlur={handleBlur}
							// error={errors.name && touched.name}
							// helperText={
							// 	errors.name && touched.name
							// 		? errors.name
							// 		: ""
							// }
						/>
                        </FormControl>
                        <FormControl className="md:w-[75%] w-[100%] border-none mb-8  m-auto">
                        <TextField
							className="w-full bg-white "
							id="securityMode"
							label="Security Mode"
							name="securityMode"
							// value={values.name}
							// onFocus={handleFocus}
							// onChange={handleChange}
							// onBlur={handleBlur}
							// error={errors.name && touched.name}
							// helperText={
							// 	errors.name && touched.name
							// 		? errors.name
							// 		: ""
							// }
						/>
                        </FormControl>

                        <Box className='flex justify-between md:w-[75%] w-[100%] m-auto'>
                            <Typography className='text-[600] text-[12px] text-[#000000] mt-3'>
                            Authentication Requrired
                            </Typography>
                            <ControlledSwitch color="default"/>
                        </Box>
          </CardContent>
        </CardComp>
      </Box>
    </div>
  )
}

export default EmailConfiguration