/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	FormControl,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import HomeLayout from "../../../layouts/Home";
import SelectInput from "../../../components/SelectInput";
import { LoadingButton } from "@mui/lab";
import MultipleSelect from "../../../components/Select";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
	getHmoData,
	getHmos,
	getPlansByHmoId,
	setPagination,
	updatePlan,
} from "../../../features/hmo/hmoSlice";
import { getAmount, getIds } from "../../../utils/helper";
import { useEffect } from "react";
import { addPlan, getCompanyData } from "../../../features/company/companySlice";

const AddPlan = ({ editable, stateModel, cb }) => {
	const navigate = useNavigate(),
		{ hmos, plans, pagination, loading: hmoLoading } = useSelector(getHmoData),
  { loading } = useSelector(getCompanyData),
		dispatch = useDispatch(),
		{
			values,
			handleBlur,
			handleChange,
			handleSubmit,
			errors,
   setValues,
			touched,
			handleFocus,
		} = useFormik({
			// validationSchema: createHMOPlan,
			initialValues: stateModel || {planName: '', name: '', services: '', amount: '', description: ''},
			onSubmit: async (value) => {
				const payload = editable ? value : {companyId: getIds()?.company, planId: getPlanByName(value.planName)?.id}
				const action = editable ? updatePlan : addPlan;
				const res = await dispatch(action(payload)).unwrap();
				res?.success === true && navigate(-1)
			},
		});

	useEffect(()=>{
		 dispatch(getHmos())
	}, [pagination.search])
	
 useEffect(()=> {
		if(values.name) dispatch(getPlansByHmoId(getHmoByName(values?.name)?.id || hmos?.[0]?.id));
 setValues({...values, services: '', amount: '', description: '', planName: ''})
}, [values.name])

useEffect(()=> {
 if(values.planName) setValues({...values, services: getPlanByName(values?.planName)?.services, amount: '₦ ' + getAmount(getPlanByName(values?.planName)?.amount || 0), description: getPlanByName(values?.planName)?.description})
}, [values.planName])

 const hmoNames= hmos?.map(hmo => hmo?.name),

 planNames = plans?.map(plan => plan?.name+ ' - '+ plan?.duration),

 getHmoByName = name => hmos?.find(hmo => hmo?.name === name),

 getPlanByName = name => plans?.find(plan => name?.includes(plan?.name) === true),
	handleSearch = (e) => {
		e.stopPropagation()
		const {target: {value}} = e;
		let cb = null;
		if (value) cb = setTimeout(() => dispatch(setPagination({search: value})), 10)
		else if(cb) {
			dispatch(getHmos())
			clearTimeout(cb)
		} else dispatch(getHmos())
	}

	return (
		<HomeLayout {...{ title: "Plans" }}>
			<Box className="flex  mt-2 text-[var(--c-dark-1)]">
				<ArrowBackIcon
					onClick={(_) => navigate(-1)}
					className=" mr-2 cursor-pointer"
					fontSize="small"
				/>
				<Typography className="text-[400] text-[16px] ">Go back</Typography>
			</Box>
			<Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
				<Typography className="text-[400] text-xl mt-8 text-[#040316]">
					{editable ? "Edit" : "New"} Plan
				</Typography>
			</Box>
			<Box
				className="py-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"  onSubmit={handleSubmit}
				sx={{ p: 0 }} component={'form'}>
				<Box className="flex flex-row w-[90%] m-auto">
					<FormControl className="w-full border-none lg:mt-5">
					<SelectInput
						{...{
							className: "w-[90%] bg-white ",
							value: values.name,
							name: "name",
							label: "H.M.O Name",
							handleChange,
							handleBlur,
							handleFocus,
							loading: !pagination.search && hmoLoading,
						searchLoading: pagination.search && hmoLoading,
							handleSearch,
							error: errors.name && touched.name,
							helperText: errors.name,
							options: hmoNames,
						}}
					/>
					</FormControl>
					<SelectInput
						{...{
							className: "lg:mt-5 w-[90%] bg-white ",
							value: values.planName,
							name: "planName",
							label: "Plan Name",
							handleChange,
							handleBlur,
							handleFocus,
							error: errors.planName && touched.planName,
							helperText: errors.planName,
							options: planNames,
						}}
					/>
				</Box>
				<MultipleSelect
					{...{
						className: "w-[90%] mx-auto lg:mt-8",
						options: values.services || [],
      readOnly: true,
						value: values.services || [],
						title: "Services",
						name: "services",
						required: true,
						handleBlur,
						handleFocus,
						handleChange,
						error: errors.services && touched.services,
						helperText: errors.services,
					}}
				/>

				<Box className="flex flex-row w-[90%] m-auto">
					<FormControl className="w-full border-none lg:mt-8">
						<TextField
							className=" bg-white"
							id="amount"
							label="Plan  Amount"
							name="amount"
							value={values.amount}
       inputProps={{readOnly: true}}
							required
							onFocus={handleFocus}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.amount && touched.amount}
							helperText={errors.amount && touched.amount ? errors.amount : ""}
						/>
					</FormControl>
				</Box>
				<FormControl className="w-full border-none lg:mt-8">
					<TextField
						className="w-[90%] m-auto bg-white"
						id="description"
						label="Plan Description"
						name="description"
						multiline
						rows={5}
						value={values.description}
      inputProps={{readOnly: true}}
						required
						onFocus={handleFocus}
						onChange={handleChange}
						onBlur={handleBlur}
						error={errors.description && touched.description}
						helperText={
							errors.description && touched.description
								? errors.description
								: ""
						}
					/>
				</FormControl>
				<Box className="flex justify-end w-[90%] m-auto">
				<Box className='flex'>
    <Button
						className="my-10 mr-4 w-full rounded-xl text-[12px] lg:text-[18px] bg-[#FFFFFF] text-[var(--c-primary-0)] shadow-none normal-case"
						size="large"
						type="reset"
						variant="contained"
						fullWidth
						onClick={_=> navigate(-1)}>
						Cancel
					</Button>
					<LoadingButton
						className={`my-10 w-full rounded-xl normal-case text-[12px] lg:text-[18px] bg-[var(--c-primary-0)] shadow-none  ${!loading && 'disabled:text-white disabled:bg-gray-700'} `}
						size="large"
						type="submit"
						variant="contained"
						fullWidth
      disabled={!values.name || !values.planName}
						loading={loading}>
						{editable ? "Update" : "Add"}
					</LoadingButton>
    </Box>
				</Box>
			</Box>
		</HomeLayout>
	);
};

export default AddPlan;
