import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth/authSlice";
import hospital from "./hospitals/hospitalSlice";
import hmo from "./hmo/hmoSlice";
import company from "./company/companySlice";
import employee from "./company/employeeSlice";
import hmoEmployee from "./hmo/employeeSlice";
import staff from "./hospitals/staffSlice";
import appointment from "./hospitals/appointmentSlice";
import user from "./users/userSlice";
import role from "./users/roleSlice";
import consultation from "../features/hospitals/consultationSlice";
import ailment from "../features/users/ailmentSlice";
import patient from "../features/hospitals/patientSlice";
import grade from "../features/users/gradeSlice";
import designation from "../features/hospitals/designation";

export const store = configureStore({
  reducer: {
    auth,
    hospital,
    hmo,
    company,
    employee,
    staff,
    user,
    ailment,
    appointment,
    role,
    consultation,
    patient,
    grade,
    designation,
    hmoEmployee,
  },
});
