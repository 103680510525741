import { LoadingButton } from '@mui/lab'
import { Box, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import SearchBar from '../../../components/SearchBar'
import HomeLayout from '../../../layouts/Home'
import AddIcon from "@mui/icons-material/Add";
import config from "../../../utils/config";
import Table from '../../../components/table/Table'
import { useDispatch, useSelector } from 'react-redux'
import { setPagination } from '../../../features/company/companySlice'
import TablePreloader from '../../../components/Loader/TablePreloader'
import { Link, useNavigate } from "react-router-dom";
import Menu from '../../../utils/data.dropdown'
import { useState } from 'react'
import { closeModal, deleteRole, getRoleData, getRoles, openModal } from '../../../features/users/roleSlice'
import CancelModal from '../../../components/Modals/Cancel'
import { IError } from '../../../utils/icons.utils'


const { routes } = config;

const Roles = () => {

  const { roles, loading, pagination, modal } = useSelector(getRoleData),
  dispatch = useDispatch(),
  navigate = useNavigate();
  let [isLoading, setIsLoading] = useState(true),
  [deleteData, setDeleteData] = useState({name: '', id: ''}),
  handleDeleteStaff =  () => {
    dispatch(deleteRole(deleteData.id)).unwrap().then(res => console.log(res))
  }

  useEffect(()=>{
    setIsLoading(false)
  }, [])
  
  useEffect(() => {
    (async ()=> await dispatch(getRoles()))()
  }, [pagination.page])

  useEffect(() => {
		let cb = setTimeout(_=> (async ()=>await dispatch(getRoles()))(), 700)

		return ()=>{
				clearTimeout(cb)
		}
}, [pagination.search])


  let data = roles?.map( role => ({
      "Name": role?.name,
      // address: company?.streetName,
      // "Phone Number": company?.phoneNumber,
      // email: company?.email,
      // status: company?.user?.verified ? "Active" : "Inactive",
      _data: role
    }) )
  
  const menu = new Menu({})
  const dropdownMenu = [
    menu.edit({
			navigate,
			route: routes.companies.company,
		}),
    menu.delete({action: (role) => {
      setDeleteData(state=> ({name: role?.name, id: role?.id}))
      dispatch(openModal('delete'))
}}),
  ]

  return (
    <HomeLayout {...{ title: "Role Management" }}>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
      <SearchBar {... {
        placeholder: 'Search by role name',
          onChange: (e) => {
            const { value } = e.target;
            if(e.code === 'Enter') dispatch(getRoles(value))
           else dispatch(setPagination({search: value}))
          }
        }} />
          <Button
            LinkComponent={Link}
            className="w-[213px] h-[56px] bg-[var(--c-primary-0)] mr-8 text-[600] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            to={routes.configurations.roleManagement.new}
          >
            
            Add Role
          </Button>
        </Box>
        <div className="w-full  mt-5">
        <Box className="pt-8 px-8 pb-2 rounded-lg bg-[var(--c-bg-color)] w-100%" sx={{ p: 0 }}>
        {
            isLoading ? <TablePreloader />
            :
            data?.length ?
            <Table
            {...{
              dropdownMenu,
              data,
              pagination,
              setPagination: data => dispatch(setPagination(data())),
              isLoading: loading
            }}
          />
          :
          <Box
          className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
          sx={{ p: 0 }}
        >
          <img
          className="m-auto w-[350px] mt-8"
          src={IError}
          alt={"error"}
          loading="lazy"
        />
        <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
        No role in the system at the moment
        </Typography>
        </Box>
          }
        </Box>
      </div>
      <CancelModal
         {...{
           open: modal.delete,
           loading: modal.loading,
            title: 'Delete Role',
            subtitle: `Are you sure you want to delete ${deleteData.name}?`,
            btnTexts: ['Cancel', 'Delete'],
            handleSuccess: handleDeleteStaff,
            handleClose: _=> dispatch(closeModal('delete'))
         }}
         />
    </HomeLayout>
  )
}

export default Roles