import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../../../../components/table/Table";
import {
  getEmployeeData,
  getHmoIndividualTransactionHistory,
  setPagination,
} from "../../../../features/company/employeeSlice";
import { capitalize, getAmount, getIds } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";

const Individuals = () => {
  const { loading, pagination, hmoIndividualTransactionHistory } =
      useSelector(getEmployeeData),
    dispatch = useDispatch();

  useEffect(() => {
    (async () =>
      await dispatch(getHmoIndividualTransactionHistory(getIds().hmo)))();
  }, [pagination.page]);

  const data = hmoIndividualTransactionHistory?.map((data) => ({
    // {
    //   id: 'uruririir',
    //   payeeId: 'jdjjfjddkkd',
    //   payerId: 'jfjfkfkkf',
    //   planId: 'jfjfkfkf',
    //   purpose: 'payment for a plan',
    //   amount: "7000",
    //   details: "Paid for the plan of 5 people",
    //   referenceNumber: "94040333",
    //   paymentReference: "jfjfjjfjdk-9303300"
    // },
    Name: capitalize(data?.plan?.Hmo.name),
    "H.M.O Name": capitalize(data?.plan?.Hmo.name),
    "Payment method": capitalize(data?.plan?.Hmo?.phoneNumber),
    Amount: "₦" + getAmount(data?.amount),
    "Subscription Type": capitalize(data?.plan?.Hmo?.phoneNumber),
    "Start Date": capitalize(data?.plan?.Hmo?.phoneNumber),
    "End Date": capitalize(data?.plan?.Hmo?.phoneNumber),
    _data: data,
  }));

  return (
    <Box
      className="pt-8 px-8 mt-8 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
      sx={{ p: 0 }}
    >
      <Table
        {...{
          data,
          pagination,
          setPagination: (data) => dispatch(setPagination(data())),
          isLoading: loading,
        }}
      />
    </Box>
  );
};

export default Individuals;
