/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import config from "./config";
import { Roles } from "./helper";
import {
  IConfiguration,
  IDashboard,
  IHmo,
  IHospital,
  ITransaction,
  IAppointment,
  IClient,
  IPlans,
  IBilling,
  ICompanyIcon,
} from "./icons.utils";
const { superAdmin, hmoAdmin, companyAdmin, hospitalAdmin, hospitalStaff } =
  Roles;

const { routes } = config;

const links = [
  {
    authorizedUsers: [superAdmin, hmoAdmin, hospitalAdmin, companyAdmin],
    name: "Dashboard",
    url: routes.dashboard,
    icon: <img src={IDashboard} width={"20"} className="mx-3" />,
  },
  {
    authorizedUsers: [companyAdmin],
    name: "Plans",
    url: routes.companies.plans.plan,
    icon: <img src={IPlans} width={"22px"} className="mx-3" />,
  },
  {
    authorizedUsers: [companyAdmin],
    name: "Employees",
    url: routes.companies.employees.employee,
    icon: <img src={IClient} width={"16px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hospitalStaff],
    name: "Patients",
    url: routes.hospitals.patients.patient,
    icon: <img src={IClient} width={"16px"} className="mx-3" />,
  },
  {
    authorizedUsers: [superAdmin],
    name: "Hospitals",
    url: routes.hospitals.hospital,
    icon: <img src={IHospital} width={"21px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hospitalAdmin],
    name: "Staff",
    url: null,
    icon: <img src={IClient} width={"16px"} className="mx-3" />,
    dropdown: [
      {
        name: "Staff Management",
        url: routes.hospitals.staffs.staff,
      },
      {
        name: "Consultation",
        url: routes.hospitals.staffs.consultation,
      },
    ],
  },
  {
    authorizedUsers: [superAdmin],
    name: "H. M. O",
    url: routes.hmos.hmo,
    icon: <img src={IHmo} width={"22px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hospitalAdmin],
    name: "H. M. O",
    url: routes.hospitals.hmo,
    icon: <img src={IHmo} width={"22px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hmoAdmin],
    name: "H. M. O Plans",
    url: routes.hmos.plans.plan,
    icon: <img src={IHmo} width={"22px"} className="mx-3" />,
  },
  {
    authorizedUsers: [companyAdmin],
    name: "Transaction Management",
    url: null,
    icon: <img src={ITransaction} width={"20px"} className="mx-3" />,
    dropdown: [
      {
        name: "H. M. O Transaction History",
        url: routes.companies.employees.transactions.hmoHistory,
      },
      {
        name: "H. M. O Outstanding Pay..",
        url: routes.companies.employees.transactions.hmoDebt,
      },
    ],
  },

  {
    authorizedUsers: [hmoAdmin],
    name: "Transaction Management",
    url: null,
    icon: <img src={ITransaction} width={"20px"} className="mx-3" />,
    dropdown: [
      {
        name: "Client’s Transaction History",
        url: routes.hmos.transactions.clientHistory,
      },
      {
        name: "Client’s Outstanding Pay...",
        url: routes.hmos.transactions.clientDebt,
      },
      {
        name: "Hospital Transaction History",
        url: routes.hmos.transactions.hospitalHistory,
      },
      {
        name: "Hospital Outstanding Pay..",
        url: routes.hmos.transactions.hospitalDebt,
      },
    ],
  },

  {
    authorizedUsers: [hmoAdmin],
    name: "Billing",
    url: routes.hmos.billing,
    icon: <img src={IBilling} width={"20px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hmoAdmin],
    name: "Clients",
    url: routes.hmos.client,
    icon: <img src={IClient} width={"16px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hmoAdmin],
    name: "Hospitals",
    url: routes.hmos.hospitals,
    icon: <img src={IHospital} width={"21px"} className="mx-3" />,
  },
  {
    authorizedUsers: [superAdmin],
    name: "Company",
    url: routes.companies.company,
    icon: <img src={ICompanyIcon} width={"23px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hospitalAdmin],
    name: "Transaction Management",
    url: null,
    icon: <img src={ITransaction} width={"20px"} className="mx-3" />,
    dropdown: [
      {
        name: "H.M.O Transaction History",
        url: routes.hospitals.hmoHistory,
      },
      {
        name: "H.M.O Outstanding Pay..",
        url: routes.hospitals.hmoDebt,
      },
      {
        name: "Payment Confirmation",
        url: routes.hospitals.confirmPayment,
      },
    ],
  },

  {
    authorizedUsers: [hospitalAdmin, hospitalStaff],
    name: "Appointment",
    url: routes.hospitals.appointment,
    icon: <img src={IAppointment} width={"20px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hospitalAdmin],
    name: "Designation",
    url: routes.hospitals.designations.designation,
    icon: <img src={IClient} width={"20px"} className="mx-3" />,
  },
  {
    authorizedUsers: [superAdmin],
    name: "Configuration",
    url: null,
    icon: <img src={IConfiguration} width={"20px"} className="mx-3" />,
    dropdown: [
      {
        name: "Ailments",
        url: routes.configurations.ailments,
      },
      {
        name: "Grade",
        url: routes.configurations.grade,
      },
      {
        name: "User Management",
        url: routes.configurations.userManagement,
      },
      {
        name: "Role Management",
        url: routes.configurations.roleManagement.role,
      },
      {
        name: "Settings",
        url: routes.configurations.settings,
      },
    ],
  },
  {
    authorizedUsers: [hospitalAdmin],
    name: "Configuration",
    url: routes.hospitals.configurations,
    icon: <img src={IConfiguration} width={"20px"} className="mx-3" />,
  },
  {
    authorizedUsers: [hmoAdmin],
    name: "Configuration",
    url: null,
    icon: <img src={IConfiguration} width={"20px"} className="mx-3" />,
    dropdown: [
      {
        name: "Bank Accounts",
        url: routes.hmos.configurations.accounts.account,
      },
      {
        name: "Services",
        url: routes.hmos.configurations.services.service,
      },
      {
        name: "User Management",
        url: routes.hmos.configurations.userManagement.user,
      },
    ],
  },
];

export default links;
