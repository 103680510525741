/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import CreateHmoPlan from "./CreateHmoPlan";

const EditPlan = () => {
  const { state } = useLocation(),
    data = useMemo(() => {
      const {
        updatedAt,
        createdAt,
        hmoId,
        editable,
        id,
        Grades,
        PlanBenefitCategories,
        ...rest
      } = state;

      return {
        ...rest,
        planId: state?.id,
        hospitalGrade: Grades?.[0]?.gradeNumber,
        benefits: PlanBenefitCategories?.[0]?.planBenefits?.map((a) => a.name),
      };
    }, []);

  return (
    <div>
      <CreateHmoPlan {...{ editable: state?.editable, stateModel: data }} />
    </div>
  );
};

export default EditPlan;
