/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import HomeLayout from "../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Table from "../../../components/table/Table";
import AddIcon from "@mui/icons-material/Add";
import config from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  getHmoData,
  getPlansByHmoId,
  setPagination,
  closeModal,
  openModal,
  addPlanToHospitals,
  deletePlanById,
} from "../../../features/hmo/hmoSlice";
import Menu from "../../../utils/data.dropdown";
import { getAmount, getIds } from "../../../utils/helper";
import CancelModal from "../../../components/Modals/Cancel";
import AddHospitalsToPlan from "../../../components/Modals/AddHospitalsToPlan";
import { getHospitalData } from "../../../features/hospitals/hospitalSlice";

const { routes } = config;
const HmoPlans = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    [planDetails, setPlanDetails] = useState({ name: "this user's", id: null }),
    [value, setValue] = useState({
      planId: "",
      hospitalIds: [],
    }),
    { plans, pagination, loading, modal } = useSelector(getHmoData),
    { hospitals } = useSelector(getHospitalData);

  async function getPlans() {
    await dispatch(getPlansByHmoId(getIds().hmo));
  }

  useEffect(() => {
    (async () => await getPlans())();
  }, [pagination.page]);

  const data = plans?.map((plan) => ({
    "Plan Name": plan?.name,
    "Amount Per Plan": "₦" + getAmount(plan?.amount),
    "Plan Duration": plan?.duration,
    "Hospital Grade": plan?.Grades?.[0]?.name,
    "Plan Type": plan?.planType || "N/A",
    _data: plan,
  }));

  const menu = new Menu({});
  const dropdownMenu = [
      menu.edit({
        navigate,
        route: routes.hmos.plans.plan,
      }),
      menu.deleteHmoPlan({
        action: (value) => {
          setPlanDetails(value);
          dispatch(openModal("delete"));
        },
        route: routes.companies.employees.employee,
      }),
    ],
    // TODO complete delete hmo plan implementation
    deleteHmoPlan = async () => {
      const res = await dispatch(deletePlanById(planDetails?.id)).unwrap();
      res.success && (await getPlans());
    },
    addHospitalsToPlan = async () => {
      const payload = { ...value };

      payload.hospitalIds = value?.hospitalIds?.map(
        (name) =>
          hospitals
            ?.filter((v, i) => v?.name === name)
            ?.map(({ id }) => id)?.[0]
      );

      const res = await dispatch(addPlanToHospitals(payload)).unwrap();
      if (res?.success) {
        dispatch(closeModal("add"));
        await getPlans();
      }
    };

  return (
    <HomeLayout {...{ title: "H. M. O Plans" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-end gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <LoadingButton
          LinkComponent={Link}
          className="w-[213px] h-[56px] bg-[var(--c-primary-0)] mr-8 text-[600] text-[18px] rounded-xl shadow-none normal-case"
          size="small"
          type="submit"
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          to={routes.hmos.plans.new}
        >
          Add H. M. O Plan
        </LoadingButton>
      </Box>
      <Box
        className="pt-8 px-8 mt-8 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Table
          {...{
            dropdownMenu,
            data,
            pagination,
            setPagination: (data) => dispatch(setPagination(data())),
            isLoading: loading,
          }}
        />
      </Box>

      <CancelModal
        {...{
          open: modal.delete,
          loading: modal.loading,
          title: "Delete Plan",
          subtitle: `Are you sure you want to delete ${planDetails?.name}?`,
          btnTexts: ["Cancel", "Delete"],
          handleSuccess: deleteHmoPlan,
          handleClose: (_) => dispatch(closeModal("delete")),
        }}
      />

      <AddHospitalsToPlan
        {...{
          open: modal.add,
          loading: modal.loading,
          title: "Select Hospitals",
          subtitle: `Please select the Hospitals to be added to this plan`,
          btnTexts: ["Cancel", "Add"],
          handleSuccess: addHospitalsToPlan,
          options: hospitals?.map(({ name }) => name)?.sort(),
          setValue: (value) =>
            setValue((state) => ({ ...state, hospitalIds: value })),
          value: value.hospitalIds,
          handleClose: (_) => {
            setValue((v) => ({ ...v, hospitalIds: [] }));
            dispatch(closeModal("add"));
          },
        }}
      />
    </HomeLayout>
  );
};

export default HmoPlans;
