/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import CreateDesignation from './CreateDesignation';

const EditDesignation = () => {
  const { state } = useLocation();
  const navigate = useNavigate(),
	cb = async res => {
		res.payload.success === true && navigate(-1)
	}

    const stateModel = useMemo(_=> {
     return {
      name: state?.name,
     description: state?.description,
     designationId: state?.id
     }
      })

  return (
    <CreateDesignation {...{ editable: state.editable, stateModel, cb }}/>
  )
}

export default EditDesignation