import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeLayout from "../../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "../../../../components/table/Table";
import {
  getPatientsData,
  setPagination,
  getPatientConsultations,
} from "../../../../features/hospitals/patientSlice";
import { useDispatch, useSelector } from "react-redux";
import TablePreloader from "../../../../components/Loader/TablePreloader";
import { formatDate, getAmount } from "../../../../utils/helper";
import Menu from "../../../../utils/data.dropdown";
import config from "../../../../utils/config";
import { IError } from "../../../../utils/icons.utils";

const { routes } = config;
const ConsultHistory = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    { state } = useLocation(),
    [isLoading, setIsLoading] = useState(true),
    { consultations, pagination, loading } = useSelector(getPatientsData);

  useEffect(() => {
    (async () => await dispatch(getPatientConsultations(state?.User?.id)))();
  }, [pagination.page]);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const data = consultations?.map((consultation) => ({
    "Patient Name": `${consultation?.patient?.firstName} ${consultation?.patient?.lastName}`,
    "Consultation Date": formatDate(consultation?.createdAt),
    "Consultation Amount": "₦" + getAmount(consultation?.amount),
    Doctor:
      consultation?.consultant?.firstName +
      " " +
      consultation?.consultant?.lastName,
    _data: consultation,
  }));

  const menu = new Menu({});
  const dropdownMenu = [menu.viewConsultationProfile({ navigate, routes })];

  return (
    <HomeLayout {...{ title: "Patients" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <Typography className="text-[400] text-xl mt-8 text-[#040316]">
          Consultation History
        </Typography>
      </Box>
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        <Table
          {...{
            dropdownMenu,
            data,
            pagination,
            setPagination: (data) => dispatch(setPagination(data())),
            isLoading: loading,
            emptyMsg: ["No consultation found for the selected patient"],
          }}
        />
      </Box>
    </HomeLayout>
  );
};

export default ConsultHistory;
