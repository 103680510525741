import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SearchBar from "../../components/SearchBar";
import HomeLayout from "../../layouts/Home";
import AddIcon from "@mui/icons-material/Add";
import config from "../../utils/config";
import Table from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  getCompanyData,
  getCompany,
  setPagination,
} from "../../features/company/companySlice";
import TablePreloader from "../../components/Loader/TablePreloader";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../../utils/data.dropdown";
import { useState } from "react";
import { IError } from "../../utils/icons.utils";
import { capitalize } from "../../utils/helper";

const { routes } = config;

const Company = () => {
  const { companies, loading, pagination } = useSelector(getCompanyData),
    dispatch = useDispatch(),
    navigate = useNavigate();
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {}, [pagination.page]);

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(getCompany()))(), 700);
    } else cb = (async () => await dispatch(getCompany()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search]);

  let data = companies?.map((company) => ({
    "Company Name": capitalize(company?.name),
    "Phone Number": company?.phoneNumber,
    email: company?.email,
    "Date Created": dayjs(company?.createdAt).format("MMM DD, YYYY"),
    status: company?.user?.verified ? "Active" : "Inactive",
    _data: company,
  }));

  const menu = new Menu({});
  const dropdownMenu = [
    menu.viewCompanyProfile({ navigate, routes }),
    menu.edit({
      navigate,
      route: routes.companies.company,
    }),
  ];

  

  return (
    <HomeLayout {...{ title: "Company" }}>
      <Box className="flex justify-between gap-4 sm:flex-col mt-3 xl:flex-row flex-col">
        <SearchBar
          {...{
            placeholder: "Search For Company",
            onChange: (e) => {
              const { value } = e.target;
              if (e.code === "Enter") dispatch(getCompany(value));
              else dispatch(setPagination({ search: value }));
            },
          }}
        />
        <LoadingButton
          LinkComponent={Link}
          className="w-[213px] h-[56px] bg-[var(--c-primary-0)] mr-8 text-[600] text-[16px] rounded-xl shadow-none normal-case"
          size="small"
          type="submit"
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          to={routes.companies.new}
        >
          Add Company
        </LoadingButton>
      </Box>
      <div className="w-full  mt-14">
        <Box
          className="pt-8 px-8 pb-2 rounded-lg bg-[var(--c-bg-color)] w-100%"
          sx={{ p: 0 }}
        >
          {isLoading ? (
            <TablePreloader />
          ) : data?.length ? (
            <Table
              {...{
                dropdownMenu,
                data,
                pagination,
                setPagination: (data) => dispatch(setPagination(data())),
                isLoading: loading,
              }}
            />
          ) : (
            <Box
              className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
              sx={{ p: 0 }}
            >
              <img
                className="m-auto w-[350px] mt-8"
                src={IError}
                alt={"error"}
                loading="lazy"
              />
              <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
                No company in the system at the moment
              </Typography>
            </Box>
          )}
        </Box>
      </div>
    </HomeLayout>
  );
};

export default Company;
