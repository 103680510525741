import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BACKEND from "../../utils/backend";

export const getAilment = createAsyncThunk('/user/getAilment', (search, thunkApi) => {
 try {
  const { pagination } = thunkApi.getState().ailment
  return new BACKEND().send({
   type: 'get',
   to: `/ailments/?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&search=${search || ''}`,
   useAlert: false
 })
 } catch (error) {
   console.error(error);
 }
})

export const createAilment = createAsyncThunk('/user/createAilment', (payload, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'post',
   to: `/ailments`,
   useAlert: true,
   payload
 })
 } catch (error) {
   console.error(error);
 }
})


export const updateAilment = createAsyncThunk('/user/updateAilment', ({ailmentId, ...payload}, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'patch',
   to: `/ailments/ailment/${ailmentId}`,
   useAlert: true,
   payload
 })
 } catch (error) {
   console.error(error);
 }
})

export const deleteAilment = createAsyncThunk('/user/deleteAilment', (ailmentId, thunkApi) => {
 try {
  return new BACKEND().send({
   type: 'delete',
   to: `/ailments/ailment/${ailmentId}`,
   useAlert: true,
 })
 } catch (error) {
   console.error(error);
 }
})



const initialState = {
 loading: false,
 ailments: [],
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 },
 model: {
  name: '',
  description: ''
 },
 modal: {
  delete: false,
  loading: false
 },
};

export const ailmentSlice = createSlice({
 name: 'ailment',
 initialState,
 reducers: {
  setPagination: (state, { payload }) => {
   state.pagination = {...state.pagination, ...payload}
  },
  
 closeModal: (state, {payload}) => {
  state.modal[payload] = false
},
openModal: (state, {payload}) => {
  state.modal[payload] = true
},
 },
 extraReducers: (builder => {
  
  builder.addCase(getAilment.pending, (state) => {
   state.loading = true;
  })
  .addCase(getAilment.fulfilled, (state, {payload}) => {
   state.loading = false;
   if(payload?.success) {
    state.ailments = payload?.data?.ailments
    state.pagination.total = payload?.data?.count || state.pagination.pageSize;
    state.pagination.length = state.pagination.pageSize * state.pagination.page
   }
  })
  .addCase(getAilment.rejected, state => {
    state.loading = false
  })

    /** ADD AILMENT **/
    .addCase(createAilment.pending, (state) => {
     state.loading = true;
   })
   .addCase(createAilment.fulfilled, (state) => {
     state.loading = false;
   })
   .addCase(createAilment.rejected, (state) => {
     state.loading = false;
   })

   /** DELETE AILMENT **/
   .addCase(deleteAilment.pending, (state) => {
    state.modal.loading = true;
  })
  .addCase(deleteAilment.fulfilled, (state) => {
    state.modal.loading = false;
    state.modal.delete = false
  })
  .addCase(deleteAilment.rejected, (state) => {
    state.modal.loading = false;
  })

   
    /** UPDATE AILMENT **/
    .addCase(updateAilment.pending, (state) => {
     state.loading = true;
   })
   .addCase(updateAilment.fulfilled, (state) => {
     state.loading = false;
   })
   .addCase(updateAilment.rejected, (state) => {
     state.loading = false;
   })
   
 })
})

export const { setPagination, closeModal, openModal } = ailmentSlice.actions
export const getAilmentData = state => state.ailment
export default ailmentSlice.reducer