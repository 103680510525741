import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import SearchBar from '../../../components/SearchBar'
import TabControl from '../../../components/tabControl/TabControl'
import { useAuthContext } from '../../../context/AuthContext'
import HomeLayout from '../../../layouts/Home'
import CompanyClient from './CompanyClients'
import IndivualClients from './IndivualClients'
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";


const HmoClients = () => {
    const [currentTabView, setCurrentTabView] = useState(<CompanyClient/>),
  [current, setCurrent] = useState(1),
  { setClassName } = useAuthContext()

    const pages = [
		{
			id: 1,
			title: "Company",
			element: <CompanyClient />,
		},
		{
			id: 2,
			title: "Individuals",
			element: <IndivualClients />,
		},
	];

    const handleTabChange = (id) => {
		setCurrent(id);
		setCurrentTabView(
			pages.find((page) => {
				setClassName((_) => "md:w-[80%] lg:w-[85%] xl:w-[65%]");
				return page.id === id;
			}).element,
		);
	};


  return (
    <HomeLayout {...{ title: "Clients", subtitle: "Overview  of all information on the system."  }}>
        <Box className="ml-3 my-10 flex justify-between">
		<SearchBar placeholder="Search For Clients..."/> 
            <LoadingButton
						className=" lg:w-[20.1%] w-full rounded-xl text-[12px] lg:text-[18px] bg-[var(--c-primary-0)] shadow-none normal-case"
						size="large"
						type="submit"
						variant="contained"
						fullWidth
                        startIcon={<CloudDownloadIcon />}
						// loading={loading}
                        >
						Export
					</LoadingButton>
        </Box>

        <TabControl
				tabs={pages}
				setCurrent={handleTabChange}
				current={current}
				tabWrapperActiveClass=" border-b-4 shadow-none rounded-none mt-6 hover:shadow-none border-[#090F47]"
				tabWrapperClass="mt-6  shadow-none rounded-none hover:shadow-none " 
				wraperClass="grid lg:grid-cols-6  gap-14 my-8 border-b-2 border-[#B3B3B3]"
				tabClass="text-[400] text-[20px] text-[#040316]  "
			/>
      {currentTabView}
    </HomeLayout>
  )
}

export default HmoClients