import {
  Card,
  CardMedia,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeLayout from "../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import config from "../../utils/config";
import SelectInput from "../../components/SelectInput";
import FileUpload from "../../components/FileUpload";
import PhoneNumberInput from "../../components/Phone/PhoneInput";
import { useFormik } from "formik";
import { vaddEmployee } from "../../utils/validators";
import {
  addEmployee,
  getEmployeeData,
  setPhoto,
  updateEmployee,
} from "../../features/company/employeeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyData,
  getPlansByCompanyId,
} from "../../features/company/companySlice";
import { getAmount, getIds } from "../../utils/helper";
import {
  getAuthData,
  getCities,
  getStates,
  uploadFile,
} from "../../features/auth/authSlice";

const { routes } = config;
const AddEmployee = ({ editable, cb, stateModel }) => {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    { model, loading } = useSelector(getEmployeeData),
    { states, cities, modal } = useSelector(getAuthData),
    { plans } = useSelector(getCompanyData),
    {
      values,
      handleBlur,
      handleChange,
      handleFocus,
      handleSubmit,
      setValues,
      errors,
      touched,
    } = useFormik({
      validationSchema: vaddEmployee,
      initialValues: stateModel || model,
      onSubmit: async (value) => {
        const plan = plans?.find(({ Plan, planId }) => {
          return value.hmoPlan.includes(Plan.name) ? planId : "";
        });

        const action = editable ? updateEmployee : addEmployee;
        const payload = {
          ...values,
          hmoPlan: plan?.planId,
          companyId: editable ? stateModel?.companyId : getIds().company,
          employeeUserId: stateModel?.userId,
        };

        const res = await dispatch(action(payload)).unwrap();
        res?.success === true && navigate(-1);
      },
    });

  useEffect(() => {
    dispatch(getPlansByCompanyId(getIds().company));
  }, []);

  const handleUpload = async (payload) => {
    try {
      const res = await dispatch(uploadFile(payload)).unwrap();
      if (res?.success) dispatch(setPhoto(res?.data?.[0]?.imageUrl));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(setPhoto(""));
    dispatch(getStates());
  }, []);

  useEffect(() => {
    values.state &&
      dispatch(
        getCities(states?.find((state) => state?.state === values.state)?.tag)
      );
  }, [values.state]);

  return (
    <HomeLayout {...{ title: "Employee" }}>
      <Box className="flex  mt-2 text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] mb-2">Go back</Typography>
      </Box>
      <Box className="flex justify-between flex-col gap-6 sm:flex-col lg:flex-col xl:flex-row">
        <Typography className="text-[400] text-xl mt-8 text-[#040316]">
          {editable ? "Edit" : "Add"} Employee
        </Typography>
        {!editable && (
          <LoadingButton
            LinkComponent={Link}
            className="w-[213px] h-[56px] bg-[var(--c-gray-4)] text-[var(--c-primary-0)] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            to={routes.companies.employees.upload}
          >
            Existing User
          </LoadingButton>
        )}
      </Box>
      <Box
        sx={{
          className: "w-[100%] lg:w-[80%]",
          px: "3em",
          py: "3px",
          bgcolor: "var(--c-bg-color)",
          mt: "3%",
        }}
      >
        <Box
          component="form"
          marginTop=""
          noValidate
          onSubmit={handleSubmit}
          className="mt-[1.3em]"
        >
          <Box className="grid lg:grid-cols-2 gap-4 mb-8">
            <FormControl className="w-full border-none lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="employeeFirstName"
                label="First Name"
                name="employeeFirstName"
                value={values.employeeFirstName}
                required
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.employeeFirstName && touched.employeeFirstName}
                helperText={
                  errors.employeeFirstName && touched.employeeFirstName
                    ? errors.employeeFirstName
                    : ""
                }
              />
            </FormControl>
            <FormControl className="w-full border-none lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="employeeLastName"
                label="Last Name"
                name="employeeLastName"
                value={values.employeeLastName}
                required
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.employeeLastName && touched.employeeLastName}
                helperText={
                  errors.employeeLastName && touched.employeeLastName
                    ? errors.employeeLastName
                    : ""
                }
              />
            </FormControl>
            <FormControl className="w-full border-none lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="employeeId"
                label="Employee ID"
                name="employeeId"
                value={values.employeeId}
                required
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.employeeId && touched.employeeId}
                helperText={
                  errors.employeeId && touched.employeeId
                    ? errors.employeeId
                    : ""
                }
              />
            </FormControl>

            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="employeeEmail"
                label="Email Address"
                name="employeeEmail"
                value={values.employeeEmail}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.employeeEmail && touched.employeeEmail}
                helperText={
                  errors.employeeEmail && touched.employeeEmail
                    ? errors.employeeEmail
                    : ""
                }
                type="employeeEmail"
              />
            </FormControl>

            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.hmoPlan,
                name: "hmoPlan",
                label: "H. M. O Plan",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.hmoPlan && touched.hmoPlan,
                helperText: errors.hmoPlan,
                options: plans?.map(({ Plan, planId }) => {
                  return Plan.name + " - ₦" + getAmount(Plan.amount);
                }),
              }}
            />

            <FormControl required className="w-full lg:mt-5">
              <PhoneNumberInput
                {...{
                  phoneNo: values.employeePhoneNumber,
                  setPhoneNo: (val) =>
                    setValues({ ...values, employeePhoneNumber: val }),
                  error:
                    errors.employeePhoneNumber && touched.employeePhoneNumber
                      ? errors.employeePhoneNumber
                      : "",
                  onFocus: handleFocus,
                  onBlur: handleBlur,
                }}
              />
            </FormControl>

            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.state,
                name: "state",
                label: "State",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.state && touched.state,
                helperText: errors.state,
                options: states?.map((state) => state?.state),
              }}
            />

            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.city,
                name: "city",
                label: "City",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.city && touched.city,
                helperText: errors.city,
                options: cities?.map((city) => city?.lga),
              }}
            />

            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="street"
                label="Street Name"
                name="street"
                value={values.street}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.street && touched.street}
                helperText={
                  errors.street && touched.street ? errors.street : ""
                }
                type="text"
              />
            </FormControl>
          </Box>
          <FileUpload
            {...{
              action: handleUpload,
              errors,
              touched,
              loading: modal.photoLoading,
              handleChange,
              label: "Upload Employee Picture",
              name: "profileImage",
            }}
          />
          {(model?.profileImage || stateModel?.profileImage) && (
            <Card className="w-60 h-50 m-auto my-8">
              <CardMedia
                component="img"
                alt="Selected Image"
                height="163px"
                width="266px"
                image={model?.profileImage || stateModel?.profileImage}
                title="Selected Image"
              />
            </Card>
          )}

          <Box className="flex justify-center">
            <LoadingButton
              className="my-10 rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] w-[50%] shadow-none normal-case"
              sx={{
                ".MuiLoadingButton-loadingIndicatorCenter": {
                  color: "var(--c-bg-color) !important",
                },
              }}
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              loading={loading}
            >
              {editable ? "Save Changes" : "Add"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </HomeLayout>
  );
};

export default AddEmployee;
