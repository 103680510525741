import * as Yup from "yup";

const lettersRegex = /^[A-Za-z0-9\s{2,}]+$/;
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/gi;
const passwordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;

const email = Yup.string()
    .required("Email Address is required")
    .email("Email Address is invalid")
    .matches(emailRegex, "Email Address is invalid"),
  phoneNumber = Yup.string()
    .required("Phone Number is required")
    .min(11, "Phone Number must be at least 11 characters")
    .max(15, "Phone Number must be less than 15 characters"),
  fullName = Yup.string()
    .required("Full Name is required")
    .min(3, "Full Name must be at least 3 characters")
    .max(50, "Full Name must be less than 50 characters")
    .matches(
      lettersRegex,
      "Full Name cannot contain numbers and special characters"
    ),
  firstName = Yup.string()
    .required("First Name is required")
    .min(3, "First Name must be at least 3 characters")
    .max(30, "First Name must be less than 30 characters")
    .matches(
      lettersRegex,
      "First Name cannot contain numbers and special characters"
    ),
  lastName = Yup.string()
    .required("Last Name is required")
    .min(3, "Last Name must be at least 3 characters")
    .max(30, "Last Name must be less than 30 characters")
    .matches(
      lettersRegex,
      "Last Name cannot contain numbers and special characters"
    ),
  companyName = Yup.string()
    .required("Company Name is required")
    .min(3, "Company Name Name must be at least 3 characters")
    .max(30, "Company Name Name must be less than 30 characters")
    .matches(
      lettersRegex,
      "Company Name cannot contain numbers and special characters"
    ),
  password = Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(16, "Password must be less than 16 characters");

export const userSignup = Yup.object({
  fullName,
  email,
  phoneNumber,
  password: password.matches(
    passwordRegex,
    "Password must contain at least 1 of the following characters; uppercase, lowercase, numbers and special characters"
  ),
});

export const createHMOPlan = Yup.object({
  name: Yup.string()
    .min(3, "Plan Name must be at least 3 characters")
    .max(30, "Plan Name must be less than 30 characters")
    .required("Plan Name is required"),
  planType: Yup.string().required("Plan Type is required"),
  duration: Yup.string().required("Duration is required"),
  amount: Yup.string().required("Plan Amount is required"),
  description: Yup.string()
    .min(10, "Plan Description must be at least 10 characters")
    .required("Plan Description is required"),
  memberLimit: Yup.string(),
  services: Yup.array().required("Services is required"),
  hospitalGrade: Yup.string().required("Hospital Grade is required"),
  benefits: Yup.array().required("Benefits is required"),
});

export const vCreateGrade = Yup.object({
  name: Yup.string()
    .min(3, "Grade Name must be at least 3 characters")
    .max(30, "Grade Name must be less than 30 characters")
    .required("Grade Name is required"),
  gradeNumber: Yup.string().required("Grade Number is required"),
  description: Yup.string()
    .min(3, "Description must be at least 10 characters")
    .max(40, "Description cannot be more than 40 characters")
    .required("Description is required"),
});

export const companySignup = Yup.object({
  email,
  phoneNumber,
  adminFirstName: firstName,
  adminLastName: lastName,
  name: companyName,
  streetName: Yup.string().required("Street is required"),
  rcNumber: Yup.string().required("RC Number is required"),
  adminEmail: email,
  adminPhoneNumber: phoneNumber,
  websiteLink: Yup.string()
    .matches(
      /[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&=]*)/g,
      "Invalid website link"
    )
    .matches(
      /(http(s)?:\/\/.)/,
      "Website Link must start with http:// or https://"
    )
    .required("Website Link is required"),
  logo: Yup.string().nullable(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
});

export const hospitalSignup = Yup.object({
  email,
  phoneNumber,
  adminFirstName: firstName,
  adminLastName: lastName,
  name: Yup.string()
    .required("Hospital Name is required")
    .min(3, "Hospital Name must be at least 3 characters")
    .max(30, "Hospital Name must be less than 30 characters")
    .matches(lettersRegex, "cannot contain numbers and special characters"),
  streetName: Yup.string().required("Address is required"),
  adminEmail: email,
  adminPhoneNumber: phoneNumber,
  hospitalLogo: Yup.string().nullable(),
  hospitalImages: Yup.array().nullable(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  rcNumber: Yup.string().required("RC Number is required"),
  hospitalType: Yup.string().required("Hospital Type is required"),
  hospitalGrade: Yup.string().required("Hospital Grade is required"),
  branch: Yup.string().required("Branch is required"),
  parentId: Yup.string().required("Parent ID is required"),
  hospitalServices: Yup.array().required("Hospital Services is required"),
});

export const userLogin = Yup.object({
  email,
  password,
});

export const vCreateRole = Yup.object({
  name: Yup.string()
    .required("Role Name is required")
    .min(3, "Name must be more than 3 characters"),
  permissions: Yup.array(Yup.string().required("Permission is required")),
});

export const vAilment = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be more than 3 characters"),
  description: Yup.string().required("Description is required"),
});

export const userForgotPassword = Yup.object({
  email,
});

export const userResetPassword = Yup.object({
  newPassword: password.matches(
    passwordRegex,
    "Password must contain at least 1 of the following characters; uppercase, lowercase, numbers and special characters"
  ),
  confirmPassword: password.matches(
    passwordRegex,
    "Password must contain at least 1 of the following characters; uppercase, lowercase, numbers and special characters"
  ),
});

export const vaddEmployee = Yup.object({
  employeeFirstName: firstName,
  employeeLastName: lastName,
  employeeId: Yup.string().required("Employee ID is required"),
  employeeEmail: email,
  hmoPlan: Yup.string(),
  employeePhoneNumber: phoneNumber,
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  street: Yup.string().required("Street is required"),
});

export const vaddHmoEmployee = Yup.object({
  fullName: fullName,
  employeeEmail: email,
  employeeRole: Yup.string().required("Role is required"),
  employeePhoneNumber: phoneNumber,
  employeeId: Yup.string().required("Employee ID is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  street: Yup.string().required("Street is required"),
});

export const vaddConsultation = Yup.object({
  ailment: Yup.string().required("Ailment is required"),
  prescription: Yup.string().required("Drug Prescription is required"),
  description: Yup.string().required("Description is required"),
  amount: Yup.number().required("Amount is required"),
});

export const vaddStaff = Yup.object({
  employeeFirstName: firstName,
  employeeLastName: lastName,
  employeeEmail: email,
  profileImage: Yup.string().required("Staff Picture is required"),
  employeePhoneNumber: phoneNumber,
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  street: Yup.string().required("Street is required"),
  specialization: Yup.string().required("Specialization is required"),
  designation: Yup.string().required("Designation is required"),
});

export const vaddBankAccount = Yup.object({
  accountName: Yup.string().required("Account Name is required"),
  accountNumber: Yup.string().required("Account Number is required"),
  bank: Yup.string().required("Bank Name is required"),
});
