/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import HomeLayout from "../../layouts/Home";
import Box from "@mui/material/Box";
import Table from "../../components/table/Table";
import AddIcon from "@mui/icons-material/Add";
import config from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
	openModal,
	closeModal,
	getHmoData,
	getHmos,
	setPagination,
	deactivateHmoById,
} from "../../features/hmo/hmoSlice";
import TablePreloader from "../../components/Loader/TablePreloader";
import Menu from "../../utils/data.dropdown";
import { Link, useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import CancelModal from "../../components/Modals/Cancel";
import { IError } from "../../utils/icons.utils";
import { capitalize } from "../../utils/helper";

const { routes } = config;
export default function HMO() {
	const { hmos, loading, pagination, modal } = useSelector(getHmoData),
		dispatch = useDispatch(),
		[isLoading, setIsLoading] = useState(true),
		[hmoDetails, setHmoDetails] = useState({ name: "" }),
		navigate = useNavigate(),
		//TODO write action for disable feature
		activateDeactivateHmo = async () => {
			const res = await dispatch(
				deactivateHmoById({ hmoId: hmoDetails.id, status: hmoDetails.status }),
			).unwrap();
      res?.success && dispatch(getHmos())
		};

	useEffect(() => {
		setIsLoading(false);
	}, []);

	useEffect(() => {
		let cb = () => {};
		if (pagination.search) {
			dispatch(setPagination({ page: 1 }));
			cb = setTimeout((_) => (async () => await dispatch(getHmos()))(), 700);
		} else cb = (async () => await dispatch(getHmos()))();
		return () => {
			clearTimeout(cb);
		};
	}, [pagination.search, pagination.page]);

	let data = hmos?.map((hmo) => ({
		"HMO Name": capitalize(hmo?.name),
		"Phone Number": hmo?.phoneNumber,
		"email address": hmo?.email,
		"Date Created": dayjs(hmo?.createdAt).format("MMM DD, YYYY"),
		status: hmo?.status ? "Active" : "Inactive",
		_data: hmo,
	}));

	const menu = new Menu({});
	const dropdownMenu = [
		menu.viewHmoProfile({ navigate, routes }),
		menu.edit({
			navigate,
			route: routes.hmos.hmo,
		}),
		menu.deactivateHmo({
			action: (value) => {
				setHmoDetails((state) => ({ ...value, status: !value.status }));
				dispatch(openModal("delete"));
			},
		}),
    menu.activateHmo({
			action: (value) => {
				setHmoDetails((state) => ({ ...value, status: !value.status }));
				dispatch(openModal("delete"));
			},
		}),
	];

	return (
		<HomeLayout {...{ title: "H.M.O" }}>
			<Box className="flex justify-between flex-col gap-6 sm:flex-col lg:flex-col xl:flex-row">
				<SearchBar
					{...{
						placeholder: "Search For H. M. O",
						onChange: (e) => {
							const { value } = e.target;
							if (e.code === "Enter") dispatch(getHmos({ search: value }));
							else dispatch(setPagination({ search: value }));
						},
					}}
				/>
				<Box className="flex justify-between gap-4 sm:flex-col lg:flex-row flex-col">
					<Button
						LinkComponent={Link}
						className="w-[213px] h-[56px] bg-[var(--c-gray-4)] text-[var(--c-primary-0)] text-[18px] rounded-xl shadow-none normal-case"
						size="small"
						type="submit"
						variant="contained"
						fullWidth
						to={routes.hmos.upload}>
						Upload H. M. O
					</Button>
					<Button
						LinkComponent={Link}
						className="w-[213px] h-[56px] bg-[var(--c-primary-0)] text-[600] text-[18px] rounded-xl shadow-none normal-case"
						size="small"
						type="submit"
						variant="contained"
						fullWidth
						startIcon={<AddIcon />}
						to={routes.hmos.new}>
						Add H. M. O
					</Button>
				</Box>
			</Box>
			<div className="w-full  mt-14">
				<Box
					className="pt-8 px-8 pb-2 rounded-lg bg-[var(--c-bg-color)] w-100%"
					sx={{ p: 0 }}>
					{isLoading ? (
						<TablePreloader />
					) : data?.length ? (
						<Table
							{...{
								dropdownMenu,
								data,
								pagination,
								setPagination: (data) => dispatch(setPagination(data())),
								isLoading: loading,
							}}
						/>
					) : (
						<Box
						className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
						sx={{ p: 0 }}
					  >
						<img
						className="m-auto w-[350px] mt-8"
						src={IError}
						alt={"error"}
						loading="lazy"
					  />
					  <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
					  No H.M.O in the system at the moment
					  </Typography>
					  </Box>
					)}
				</Box>
			</div>
			<CancelModal
				{...{
					open: modal.delete,
					loading: modal.loading,
					title: hmoDetails.status ? "Activate H.M.O" : "Deactivate H.M.O",
					subtitle: `Are you sure you want to ${hmoDetails.status ? "activate" : "deactivate"} ${hmoDetails.name}?`,
					btnTexts: ["Cancel", hmoDetails.status ? "Activate" : "Deactivate"],
					handleSuccess: activateDeactivateHmo,
					handleClose: (_) => dispatch(closeModal("delete")),
				}}
			/>
		</HomeLayout>
	);
}
