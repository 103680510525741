import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Table from '../../../components/table/Table';
import HomeLayout from '../../../layouts/Home';
import config from "../../../utils/config";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from 'react-redux';
import { getGradeData, getGrades, setPagination } from '../../../features/users/gradeSlice';
import { useEffect } from 'react';
import TablePreloader from '../../../components/Loader/TablePreloader';
import { IError } from '../../../utils/icons.utils';



const { routes } = config;

const Grade = () => {

 const { grades, loading, pagination } = useSelector(getGradeData),
 dispatch = useDispatch(),
 [isLoading, setIsLoading] = useState(true);


 useEffect(() => {
   (async ()=> await dispatch(getGrades()))()
 }, [pagination.page])
 
 
 let data =  grades?.map( grade => ({
   "Name": grade?.name,
   "Number": grade?.gradeNumber,
   "Description": grade.description,
   _data: grade
  }))
  
   useEffect(() => {
    !loading && setIsLoading(false)
  }, [])

  return (
    <HomeLayout {...{ title: 'Configuration' }}>
      <Box className='flex justify-end mt-4 mb-10'>
      <LoadingButton
            LinkComponent={Link}
            className="w-[213px] h-[56px] bg-[var(--c-primary-0)] mr-8 text-[600] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            to={routes.createGrade}
        >
            Create Grade
          </LoadingButton>
      </Box>
      <Box className="pt-8 px-8 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%" sx={{ p: 0 }}>
      {
            isLoading ? <TablePreloader />
            :
            data?.length ?
            <Table
            {...{
              data,
              pagination,
              setPagination: data => dispatch(setPagination(data())),
              isLoading: loading
            }}
          />
          :
          <Box
          className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
          sx={{ p: 0 }}
        >
          <img
          className="m-auto w-[350px] mt-8"
          src={IError}
          alt={"error"}
          loading="lazy"
        />
        <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
        No grade in the system at the moment
        </Typography>
        </Box>
          }
        </Box>
    </HomeLayout>
  )
}

export default Grade