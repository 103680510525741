/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getEmployeesById } from "../../features/company/employeeSlice";
import { getAmount } from "../../utils/helper";
import AddEmployee from "./AddEmployee";

const EditEmployee = () => {
  const { state } = useLocation();
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    cb = async (res) => {
      res.payload.success === true &&
        dispatch(getEmployeesById(state?.companyId, state?.userId));

      res.payload.success === true && navigate(-1);
    };
  console.log(state);

  const stateModel = useMemo((_) => {
    let user;
    // employee
    if (state.User) {
      user = {
        employeeFirstName: state?.User?.firstName,
        employeeLastName: state?.User?.lastName,
        employeeId: state?.employeeId,
        employeeEmail: state?.User?.email,
        hmoPlan:
          state?.companyuserplans?.name +
          " - ₦" +
          getAmount(state?.companyuserplans?.amount),
        employeePhoneNumber: state?.User?.phoneNumber,
        city: state?.city || "",
        state: state?.state || "",
        street: state?.street || "",
        companyId: state?.companyId,
        userId: state?.userId,
        profileImage: state?.User?.profileImage,
      };
    } else {
      user = {
        employeeFirstName: state?.firstName,
        employeeLastName: state?.lastName,
        employeeId: state?.employeeId,
        employeeEmail: state?.email,
        hmoPlan:
          state?.userplans?.[0]?.name +
          " - ₦" +
          getAmount(state?.userplans?.[0]?.amount),
        employeePhoneNumber: state?.phoneNumber,
        city: state?.city || "",
        state: state?.state || "",
        street: state?.street || "",
        companyId: state?.companyId,
        userId: state?.userId,
        profileImage: state?.profileImage,
      };
    }
    return user;
  }, []);

  return <AddEmployee {...{ editable: state.editable, stateModel, cb }} />;
};

export default EditEmployee;
