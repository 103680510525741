import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  FormControl,
  TextField,
  Card,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhoneNumberInput from "../Phone/PhoneInput";
import { useFormik } from "formik";
import { hospitalSignup } from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthData,
  setLogo,
  uploadFile,
  setImages,
  createHospital,
  getCities,
  getStates,
} from "../../features/auth/authSlice";
import {
  getHospitalData,
  updateHospital,
} from "../../features/hospitals/hospitalSlice";
import SelectInput from "../SelectInput";
import FileUpload from "../FileUpload";
import MultipleSelect from "../Select";
import { useEffect } from "react";
import {
  getAilment,
  getAilmentData,
  setPagination,
} from "../../features/users/ailmentSlice";
import { HospitalTypes } from "../../utils/helper";
import { getGradeData, getGrades } from "../../features/users/gradeSlice";
import ClearIcon from "@mui/icons-material/Clear";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ReactGoogleAutocomplete, {
  usePlacesWidget,
} from "react-google-autocomplete";
import config from "../../utils/config";
import { Input } from "@mui/icons-material";

const SignupHospital = ({ cb, editable, stateModel }) => {
  const dispatch = useDispatch(),
    [coordinates, setCoordinates] = useState({ latitude: 0, longitude: 0 }),
    { grades } = useSelector(getGradeData),
    {
      payload: { taxId, websiteLink, ...model },
      loading,
      hospital,
      cities,
      states,
    } = useSelector(getAuthData),
    { loading: isUpdating } = useSelector(getHospitalData),
    {
      ailments,
      pagination,
      loading: ailmentLoading,
    } = useSelector(getAilmentData),
    {
      setValues,
      values,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      errors,
      touched,
      handleFocus,
    } = useFormik({
      validationSchema: hospitalSignup,
      initialValues: editable
        ? {
            ...stateModel,
            hospitalGrade: getGradeByNumber(stateModel?.hospitalGrade),
          }
        : { ...hospital, ...model },
      onSubmit: async (value) => {
        const action = editable ? updateHospital : createHospital,
          payload = {
            ...value,
            hospitalGrade: getGradeByName(value.hospitalGrade),
            ...coordinates, // Add long & lat to the payload
          },
          res = await dispatch(action(payload));
        cb?.(res);
      },
    }),
    handleSearch = (e) => {
      e.stopPropagation();
      const {
        target: { value },
      } = e;
      let cb = null;
      if (value)
        cb = setTimeout(() => dispatch(setPagination({ search: value })), 10);
      else if (cb) {
        dispatch(getAilment());
        clearTimeout(cb);
      } else dispatch(getAilment());
    };

  function getGradeByName(name) {
    const grade = grades?.find((grade) => {
      const value = `${grade?.name} - ${grade?.description}`;
      if (value === name) return grade;
    });

    return grade?.gradeNumber;
  }

  function getGradeByNumber(number) {
    const grade = grades?.find((grade) => {
      if (grade?.gradeNumber === number) return grade;
    });
    return `${grade?.name} - ${grade?.description}`;
  }

  const handleUpload = async (payload) => {
    try {
      const res = await dispatch(uploadFile(payload)).unwrap();
      const { data, success } = res;
      success && dispatch(setLogo(data?.[0]?.imageUrl));
    } catch (err) {
      console.error(err);
    }
  };

  const handleUploads = async (payload) => {
    try {
      let { success, data } = await dispatch(uploadFile(payload)).unwrap();
      if (success) {
        dispatch(setImages(data?.map(({ imageUrl }) => imageUrl)));
        const images = values.hospitalImages ? values.hospitalImages : [];

        setValues({
          ...values,
          hospitalImages: [...images, ...data?.map(({ imageUrl }) => imageUrl)],
        });
      }
      setProgress(0);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(setLogo(""));
    dispatch(getStates());
    dispatch(getAilment());
    dispatch(getGrades());
  }, []);

  const [total, setTotal] = useState(0),
    [progress, setProgress] = useState(0);

  const handleHospitalImagesUpload = (e) => {
    const formData = new FormData();

    const config = {
      onUploadProgress: (e) => {
        setTotal(e.total);
        const percentCompleted = Math.round((e.loaded / e.total) * 100);
        setProgress(percentCompleted);
      },
      headers: { "Content-Type": "multipart/form-data" },
    };
    for (let file of e.target.files) {
      formData.append("file", file);
    }
    handleUploads({ formData, config });
  };

  useEffect(() => {
    values.state &&
      dispatch(
        getCities(states?.find((state) => state?.state === values.state)?.tag)
      );
    pagination.search && dispatch(getAilment(pagination.search));
  }, [values.state, pagination.search]);

  const removeImg = (idx) => {
      let handleFilter = (img, i) => i !== idx;
      setValues({
        ...values,
        hospitalImages: values.hospitalImages.filter(handleFilter),
      });
      if (values.hospitalImages.length > 0) setWarning(true);
      else setWarning(false);
    },
    [showWarning, setWarning] = useState(editable ? true : false);

  const { ref: materialRef } = usePlacesWidget({
    apiKey: config.googleMapsApiKey,
    onPlaceSelected: (place) => {
      setCoordinates({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });

      setFieldValue("streetName", place.formatted_address, false);
    },
    options: {
      types: [],
    },
  });

  return (
    <Box className="">
      <Box
        component="form"
        marginTop=""
        noValidate
        onSubmit={handleSubmit}
        className="mt-[1.3em]"
      >
        <Box>
          <FileUpload
            {...{
              action: handleUpload,
              errors,
              touched,
              handleChange,
              label: "Upload Hospital Logo",
              required: true,
            }}
          />
          {(model.logo || stateModel?.hospitalLogo) && (
            <Card className="w-60 h-50 m-auto my-8">
              <CardMedia
                component="img"
                alt="Selected Image"
                height="163px"
                width="266px"
                image={model.logo || stateModel?.hospitalLogo}
                title="Selected Image"
              />
            </Card>
          )}

          <Box className="grid lg:grid-cols-2 gap-4 mb-8">
            <FormControl className="w-full border-none lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="name"
                label="Hospital Name"
                name="name"
                value={values.name}
                required
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name && touched.name}
                helperText={errors.name && touched.name ? errors.name : ""}
              />
            </FormControl>

            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="email"
                label="Email Address"
                name="email"
                value={values.email}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email ? errors.email : ""}
                type="email"
              />
            </FormControl>

            <FormControl required className="w-full lg:mt-5">
              <PhoneNumberInput
                {...{
                  phoneNo: values.phoneNumber,
                  setPhoneNo: (val) =>
                    setValues({ ...values, phoneNumber: val }),
                  error:
                    errors.phoneNumber && touched.phoneNumber
                      ? errors.phoneNumber
                      : "",
                  onFocus: handleFocus,
                  onBlur: handleBlur,
                }}
              />
            </FormControl>

            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="rcNumber"
                label="RC Number"
                name="rcNumber"
                value={values.rcNumber}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.rcNumber && touched.rcNumber}
                helperText={
                  errors.rcNumber && touched.rcNumber ? errors.rcNumber : ""
                }
                type="text"
              />
            </FormControl>

            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.state || "",
                name: "state",
                label: "State",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.state && touched.state,
                helperText: errors.state,
                options: states?.map((state) => state?.state),
              }}
            />

            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.city || "",
                name: "city",
                label: "City",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.city && touched.city,
                helperText: errors.city,
                options: cities?.map((city) => city?.lga),
              }}
            />

            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <div style={{ width: 100 }}>
                <Input
                  fullWidth
                  className="hidden"
                  inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                    <ReactGoogleAutocomplete
                      apiKey={config.googleMapsApiKey}
                      {...props}
                    />
                  )}
                />
              </div>
              <div style={{ width: "100%" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  inputRef={materialRef}
                  className="w-full bg-white"
                  id="streetName"
                  label="Address"
                  name="streetName"
                  value={values.streetName}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  error={errors.streetName && touched.streetName}
                  helperText={
                    errors.streetName && touched.streetName
                      ? errors.streetName
                      : ""
                  }
                  type="text"
                />
              </div>
            </FormControl>
            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.hospitalType,
                name: "hospitalType",
                label: "Hospital Type",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.hospitalType && touched.hospitalType,
                helperText: errors.hospitalType,
                options: HospitalTypes,
              }}
            />

            <SelectInput
              {...{
                className: "lg:mt-5",
                value: values.hospitalGrade || "",
                name: "hospitalGrade",
                label: "Hospital Grade",
                handleChange,
                handleBlur,
                handleFocus,
                error: errors.hospitalGrade && touched.hospitalGrade,
                helperText: errors.hospitalGrade,
                options: grades?.map?.(
                  (grade) => `${grade?.name} - ${grade?.description}`
                ),
              }}
            />

            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="branch"
                label="Branch"
                name="branch"
                value={values.branch}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.branch && touched.branch}
                helperText={
                  errors.branch && touched.branch ? errors.branch : ""
                }
                type="text"
              />
            </FormControl>
            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="parentId"
                label="Parent ID"
                name="parentId"
                value={values.parentId}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.parentId && touched.parentId}
                helperText={
                  errors.parentId && touched.parentId ? errors.parentId : ""
                }
                type="text"
              />
            </FormControl>

            <MultipleSelect
              {...{
                className: "w-full mx-auto lg:mt-5",
                options: ailments?.map((ailment) => ailment?.name),
                value: values.hospitalServices || [],
                title: "Hospital Services",
                name: "hospitalServices",
                required: true,
                handleBlur,
                handleFocus,
                handleChange,
                handleSearch,
                loading: !pagination.search && ailmentLoading,
                searchLoading: pagination.search && ailmentLoading,
                error: errors.hospitalServices && touched.hospitalServices,
                helperText: errors.hospitalServices,
              }}
            />
          </Box>

          <FileUpload
            {...{
              values: values?.hospitalImages,
              total,
              showWarning: showWarning,
              action: (e) => {
                setValues({ ...values, hospitalImages: [] });
                handleUploads(e);
              },
              errors,
              touched,
              handleChange,
              name: "hospitalImages",
              label: "Upload Hospital Images",
            }}
            multiple={true}
          />
          <div className="flex flex-wrap">
            {values?.hospitalImages?.length > 0 &&
              values?.hospitalImages?.map?.((imageUrl, i) => {
                return (
                  <div className="m-auto my-5 relative" key={i}>
                    <ClearIcon
                      style={{ top: "-10px", right: "-10px" }}
                      onClick={(_) => removeImg(i)}
                      className="cursor-pointer text-[#000] p-1 z-30 shadow-xl bg-[#eee] rounded-full  absolute hover:text-red-500 transition-all hover:p-[0px] hover:rotate-90"
                    />
                    <Card className="w-60 h-50" key={i}>
                      <CardMedia
                        component="img"
                        alt="Selected Image"
                        height="163px"
                        width="266px"
                        image={imageUrl}
                        title="Selected Image"
                      />
                    </Card>
                  </div>
                );
              })}

            {values?.hospitalImages?.length > 0 && (
              <div className="mx-auto">
                <input
                  id="more-img"
                  multiple
                  className="hidden"
                  type="file"
                  onChange={handleHospitalImagesUpload}
                />
                <label htmlFor="more-img">
                  <Card
                    className="w-60 h-[135px] my-5 flex justify-center items-center cursor-pointer hover:bg-slate-200 transition-all"
                    onMouseEnter={(e) => {
                      e.target.firstElementChild.style.transform =
                        "scale(1.5) rotate(-45deg)";
                      e.target.firstElementChild.style.transition = "all .150s";
                    }}
                    onMouseLeave={(e) => {
                      if (e.target.firstElementChild)
                        e.target.firstElementChild.style.transform =
                          "scale(1) rotate(0deg)";
                      if (e.target.firstElementChild)
                        e.target.firstElementChild.style.transition =
                          "all .150s";
                    }}
                  >
                    {progress === 0 ? (
                      <AddPhotoAlternateIcon />
                    ) : (
                      <>
                        <CircularProgress
                          color="primary"
                          size="2rem"
                          variant="determinate"
                          value={progress}
                        >
                          {progress}%
                        </CircularProgress>
                      </>
                    )}
                  </Card>
                </label>
              </div>
            )}
          </div>
        </Box>
        <Box>
          <Typography component={"h6"} variant={"h6"} className="mt-8 mb-4">
            Admin Information
          </Typography>
          <Box className="grid lg:grid-cols-2 gap-4">
            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="adminFirstName"
                label="First Name"
                name="adminFirstName"
                value={values.adminFirstName}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.adminFirstName && touched.adminFirstName}
                helperText={
                  errors.adminFirstName && touched.adminFirstName
                    ? errors.adminFirstName
                    : ""
                }
                type="text"
              />
            </FormControl>
            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="adminLastName"
                label="Last Name"
                name="adminLastName"
                value={values.adminLastName}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.adminLastName && touched.adminLastName}
                helperText={
                  errors.adminLastName && touched.adminLastName
                    ? errors.adminLastName
                    : ""
                }
                type="text"
              />
            </FormControl>

            <FormControl hiddenLabel required className="w-full lg:mt-5">
              <TextField
                className="w-full bg-white"
                id="adminEmail"
                label="Email Address"
                name="adminEmail"
                value={values.adminEmail}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={errors.adminEmail && touched.adminEmail}
                helperText={
                  errors.adminEmail && touched.adminEmail
                    ? errors.adminEmail
                    : ""
                }
                type="email"
              />
            </FormControl>

            <FormControl required className="w-full lg:mt-5">
              <PhoneNumberInput
                {...{
                  phoneNo: values.adminPhoneNumber,
                  setPhoneNo: (val) =>
                    setValues({ ...values, adminPhoneNumber: val }),
                  error:
                    errors.adminPhoneNumber && touched.adminPhoneNumber
                      ? errors.adminPhoneNumber
                      : "",
                  onFocus: handleFocus,
                  onBlur: handleBlur,
                }}
              />
            </FormControl>
          </Box>
          {cb && <Box></Box>}
          <Box className="flex  justify-center">
            <LoadingButton
              className="my-10 normal-case rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] w-[50%] shadow-none"
              sx={{
                ".MuiLoadingButton-loadingIndicatorCenter": {
                  color: "var(--c-bg-color) !important",
                },
              }}
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              loading={editable ? isUpdating : loading}
            >
              <span>
                {cb && !editable
                  ? "Add Hospital"
                  : editable
                  ? "Update Hospital"
                  : "Create Account"}
              </span>
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignupHospital;
