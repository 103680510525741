import React from "react";

const TabControl = ({
  tabs,
  current,
  setCurrent,
  wraperClass,
  tabClass,
  activeTabClass,
  tabWrapperClass,
  tabWrapperActiveClass,
  tabWrapperInactiveClass,
  tabImageClass,
  tabImageActiveClass,
  tabImageInactiveClass,
  tabTitleClass,
  tabTitleActiveClass,
  tabTitleInactiveClass
}) => {
  return (
    <div className={`tabControl flex flex-wrap gap-1 ${wraperClass}`}>
      {tabs?.map((i) => {
        const isCurrent = current === i.id;
        return (
          <div
            key={i.id}
            className={`flex flex-col p-3 justify-center items-center cursor-pointer shadow-md hover:shadow-xl rounded-lg ${
              isCurrent ? tabWrapperActiveClass : tabWrapperInactiveClass
            } ${tabWrapperClass}`}
            onClick={() => setCurrent(i.id)}
          >
            {i.img && (
              <img
                src={i.img}
                alt={i.title}
                className={`${tabImageClass} ${
                  isCurrent ? tabImageActiveClass : tabImageInactiveClass
                }`}
              />
            )}
            <p
              className={`${tabClass} ${
                isCurrent ? tabTitleActiveClass : tabTitleInactiveClass
              } ${isCurrent ? activeTabClass : ""}`}
            >
              {i.title}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default TabControl;
