import React, { useEffect, useState } from "react";
import HomeLayout from "../../../../layouts/Home";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { IError } from "../../../../utils/icons.utils";
import { LoadingButton } from "@mui/lab";
import config from "../../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, getIds } from "../../../../utils/helper";
import AddIcon from "@mui/icons-material/Add";
import Menu from "../../../../utils/data.dropdown";
import Table from "../../../../components/table/Table";
import {
  closeModal,
  deleteEmployee,
  getEmployeeData,
  getEmployees,
  openModal,
  setPagination,
} from "../../../../features/hmo/employeeSlice";
import CancelModal from "../../../../components/Modals/Cancel";

const { routes } = config;
const HmoRoles = () => {
  const navigate = useNavigate(),
    [deletePayload, setDeletePayload] = useState({
      hmoId: getIds().hmo,
      employeeId: null,
    }),
    [name, setName] = useState("");

  const { employees, pagination, loading, modal } =
      useSelector(getEmployeeData),
    dispatch = useDispatch();

  useEffect(() => {
    let cb = () => {};
    if (pagination.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) => (async () => await dispatch(getEmployees(getIds().hmo)))(),
        700
      );
    } else cb = (async () => await dispatch(getEmployees(getIds().hmo)))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page]);

  const handleDelete = async () => {
    const res = await dispatch(deleteEmployee(deletePayload)).unwrap();
    res?.success === true && dispatch(getEmployees(getIds().hmo));
  };

  let data = employees?.map((employee) => ({
    Name: employee?.User?.firstName + " " + employee?.User?.lastName,
    "Employee ID": employee?.employeeId,
    Email: employee?.User?.email,
    "Phone Number": employee?.User?.phoneNumber,
    "H.M.O Plan": capitalize(employee?.hmouserplans?.name) || "N/A",
    status: employee?.User?.verified ? "Active" : "Inactive",
    _data: employee,
  }));

  const menu = new Menu({});
  const dropdownMenu = [
    menu.edit({
      navigate,
      route: routes.hmos.configurations.userManagement.user,
    }),
    menu.delete({
      action: (value) => {
        setDeletePayload((state) => ({
          ...state,
          employeeId: value.userId,
        }));

        setName(`${value?.User?.firstName}  ${value?.User?.lastName}`);
        dispatch(openModal("delete"));
      },
    }),
  ];

  return (
    <HomeLayout {...{ title: "Configuration" }}>
      <Box className="flex justify-between flex-wrap gap-2 sm:flex-col lg:flex-col xl:flex-row">
        <Typography className="text-[400] text-xl mt-8 ml-1 text-[#040316]">
          User Management
        </Typography>

        <Box className="flex justify-between gap-4 sm:flex-col lg:flex-row flex-col">
          <LoadingButton
            LinkComponent={Link}
            className="w-[220px] h-[56px] bg-[var(--c-gray-4)] text-[var(--c-primary-0)] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            to={routes.hmos.configurations.userManagement.upload}
          >
            Upload Employees
          </LoadingButton>
          <LoadingButton
            LinkComponent={Link}
            className="w-[213px] h-[56px] bg-[var(--c-primary-0)] text-[600] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            to={routes.hmos.configurations.userManagement.new}
          >
            Add Employee
          </LoadingButton>
        </Box>
      </Box>
      <Box
        className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%"
        sx={{ p: 0 }}
      >
        {data.length > 0 || loading ? (
          <Table
            {...{
              dropdownMenu,
              data,
              pagination,
              setPagination: (d) => dispatch(setPagination(d())),
              isLoading: loading,
            }}
          />
        ) : (
          <div className="flex flex-col justify-center items-center">
            <img
              className="m-auto w-[350px]"
              src={IError}
              alt={"error"}
              loading="lazy"
            />
            <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
              You currently don’t have any user
            </Typography>
            <LoadingButton
              LinkComponent={Link}
              className="w-[213px] text-[#FFFFFF] h-[56px] bg-[var(--c-primary-0)] mt-8 text-[600] text-[18px] rounded-xl m-auto shadow-none normal-case"
              size="small"
              type="submit"
              variant="contained"
              fullWidth
              to={routes.hmos.configurations.userManagement.new}
            >
              Create User
            </LoadingButton>{" "}
            <br />
            <br />
          </div>
        )}
      </Box>

      <CancelModal
        {...{
          open: modal.delete,
          loading: modal.loading,
          title: "Delete Employee",
          subtitle: `Are you sure you want to delete ${name}?`,
          btnTexts: ["Cancel", "Delete"],
          handleSuccess: handleDelete,
          handleClose: (_) => dispatch(closeModal("delete")),
        }}
      />
    </HomeLayout>
  );
};

export default HmoRoles;
