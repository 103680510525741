import React, { useEffect, useState } from "react";
import { Box, LinearProgress, Tooltip, Typography } from "@mui/material";
import { getFileSize } from "../utils/helper";
import ProgressBar from "./Loader/ProgressBar";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Alert from "../utils/alert";

const FileUpload = ({
  handleChange,
  action,
  errors,
  touched,
  name,
  loading,
  label,
  ...other
}) => {
  const [file, setFile] = useState({ name: "" });
  const [total, setTotal] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => setTotal(total + (other?.total || 0)), [other?.total]);
  useEffect(
    () =>
      setFile({
        name:
          other?.values?.length > 1
            ? other?.values?.length + " files"
            : other?.values?.length + " file",
      }),
    [other?.values]
  );

  const handleFileChange = (event) => {
    let length = event.target.files.length;
    if (length) {
      length > 1
        ? setFile({ name: event.target.files.length + " files" })
        : setFile(event.target.files[0]);
      handleUpload(event);
    }
    handleChange(event);
  };

  const handleUpload = (e) => {
    const formData = new FormData();

    const config = {
      onUploadProgress: (e) => {
        console.log("percentCompleted:", e.loaded, e.total);
        setTotal(e.total);
        const percentCompleted = Math.round((e.loaded / e.total) * 100);
        setProgress(percentCompleted);
      },
      headers: { "Content-Type": "multipart/form-data" },
    };
    for (let file of e.target.files) {
      if (file.size > 5000000) {
        Alert({
          type: "error",
          message: "File size cannot be larger than 5MB",
        });
        return;
      }

      formData.append("file", file);

      console.log(getFileSize({ size: file.size }));
    }
    action({ formData, config });
  };

  console.log(progress, other.multiple);
  return (
    <Box className=" mb-8">
      <label htmlFor="logo" className="text-[#8D9091] text-[14px]">
        {label} {other.required ? "*" : ""}
      </label>
      <Box className="md:w-70 lg:h-40 border-2 border-dashed p-8 mt-1 rounded-md">
        <input
          {...other}
          type="file"
          id={name || "file"}
          label="Upload files..."
          name={name || "file"}
          onChange={handleFileChange}
          className="hidden"
          disabled={other.multiple && other?.values?.length > 0}
        />
        <label
          htmlFor={name || "file"}
          className="mb-4 inline-block  text-sm text-slate-500
       py-2 px-4 rounded-lg font-semibold
      bg-[#ECEEF4] text-[var(--c-primary-0)]
      hover:bg-[#DADCE4] cursor-pointer
    "
        >
          Upload File
        </label>
        {(other?.values?.length > 0 || (progress > 0 && !other.multiple)) && (
          <div className="text-[var(--c-grey-0)]">
            <p className="truncate">{file && `File: ${file.name}`}</p>
            <div className="flex">
              <p className="mr-1">
                {" "}
                Size:{" "}
                {total
                  ? getFileSize({
                      size: total,
                    })
                  : 0}{" "}
              </p>
              {other?.showWarning && (
                <WarningAmberIcon
                  className="cursor-pointer"
                  color="warning"
                  titleAccess="This data may not be accurate"
                />
              )}
            </div>
          </div>
        )}
        {other?.values?.length > 0 || (progress > 0 && !other.multiple) ? (
          <ProgressBar value={progress} />
        ) : (
          loading && <LinearProgress variant="indeterminate" />
        )}
        {errors[name || "logo"] && touched[name || "logo"] && (
          <Typography
            className={
              "pt-1 text-[0.75rem] pl-[5px] leading-[1.66] font-normal"
            }
            color="error"
          >
            {errors[name || "logo"]}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FileUpload;
