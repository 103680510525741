import { LoadingButton } from '@mui/lab'
import { Box, CardContent, FormControl, Typography } from '@mui/material'
import React from 'react'
import CardComp from '../../../components/CardComp'
import AddIcon from "@mui/icons-material/Add";
import ControlledSwitch from '../../../components/ControlledSwitch';
import PasswordInput from '../../../components/PasswordInput';

const ApiSettings = () => {
  return (
    <div>
        <Box className='flex md:justify-end justify-center mt-4 mb-10'>
      <LoadingButton
            className="w-[213px] h-[56px] bg-[var(--c-primary-0)] md:mr-8 text-[600] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
          >
            Create API
          </LoadingButton>
      </Box>
         <Box
        className="p-8 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg"
        sx={{ p: 0 }}
      >
        <Typography className="text-[400] text-xl  ml-5 text-[#040316]">
        Api Settings
      </Typography>
        <CardComp
          className={
            "bg-[#FFFFFF] w-[95%] h-[550px] my-4 border-none rounded-lg shadow-none m-auto"
          }
        >
          <CardContent className=" ">
          <Box className='flex md:justify-between md:w-[75%] w-[100%] m-auto pt-8'>
                            <Typography className='text-[600] text-[18px]  text-[#000000] md:mt-3 mt-1 mr-6 '>
                            Turn webhook on
                            </Typography>
                            <ControlledSwitch color="default"/>
                        </Box>
                        <Box className='flex md:flex-row flex-col gap-6 md:w-[75%] m-auto mt-8'>
                        <FormControl className='md:w-[71%] w-[100%]'>
                        <PasswordInput
					{...{
						// value: values.password,
						label: "API Key",
						// error: errors.password && touched.password,
						// helperText:
						// 	errors.password && touched.password ? errors.password : "",
						name: "ApiKey",
						// onChange: handleChange,
						// onBlur: handleBlur,
					}}
				/>
                </FormControl>
                <LoadingButton
            className="md:w-[213px] text-[#FFFFFF] h-[56px] bg-[var(--c-primary-0)] shadow-none  mt-8 text-[600] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
          >
           Copy API Key
            
          </LoadingButton>
                </Box>
          </CardContent>
        </CardComp>
      </Box>
    </div>
  )
}

export default ApiSettings