import { Box, Typography, TextField, FormControl } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeLayout from "../../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { vaddBankAccount } from "../../../../utils/validators";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  AddBankAccount,
  updateBankAccount,
} from "../../../../features/hmo/hmoSlice";

const AddHmoAccount = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    dispatch = useDispatch(),
    {
      values,
      handleBlur,
      handleChange,
      handleFocus,
      handleSubmit,
      errors,
      touched,
    } = useFormik({
      validationSchema: vaddBankAccount,
      initialValues: { accountName: "", accountNumber: "", bank: "" },
      onSubmit: async (values) => {
        const action = state ? updateBankAccount : AddBankAccount;
        const payload = values;
        if (state) payload.bankAccountId = state.id;
        const res = await dispatch(action(payload)).unwrap();
        res?.success === true && navigate(-1);
      },
    });

  return (
    <HomeLayout {...{ title: "Configuration" }}>
      <Box className="flex text-[var(--c-dark-1)]">
        <ArrowBackIcon
          onClick={(_) => navigate(-1)}
          className=" mr-2 cursor-pointer"
          fontSize="small"
        />
        <Typography className="text-[400] text-[16px] ">Go back</Typography>
      </Box>
      <Typography className="text-[400] text-xl mt-8 text-[#040316]">
        New Account
      </Typography>
      <Box
        className="w-[100%] rounded-lg"
        sx={{
          px: "3em",
          py: "3em",
          bgcolor: "var(--c-bg-color)",
          mt: "3%",
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <Box className="grid lg:grid-cols-2 gap-4 mb-8">
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="accountName"
              label="Account Name"
              name="accountName"
              value={values.accountName}
              // required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.accountName && touched.accountName}
              helperText={
                errors.accountName && touched.accountName
                  ? errors.accountName
                  : ""
              }
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="accountNumber"
              label="Account Number"
              name="accountNumber"
              value={values.accountNumber}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.accountNumber && touched.accountNumber}
              helperText={
                errors.accountNumber && touched.accountNumber
                  ? errors.accountNumber
                  : ""
              }
            />
          </FormControl>
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="bank"
              label="Bank Name"
              name="bank"
              value={values.bank}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.bank && touched.bank}
              helperText={errors.bank && touched.bank ? errors.bank : ""}
            />
          </FormControl>
        </Box>
        <Box className="flex justify-end gap-4 my-6">
          <LoadingButton
            className="w-[213px] h-[56px] bg-[var(--cc-grey-3)] text-[var(--c-primary-0)] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
            onClick={(_) => navigate(-1)}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            className="w-[213px] h-[56px] bg-[var(--c-primary-0)] text-[var(--cc-grey-3)] text-[18px] rounded-xl shadow-none normal-case"
            size="small"
            type="submit"
            variant="contained"
            fullWidth
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </HomeLayout>
  );
};

export default AddHmoAccount;
