import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IDatePicker } from '../utils/icons.utils';
import { Box } from '@mui/material';

const DateRangePicker = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const handleStartDateClick = () => {
    startDateRef.current.setFocus(true); 
  };

  const handleEndDateClick = () => {
    endDateRef.current.setFocus(true); 
  };

  return (
    <Box className="date-range-picker grid grid-cols-2 w-[35%] mt-6 bg-[var(--c-bg-color)] pt-4 pb-4 pl-1 rounded-[10px]">
      <Box className="date-input flex text-[var(--c-primary-0)] text-[16px] font-bold ml-4">
        <img src={IDatePicker} alt='IDatePicker' className='mr-2 cursor-pointer' onClick={handleStartDateClick}/>
        <DatePicker
        ref={startDateRef}
          className="w-[100%] px-1 pt-1 bg-transparent"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Created From"
          wrapperClassName="date-picker-wrapper"
          dateFormat="MM/dd/yyy"
        />
        <span className='w-[15px]  mt-1 pl-3'>-</span>
      </Box>
      
      <Box className="date-input flex text-[var(--c-primary-0)] text-[16px] ml-7 font-bold">
      <img src={IDatePicker} alt='IDatePicker' className='mr-2 cursor-pointer'onClick={handleEndDateClick}/>
        <DatePicker
        ref={endDateRef}
        className="w-[100%] px-1 pt-1 bg-transparent"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText="Created To"
          wrapperClassName="date-picker-wrapper"
          dateFormat="MM/dd/yyy"
        />
      </Box>
    </Box>
  );
};

export default DateRangePicker;
