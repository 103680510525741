import { Box } from "@mui/material";
import React, { useState } from "react";
import Table from "../../../../components/table/Table";

const Company = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    length: 0,
    hidden: false,
  });

  const rows = [];

  return (
    <Box
      className="pt- px-8  pb-4 bg-[var(--c-bg-color)] rounded-lg w-100%"
      sx={{ p: 0 }}
    >
      <Table
        {...{
          data: rows,
          pagination,
          setPagination,
        }}
      />
    </Box>
  );
};

export default Company;
