/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  FormControl,
  TextField,
  Card,
  CardMedia,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhoneNumberInput from "../Phone/PhoneInput";
import { useFormik } from "formik";
import { companySignup } from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import {
  createCompany,
  getAuthData,
  getCities,
  getStates,
  setLogo,
  uploadFile,
} from "../../features/auth/authSlice";
import {
  getCompanyData,
  updateCompany,
} from "../../features/company/companySlice";
import SelectInput from "../SelectInput";
import FileUpload from "../FileUpload";

const SignupCompany = ({ cb, editable, stateModel }) => {
  const dispatch = useDispatch();

  const { payload, states, cities, loading } = useSelector(getAuthData),
    { loading: isUpdating } = useSelector(getCompanyData),
    {
      setValues,
      values,
      handleBlur,
      handleChange,
      handleSubmit,
      errors,
      touched,
      handleFocus,
    } = useFormik({
      validationSchema: companySignup,
      initialValues: stateModel || payload,
      onSubmit: async (value) => {
        const action = editable ? updateCompany : createCompany;
        const res = await dispatch(action(value));
        cb?.(res);
      },
    });
  const handleUpload = async (payload) => {
    try {
      const res = await dispatch(uploadFile(payload)).unwrap();
      const { data, success } = res;

      if (success) dispatch(setLogo(data?.[0]?.imageUrl));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(setLogo(""));
    dispatch(getStates());
  }, []);

  useEffect(() => {
    values.state &&
      dispatch(
        getCities(states?.find((state) => state?.state === values.state)?.tag)
      );
  }, [values.state]);

  return (
    <Box className="">
      <Box
        component="form"
        marginTop=""
        noValidate
        onSubmit={handleSubmit}
        className="mt-[1.3em]"
      >
        <FileUpload
          {...{
            action: handleUpload,
            errors,
            touched,
            handleChange,
            label: "Upload Company Logo",
            name: "logo",
          }}
        />
        {(payload.logo || stateModel?.logo) && (
          <Card className="w-60 h-50 m-auto my-8">
            <CardMedia
              component="img"
              alt="Selected Image"
              height="163px"
              width="266px"
              image={payload.logo || stateModel?.logo}
              title="Selected Image"
            />
          </Card>
        )}

        <Box className="grid lg:grid-cols-2 gap-4 ">
          <FormControl className="w-full border-none lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="name"
              label="Company Name"
              name="name"
              value={values.name}
              required
              onFocus={handleFocus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name && touched.name}
              helperText={errors.name && touched.name ? errors.name : ""}
            />
          </FormControl>

          <SelectInput
            {...{
              className: "lg:mt-5",
              value: values.state,
              name: "state",
              label: "State",
              handleChange,
              handleBlur,
              handleFocus,
              error: errors.state && touched.state,
              helperText: errors.state,
              options: states?.map((state) => state?.state),
            }}
          />

          <SelectInput
            {...{
              className: "lg:mt-5",
              value: values.city,
              name: "city",
              label: "City",
              handleChange,
              handleBlur,
              handleFocus,
              error: errors.city && touched.city,
              helperText: errors.city,
              options: cities?.map((city) => city?.lga),
            }}
          />

          <FormControl hiddenLabel required className="w-full lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="streetName"
              label="Street"
              name="streetName"
              value={values.streetName}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors.streetName && touched.streetName}
              helperText={
                errors.streetName && touched.streetName ? errors.streetName : ""
              }
              type="email"
            />
          </FormControl>
          <FormControl hiddenLabel required className="w-full lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="rcNumber"
              label="RC Number"
              name="rcNumber"
              value={values.rcNumber}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors.rcNumber && touched.rcNumber}
              helperText={
                errors.rcNumber && touched.rcNumber ? errors.rcNumber : ""
              }
              type="text"
            />
          </FormControl>

          <FormControl required className="w-full lg:mt-5">
            <PhoneNumberInput
              {...{
                phoneNo: values.phoneNumber,
                setPhoneNo: (val) => setValues({ ...values, phoneNumber: val }),
                error:
                  errors.phoneNumber && touched.phoneNumber
                    ? errors.phoneNumber
                    : "",
                onFocus: handleFocus,
                onBlur: handleBlur,
              }}
            />
          </FormControl>

          <FormControl hiddenLabel required className="w-full lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="websiteLink"
              label="Website Link"
              name="websiteLink"
              value={values.websiteLink}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors.websiteLink && touched.websiteLink}
              helperText={
                errors.websiteLink && touched.websiteLink
                  ? errors.websiteLink
                  : ""
              }
              type="text"
            />
          </FormControl>
          <FormControl hiddenLabel required className="w-full lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="email"
              label="Email Address"
              name="email"
              value={values.email}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors.email && touched.email}
              helperText={errors.email && touched.email ? errors.email : ""}
              type="email"
            />
          </FormControl>
        </Box>
        <Typography component={"h6"} variant={"h6"} className="mt-8 mb-4">
          Admin Information
        </Typography>
        <Box className="grid lg:grid-cols-2 gap-4">
          <FormControl hiddenLabel required className="w-full lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="adminFirstName"
              label="First Name"
              name="adminFirstName"
              value={values.adminFirstName}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors.adminFirstName && touched.adminFirstName}
              helperText={
                errors.adminFirstName && touched.adminFirstName
                  ? errors.adminFirstName
                  : ""
              }
              type="text"
            />
          </FormControl>
          <FormControl hiddenLabel required className="w-full lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="adminLastName"
              label="Last Name"
              name="adminLastName"
              value={values.adminLastName}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors.adminLastName && touched.adminLastName}
              helperText={
                errors.adminLastName && touched.adminLastName
                  ? errors.adminLastName
                  : ""
              }
              type="text"
            />
          </FormControl>
          <FormControl hiddenLabel required className="w-full lg:mt-5">
            <TextField
              className="w-full bg-white"
              id="adminEmail"
              label="Email Address"
              name="adminEmail"
              value={values.adminEmail}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors.adminEmail && touched.adminEmail}
              helperText={
                errors.adminEmail && touched.adminEmail ? errors.adminEmail : ""
              }
              type="email"
            />
          </FormControl>
          <FormControl required className="w-full lg:mt-5">
            <PhoneNumberInput
              {...{
                phoneNo: values.adminPhoneNumber,
                setPhoneNo: (val) =>
                  setValues({ ...values, adminPhoneNumber: val }),
                error:
                  errors.adminPhoneNumber && touched.adminPhoneNumber
                    ? errors.adminPhoneNumber
                    : "",
                onFocus: handleFocus,
                onBlur: handleBlur,
              }}
            />
          </FormControl>
        </Box>
        {cb && <Box></Box>}
        <Box className="flex justify-center">
          <LoadingButton
            className="my-10 normal-case rounded-[10px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)] w-[50%] shadow-none"
            sx={{
              ".MuiLoadingButton-loadingIndicatorCenter": {
                color: "var(--c-bg-color) !important",
              },
            }}
            size="large"
            type="submit"
            variant="contained"
            fullWidth
            loading={editable ? isUpdating : loading}
          >
            <span>
              {cb && !editable
                ? "Add Company"
                : editable
                ? "Update Company"
                : "Create Account"}
            </span>
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SignupCompany;
