import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchBar from '../../../components/SearchBar'
import HomeLayout from '../../../layouts/Home'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '../../../components/table/Table'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData, getUsers, setPagination } from '../../../features/users/userSlice'
import TablePreloader from '../../../components/Loader/TablePreloader'
import { useNavigate } from 'react-router-dom'
import Menu from '../../../utils/data.dropdown'
import config from '../../../utils/config'
import { IError } from '../../../utils/icons.utils'

const UserManagement = () => {
  const dispatch = useDispatch(),
  { routes } = config,
  navigate = useNavigate(),
  [isLoading, setIsLoading] = useState(true),
  { loading, users, pagination } = useSelector(getUserData)
  
  useEffect(()=>{
    setIsLoading(false)
  }, [])

  useEffect(()=>{
    dispatch(getUsers())
  }, [pagination.page])

  useEffect(() => {
		let cb = setTimeout(_=> (async ()=> await dispatch(getUsers()))(), 700)

		return ()=>{
				clearTimeout(cb)
		}
}, [pagination.search])

  const data = users?.map(user => {
    return {
      name: user?.firstName + ' ' + user?.lastName || 'N/A',
      "Email Address": user?.email || 'N/A',
      company: user?.companies?.[0]?.name || 'N/A',
      hospital: user?.hospitals?.[0]?.name || 'N/A',
      "H.M.O": user?.hmos?.[0]?.name || 'N/A',
      _data: user
    }
  })
  
  const menu = new Menu({});
  const dropdownMenu = [
    menu.view({
      navigate,
      route: routes.hospitals.staffs.staff,
    }),
  ]

  return (
    <HomeLayout {...{ title: "Configuration" }}>
      <SearchBar {... {
          placeholder: "Search For User by Name",
          onChange: (e) => {
            const { value } = e.target;
            if(e.code === 'Enter') dispatch(getUsers(value))
           else dispatch(setPagination({search: value}))
          }
        }} />

          <Box className="pt-8 px-8 mt-14 pb-2 bg-[var(--c-bg-color)] rounded-lg w-100%" sx={{ p: 0 }}>
       
        {isLoading ? (
						<TablePreloader />
					) : data?.length ? (
						<Table
							{...{
								dropdownMenu,
								data,
              pagination,
              setPagination: d => dispatch(setPagination(d())),
								isLoading: loading,
							}}
						/>
					) : (
            <Box
            className="pt-8 pb-28 mt-8 bg-[var(--c-bg-color)] w-100% rounded-lg flex flex-col justify-center"
            sx={{ p: 0 }}
          >
            <img
            className="m-auto w-[350px] mt-8"
            src={IError}
            alt={"error"}
            loading="lazy"
          />
          <Typography className="flex justify-center text-[400] text-xl text-[#040316] mx-2 text-center">
          No User in the system at the moment
          </Typography>
          </Box>
					)}
           </Box>

      </HomeLayout>
  )
}

export default UserManagement