import React from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../components/others/Navbar";
import Footer from "../../components/others/Footer";
import { Box, Button, Typography } from "@mui/material";
import { setCurrentScreen } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  IWalmart,
  IAirbnb,
  IFedEx,
  IGoogle,
  IHubspot,
  IMicrosoft,
  IScalable,
  IBlank,
  ILocation,
  IDownloadMobile,
  IGooglePlay,
  IAppleStore,
} from "../../utils/icons.utils";
import CarouselComp from "../../components/others/Carousel";
import AccordionComp from "../../components/others/Accordion";

const Index = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    state && dispatch(setCurrentScreen(state));
  }, [state]);

  return (
    <Box className="">
      <Navbar />
      {/* Hero Section Starts */}
      <Box
        className="pt-10 md:pt-3 lg:pt-1 text-center md:px-0 px-3"
        style={{
          background:
            "linear-gradient(135.06deg, #BFBCF5 15.71%, #EAE9FC 54.3%)",
        }}
      >
        <Typography className="md:text-[64px] text-2xl md:leading-[65px] m-auto font-semibold md:mt-[10%] mt-[20%] mb-10 text-center">
          <p>Get Accessible HMO Plans With Us.</p>
          {/* <p></p> */}
        </Typography>
        <Typography className="md:w-[50%] text-center m-auto mb-10 md:text-[24px] text-lg font-[Lota]">
          With CareQuest you have access to our accessible yet reliable platform
          that helps get you started with HMO plans.
        </Typography>
        <Button
          LinkComponent={Link}
          className="md:w-[30%] lg:w-[20%] xl:w-[15%] w-[70%] mb-8 mt-2 rounded-[5px] bg-[var(--c-primary-0)] hover:bg-[var(--c-primary-1)]  self-end shadow-none "
          sx={{
            ".MuiLoadingButton-loadingIndicatorCenter": {
              color: "var(--c-bg-color) !important",
            },
          }}
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          to="/auth"
          {...{ state: "home-page" }}
        >
          <span className="font-semibold text-[12px]">Get Started -</span>{" "}
          <span className="font-normal text-[10px] ml-1">It’s Free</span>
        </Button>
      </Box>
      {/* Hero Section Ends */}

      {/* Sponsors Section Starts */}
      <Box className="grid grid-rows-2 grid-cols-2 gap-4  md:flex md:justify-evenly w-[95%] lg:w-[80%] md:w-[95%] m-auto pl-3 md:pl-0 py-10 border-t-2 border-b-2 my-5">
        <img src={IAirbnb} alt="instagram" />
        <img src={IHubspot} alt="web" />
        <img src={IGoogle} alt="twitter" />
        <img src={IMicrosoft} alt="youtube" />
        <img src={IWalmart} alt="youtube" />
        <img src={IFedEx} alt="youtube" />
      </Box>
      {/* Sponsors Section Ends */}

      {/* Description Section Starts */}
      <Box className="md:mx-0 mx-3">
        <Typography className="text-[32px] !font-medium md:w-[60%] m-auto leading-none mt-14 mb-10 text-center">
          One scalable, and secure platform
        </Typography>
        <Typography className="md:w-[40%] text-center m-auto mb-10 text-[400] text-[18px]">
          With CareQuest you have access to our accessible yet reliable platform
          that helps get you started with HMO plans.
        </Typography>
        <br />
        <br />
        <Box className="mb-[10%] xl:flex gap-10 xl:mx-10">
          <img
            src={IScalable}
            alt="img"
            className="rounded-r-[30px] hidden md:block h-full"
          />
          <Box className="grid md:grid-cols-2 grid-cols-1 md:grid-rows-2 gap-8 md:px-5 md:mt-5">
            <Box className="m-auto bg-[#EAE9FC] px-5 py-6 rounded-[21px] md:mt-14 mt-0 ">
              <img src={IBlank} alt="img" className="rounded-lg" />
              <Typography className="mt-10 font-bold text-[16px]">
                Hospitals
              </Typography>
              <Typography className="mt-5 text-[14px] font-[Lota]">
                Companies can easily sign up their staffs on the CareQuest App.
                and assign different HMO Plans to them and their families.
              </Typography>
            </Box>
            <Box className="m-auto bg-[#EFFFE8] px-5 py-6 rounded-[21px]">
              <img src={IBlank} alt="img" className="rounded-lg" />
              <Typography className="mt-10 font-bold text-[16px]">
                Company
              </Typography>
              <Typography className="mt-5 text-[14px] font-[Lota]">
                Companies can easily sign up their staffs on the CareQuest App.
                and assign different HMO Plans to them and their families.
              </Typography>
            </Box>
            <Box className="m-auto  bg-[#EFFFE8] py-6 px-5 rounded-[21px] md:mt-14 mt-0">
              <img src={IBlank} alt="img" className="rounded-lg" />
              <Typography className="mt-10 font-bold text-[16px]">
                H. M. O
              </Typography>
              <Typography className="mt-5 text-[14px] font-[Lota]">
                Companies can easily sign up their staffs on the CareQuest App.
                and assign different HMO Plans to them and their families.
              </Typography>
            </Box>
            <Box className="m-auto bg-[#EAE9FC] px-5 py-6 rounded-[21px]">
              <img src={IBlank} alt="img" className="rounded-lg" />
              <Typography className="mt-10 font-bold text-[16px]">
                Individuals
              </Typography>
              <Typography className="mt-5 text-[14px] font-[Lota]">
                Companies can easily sign up their staffs on the CareQuest App.
                and assign different HMO Plans to them and their families.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Description Section Ends */}

      {/* Stand Out Section starts */}
      <Typography className="w-[92%] m-auto mb-8 font-medium text-[32px] md:text-start text-center">
        What Makes Us Stand Out
      </Typography>
      <Box className="md:flex w-[100%] bg-[var(--c-sec)] md:pb-8 md:pt-8 px-8 md:px-0">
        {[1, 2, 3].map((a) => (
          <Box
            key={a}
            className={`w-[100%] flex flex-col justify-center text-center ${
              a !== 3 && "md:border-r-4"
            } ${a !== 3 && "border-b-[2px]"} md:border-b-0 border-[#6B7588]`}
          >
            <img
              src={ILocation}
              alt="img"
              className="rounded-lg mt-s w-[84px] h-[106px] m-auto"
            />
            <Typography className="font-medium md:text-[28px] text-xl">
              Closest Providers
            </Typography>
            <Typography className="mt-5 mb-8 w-[60%] m-auto font-normal md:text-xl text-lg text-gray-500 font-[Lota] text-center">
              Get Connected easily to other closest providers
            </Typography>
          </Box>
        ))}
      </Box>
      {/* Stand Out Section Ends */}

      {/* Download mobile Section Starts */}
      <Box className="my-14 relative px-2">
        <img src={IDownloadMobile} alt="img" className="m-auto " />
        <Box className="absolute  md:bottom-[16%] bottom-[0] md:left-[0] xl:left-[15%] left-[0]">
          <Typography className="font-medium xl:text-[36px] md:text-[20px] text-[13px] text-white md:w-[60%] w-[60%] md:ml-[10%] ml-6 leading-none mt-14 md:mb-10 mb-3 text-start">
            Your One Stop HMO Platforms For Corporate & Individuals
          </Typography>
          <Box className="grid grid-cols-2 gap-2 w-[45%] md:ml-[10%] ml-6">
            <img src={IGooglePlay} alt="img" className="m-auto " />
            <img src={IAppleStore} alt="img" className="m-auto " />
          </Box>
        </Box>
      </Box>
      {/* Download mobile Section Ends */}
      <br className="hidden md:block" />
      <br className="hidden md:block" />
      <br className="hidden md:block" />

      {/* Testimonials Section Starts */}
      <CarouselComp />
      {/* Testimonials Section Ends */}
      <br />
      <br className="hidden md:block" />
      <br className="hidden md:block" />

      {/* Faq's Section Starts */}
      <Typography className="w-[91%] m-auto mb-20 font-medium text-[32px] md:text-start text-center">
        Frequently Asked Questions
      </Typography>
      <AccordionComp />

      {/* Faq's Section ends */}
      <Footer />
    </Box>
  );
};

export default Index;
