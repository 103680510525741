import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../../../../components/SearchBar";
import TabControl from "../../../../components/tabControl/TabControl";
import { useAuthContext } from "../../../../context/AuthContext";
import HomeLayout from "../../../../layouts/Home";
import Company from "./Company";

const ClientDebt = () => {
  const [currentTabView, setCurrentTabView] = useState(<Company />),
    [current, setCurrent] = useState(1),
    { setClassName } = useAuthContext();

  const pages = [
    {
      id: 1,
      title: "Company",
      element: <Company />,
    },
  ];

  const handleTabChange = (id) => {
    setCurrent(id);
    setCurrentTabView(
      pages.find((page) => {
        setClassName((_) => "md:w-[80%] lg:w-[85%] xl:w-[65%]");
        return page.id === id;
      }).element
    );
  };

  return (
    <HomeLayout
      {...{
        title: "Transaction Management",
        subtitle: "Overview  of all information on the system.",
      }}
    >
      <Box className="ml-3">
        <Typography className="text-[400] text-xl mt-8 mb-5  text-[#040316]">
          Client’s Outstanding Payment
        </Typography>
        <SearchBar placeholder="Search For Transactions..." />
      </Box>

      <TabControl
        tabs={pages}
        setCurrent={handleTabChange}
        current={current}
        tabWrapperActiveClass=" border-b-4 shadow-none rounded-none mt- hover:shadow-none border-[#090F47]"
        tabWrapperClass="mt-6  shadow-none rounded-none hover:shadow-none "
        wraperClass="grid lg:grid-cols-6  gap-14 my-8 border-b-2 border-[#B3B3B3]"
        tabClass="text-[400] text-[20px] text-[#040316]  "
      />
      {currentTabView}
    </HomeLayout>
  );
};

export default ClientDebt;
