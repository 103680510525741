import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import links from "../../utils/linkTree";
import { IBrandBlue, ILogout } from "../../utils/icons.utils";
import SidebarItems from "../../components/Sidebars/SidebarItems";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { ExpandMoreOutlined } from "@mui/icons-material/";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Avatar, Stack } from "@mui/material";
import { IBulb } from "../../utils/icons.utils";
import { Close } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import { LoadingButton } from "@mui/lab";
import { loggedInUser } from "../../utils/helper";
import config from "../../utils/config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 340;

export default function HomeSidebar(props) {
  const { window, children, title, subtitle } = props;
  const [phoneNumberOpen, setMobileOpen] = React.useState(false),
    [logouts, setLogout] = React.useState(false);
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    handleLogout = () => {
      dispatch(logout());
      navigate("/");
    },
    handleDrawerToggle = () => {
      setMobileOpen(!phoneNumberOpen);
    },
    user = loggedInUser ? JSON.parse(loggedInUser) : {},
    logo =
      user?.hmos?.[0]?.hmoLogo ||
      user?.companies?.[0]?.companyLogo ||
      user?.hospitaluser?.[0]?.hospitalLogo;

  const drawer = (
    <div>
      <Toolbar>
        <img src={IBrandBlue} alt="brand" className="mt-5 ml-7 w-40" />
      </Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      ></IconButton>
      <div className="sm:mt-20 pl-7">{/* <p>General</p> */}</div>
      <List>
        {links.map((link, index) => {
          return <SidebarItems {...{ link }} key={index} />;
        })}
      </List>

      <ListItem sx={{ mt: "4em" }}>
        <ListItemButton onClick={(_) => setLogout(true)}>
          <ListItemIcon>
            <img src={ILogout} alt="logout" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography>Logout</Typography>}
          />
        </ListItemButton>
      </ListItem>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "#fff",
          color: "#000",
          boxShadow: "none",
          py: ".5em",
          zIndex: 20,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box className="flex justify-between w-full">
            <div>
              <Typography
                variant="h4"
                noWrap
                component="div"
                color="#040316"
                fontFamily={"SofiaProBold"}
                fontSize="32px"
              >
                {title}
              </Typography>
              <Typography
                variant="p"
                component="p"
                color={"var(--c-dark-1)"}
                fontFamily={"Lota"}
              >
                {subtitle}
              </Typography>
            </div>

            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                navigate(config.routes.settings.profile);
              }}
            >
              <Avatar src={user?.profileImage || logo} className="border" />
              <span className="mx-2">
                {user?.firstName} {user?.lastName}
              </span>
              <ExpandMoreOutlined />
            </div>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        fontFamily={"SofiaPro"}
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          "& .MuiDrawer-paper": {
            width: { sm: drawerWidth },
            boxSizing: "border-box",
            backgroundColor: "var(--c-bg-color)",
            borderRight: "0px",
          },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={phoneNumberOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on phoneNumber.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>

      {logouts && (
        <Dialog
          fullScreen={fullScreen}
          open={logouts}
          onClose={(_) => setLogout(false)}
          aria-labelledby="responsive-dialog-title"
          className="p-10"
          sx={{ zIndex: 5000 }}
          TransitionComponent={Transition}
        >
          <DialogActions
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button color="error" onClick={(_) => setLogout(false)} autoFocus>
              <Close />
            </Button>
          </DialogActions>
          <DialogTitle
            id="responsive-dialog-title"
            display="flex"
            justifyContent="center"
            mt="0em"
          >
            <Avatar src={IBulb} />
          </DialogTitle>
          <DialogContent>
            <Typography textAlign="center" fontSize="24px" fontFamily={"Lota"}>
              Log Out
            </Typography>
            <DialogContentText
              fontSize="20px"
              padding=".5em 1em"
              mb={"20px"}
              fontFamily={"Lota"}
            >
              Are you sure you want to logout?
            </DialogContentText>
            <Stack spacing={7} direction="row" className="normal-case">
              <LoadingButton
                className="normal-case"
                fullWidth
                sx={{
                  borderRadius: "10px",
                  bgcolor: "bg-[var(--text-bg)] !important",
                }}
                size="large"
                variant="outlined"
                onClick={handleLogout}
              >
                Yes
              </LoadingButton>

              <Button
                className="normal-case"
                fullWidth
                sx={{
                  ".MuiLoadingButton-loadingIndicatorCenter": {
                    color: "white !important",
                  },
                  borderRadius: "10px",
                  bgcolor: "var(--c-primary-1) !important",
                  "&:hover": { bgcolor: "var(--c-primary-1) !important" },
                }}
                size="large"
                onClick={(_) => setLogout(false)}
                variant="contained"
              >
                <span className="normal-case">Cancel</span>
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
