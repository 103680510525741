import { Box, FormControl, TextField, Typography } from "@mui/material";
import React from "react";
import HomeLayout from "../../../layouts/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { vAilment } from "../../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import {
	createAilment,
	getAilment,
	getAilmentData,
	updateAilment,
} from "../../../features/users/ailmentSlice";
import { useFormik } from "formik";

const CreateAilment = ({ stateModel, cb, editable }) => {
	const navigate = useNavigate(),
		{ loading, model } = useSelector(getAilmentData),
		dispatch = useDispatch(),
		{
			values,
			handleBlur,
			handleChange,
			handleFocus,
			handleSubmit,
			setValues,
			errors,
			touched,
		} = useFormik({
			validationSchema: vAilment,
			initialValues: stateModel || model,
			onSubmit: async (value) => {
				const action = editable ? updateAilment : createAilment;
				const payload = {
					...values,
					ailmentId: editable ? stateModel?.name : "",
				};

				const res = await dispatch(action(payload)).unwrap();
				res?.success === true && navigate(-1);
			},
		});

	return (
		<HomeLayout {...{ title: "Configuration" }}>
			<Box className="flex  mt-2 text-[var(--c-dark-1)]">
				<ArrowBackIcon
					onClick={(_) => navigate(-1)}
					className=" mr-2 cursor-pointer"
					fontSize="small"
				/>
				<Typography className="text-[400] text-[16px] mb-2">Go back</Typography>
			</Box>
			<Typography className="text-[400] text-[22px] mt-8 text-[#040316]">
				{editable ? "Edit Ailment" : "Create Ailment"}
			</Typography>
			<Box
				component="form"
				marginTop=""
				noValidate
				onSubmit={handleSubmit}
				className="mt-[3em]">
				<Box
					className=" pt-24 px-8 pb-8 bg-[var(--c-bg-color)] rounded-lg w-100% flex flex-col "
					sx={{ p: 0 }}>
					<FormControl className="lg:w-[50%] w-full border-none mb-8  m-auto">
						<TextField
							className="w-full bg-white "
							id="name"
							label="Ailment Name"
							name="name"
							value={values.name}
							onFocus={handleFocus}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.name && touched.name}
							helperText={errors.name && touched.name ? errors.name : ""}
						/>
					</FormControl>
					<FormControl className="lg:w-[50%] w-full border-none mb-8  m-auto">
						<TextField
							className="w-full bg-white "
							id="description"
							multiline
							rows={4}
							label="Description"
							name="description"
							value={values.description}
							onFocus={handleFocus}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.description && touched.description}
							helperText={
								errors.description && touched.description
									? errors.description
									: ""
							}
						/>
					</FormControl>
					<Box className="flex justify-center">
						<LoadingButton
							className="my-10 lg:w-1/2 w-full rounded-xl text-[12px] lg:text-[18px] bg-[var(--c-primary-0)] shadow-none normal-case"
							size="large"
							type="submit"
							variant="contained"
							fullWidth
							loading={loading}>
							{editable ? 'Save Changes' : 'Create'}
						</LoadingButton>
					</Box>
				</Box>
			</Box>
		</HomeLayout>
	);
};

export default CreateAilment;
